import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthFactoryService } from '../../auth/auth-factory.service';
import { ActivityType } from '../activity-type/interfaces/activity-type';
import { RestGenericUtilService } from '../rest-generic-util.service';
import { RestGeneric } from '../rest.generic';

@Injectable({
  providedIn: 'root'
})
export class ActivityTypeService extends RestGeneric<any, ActivityType, any> {
  constructor(
    protected http: HttpClient,
    protected authFactoryService: AuthFactoryService,
    protected restGenericUtilService: RestGenericUtilService
  ) {
    super(http, authFactoryService, 'activity-type', restGenericUtilService);
  }
}
