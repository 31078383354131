import { Component, EventEmitter, Input, isDevMode, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { EventManagerService } from 'src/app/core/services/event-manager/event-manager.service';
import { getFileContent } from 'src/app/core/utils/utils';
import { FormGroupService } from '../../form-error/form-group.service';
import { ConfirmationModalComponent } from '../confirmation-modal.component';
import { ConfirmationModal } from '../interfaces/confirmation.modal';

@Component({
  selector: 'app-confirmation-modal-upload-file',
  templateUrl: './confirmation-modal-upload-file.component.html',
  styleUrls: ['./confirmation-modal-upload-file.component.scss']
})
export class ConfirmationModalUploadFileComponent extends ConfirmationModalComponent implements OnInit {
  @Input() modal: ConfirmationModal | undefined;
  @Output() fileChangeContent = new EventEmitter<string>();

  getFileContent = getFileContent;

  constructor(
    protected modalRef: BsModalRef,
    protected eventManager: EventManagerService,
    private formGroupService: FormGroupService
  ) {
    super(modalRef, eventManager);
  }

  ngOnInit(): void {
    if (isDevMode()) {
      if (!this.modal?.form?.controls?.manualFile) {
        console.error('Please define in your form group formControl with name manualFile');
      }
    }
  }

  async onFileUpload(): Promise<void> {
    if (!this.modal?.form) {
      return;
    }

    this.formGroupService.validateFormControl(this.modal.form.controls.manualFile);

    if (this.modal?.form?.controls.manualFile.invalid) {
      return;
    }

    const fileContent = await this.getFileContent(this.modal.form.controls.manualFile.value);

    if (fileContent.length === 0) {
      return;
    }

    this.fileChangeContent.emit(fileContent);

    this.onConfirm();
  }

  onFileChange(file: File): void {
    if (!file) {
      return;
    }

    if (!this.modal?.form) {
      return;
    }

    this.modal.form.controls.manualFile.setValue(file);
    this.formGroupService.validateFormControl(this.modal?.form?.controls.manualFile);
  }
}
