<div class="{{ className }}">
  <label>{{ defaultMessageContent | translate }}</label>
  <ng-container *ngIf="form">
    <app-auto-complete
      (selectedItemChange)="onSelectChangeUser($event)"
      (removeItemChange)="onRemoveItemChange($event)"
      (searchChange)="onSearchChangeStudent($event)"
      [itemsData]="students"
      [selectedItem]="form.get(controlName)?.value"
      [multiple]="multiple"
    ></app-auto-complete>
  </ng-container>
</div>
