import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { AppConfig } from 'src/app/configs/app.config';
import { DestroyNotifierComponent } from 'src/app/core/component/destroy/destroy-notifier.component';
import { ActivityTypeService } from 'src/app/core/services/http/activity-type/activity-type.service';
import { ActivityType } from 'src/app/core/services/http/activity-type/interfaces/activity-type';
import { Paging } from 'src/app/core/services/http/general/classes/paging';
import { Status } from 'src/app/core/services/http/general/enums/status';
import { ClassInputType } from '../types/class-input-type';

@Component({
  selector: 'app-activity-type-select',
  templateUrl: './activity-type-select.component.html'
})
export class ActivityTypeSelectComponent extends DestroyNotifierComponent implements OnInit {
  @Input() form: UntypedFormGroup | undefined;
  @Input() className: ClassInputType = 'app-input';
  @Input() controlName = 'activityType';
  @Input() label = 'ACTIVITY_TYPES';
  @Input() shouldFilterByTreeLevel = false;
  @Input() paging = new Paging();
  activityTypes: ActivityType[] | undefined;

  constructor(private activityTypeService: ActivityTypeService) {
    super();
  }

  ngOnInit(): void {
    this.activityTypeService
      .getAll(this.paging.getAllRecords(), { status: Status.Active })
      .pipe(takeUntil(this.destroyNotify))
      .subscribe(activityTypes => {
        if (this.shouldFilterByTreeLevel) {
          activityTypes = this.filterByTreeLevel(activityTypes);
        }

        this.activityTypes = activityTypes;
      });
  }

  private filterByTreeLevel(activityTypes?: ActivityType[] | null): ActivityType[] {
    if (!activityTypes) {
      return [];
    }

    for (const activityType of activityTypes) {
      activityType.treeLevel = activityType.treeLevel ?? 0;

      if (activityType.parent) {
        const parent = activityType.parent;

        const parentTargetTreeLevel = this.getParentLevel(parent.uuid, activityTypes);

        activityType.treeLevel += parentTargetTreeLevel + 1;
      }
    }

    return activityTypes.filter(item => this.isValidTreeLevel(item));
  }

  private isValidTreeLevel(activityType: ActivityType): boolean {
    return ((activityType && activityType.treeLevel) || 0) < AppConfig.DropDown.ActivityTypeAllowTreeLevel;
  }

  private getParentLevel(uuid: string, activityTypes: ActivityType[], treeLevel = 0): number {
    const parentTarget = activityTypes.find(item => item.uuid === uuid);

    if (!parentTarget) {
      return 0;
    }

    if (parentTarget?.parent) {
      treeLevel += 1;
      treeLevel = this.getParentLevel(parentTarget?.parent.uuid, activityTypes, treeLevel);
    }

    return treeLevel;
  }
}
