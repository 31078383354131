import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { SubstitutePlan } from 'src/app/core/services/http/activity-type/enums/substitute-plan';
import { ClassInputType } from '../types/class-input-type';

@Component({
  selector: 'app-substitute-plan-select',
  templateUrl: './substitute-plan-select.component.html'
})
export class SubstitutePlanSelectComponent {
  @Input() form: UntypedFormGroup | undefined;
  @Input() className: ClassInputType = 'app-input';

  substitutePlan = SubstitutePlan;
}
