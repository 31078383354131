import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormGroup, Validators } from '@angular/forms';
import { isArray } from 'ngx-bootstrap/chronos';
import { merge, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FormGroupService {
  validateFormGroup(formGroup: UntypedFormGroup): void {
    formGroup.markAllAsTouched();
    formGroup.updateValueAndValidity({ emitEvent: false });
  }

  validateFormControl(formControl: AbstractControl | null | undefined): void {
    if (!formControl) {
      return;
    }

    formControl.markAsTouched({ onlySelf: true });
  }

  patchValue(form: UntypedFormGroup, controls: Record<string, any>, emitEvent = false): void {
    form.patchValue(controls, { emitEvent });
  }

  setValue(control: AbstractControl, value: any, emitEvent = false, onlySelf = true): void {
    control.setValue(value, { emitEvent, onlySelf });
  }

  setRequiredValidatorToControl(control?: AbstractControl): void {
    control?.setValidators([Validators.required]);
    this.updateValueAndValidity(control);
  }

  clearValidatorsAndValueFromControl(control?: AbstractControl): void {
    control?.clearValidators();
    control?.setValue(null, { emitEvent: false, onlySelf: true });
    this.updateValueAndValidity(control);
  }

  updateValueAndValidity(control?: AbstractControl, emitEvent = false): void {
    if (control) {
      control.updateValueAndValidity({ emitEvent });
    }
  }

  clearAbstractControlValue(controls: AbstractControl | AbstractControl[], emitEvent = false): void {
    if (isArray(controls)) {
      for (const control of controls) {
        control.setValue(null, { emitEvent });
      }
    } else {
      controls.setValue(null, { emitEvent });
    }
  }

  clearValidators(controls: AbstractControl | AbstractControl[]): void {
    if (isArray(controls)) {
      for (const control of controls) {
        control.clearValidators();
        this.updateValueAndValidity(control);
      }
    } else {
      controls.clearValidators();
      this.updateValueAndValidity(controls);
    }
  }

  abstractControlToFormGroup(control: AbstractControl | undefined): UntypedFormGroup {
    return control as UntypedFormGroup;
  }

  clearFormControlsValuesByFormGroup(form?: UntypedFormGroup, emitEvent = true, shouldResetDisabled = true): void {
    for (const controlName in form?.controls) {
      if (Object.prototype.hasOwnProperty.call(form?.controls, controlName)) {
        const control = form?.get(controlName);

        if (!control) {
          continue;
        }

        if (!shouldResetDisabled && control.disabled) {
          continue;
        }

        control.setValue(null, { emitEvent });
      }
    }
  }

  resetForm(form: UntypedFormGroup, emitEvent = false): void {
    form.reset({ emitEvent });
  }

  disableAbstractControl(controls: AbstractControl | AbstractControl[], emitEvent = false, shouldDisable = true): void {
    const updateDisableState = (control: AbstractControl) => {
      if (control.enabled && shouldDisable) {
        control.disable({ emitEvent });
      } else if (control.disabled && !shouldDisable) {
        control.enable({ emitEvent });
      }
    };

    if (isArray(controls)) {
      for (const control of controls) {
        updateDisableState(control);
      }
    } else {
      updateDisableState(controls);
    }
  }

  getFormControlsByNames(names: string[], form?: UntypedFormGroup): Record<string, AbstractControl> | undefined {
    if (!form) {
      return;
    }

    const formControls: Record<string, AbstractControl> = {};

    for (const controlName in form.controls) {
      if (Object.prototype.hasOwnProperty.call(form.controls, controlName)) {
        if (names.indexOf(controlName) !== -1) {
          formControls[controlName] = form.controls[controlName];
        }
      }
    }

    return formControls;
  }

  clearFormControlsValues(controls: string[], form?: UntypedFormGroup, emitEvent = true): void {
    controls.forEach(controlName => form?.get(controlName)?.setValue(null, { emitEvent }));
  }

  mergeValueChangesByFormControlName(names: string[], form: UntypedFormGroup): Observable<any> {
    const observables: Observable<any>[] = [];

    names.forEach(name => {
      const control = form.get(name);

      if (control) {
        observables.push(control.valueChanges);
      }
    });

    return merge(...observables);
  }

  mergeValueChangesByFormControlWithoutExcluded(excludedControls: string[], form: UntypedFormGroup): Observable<any> {
    const observables: Observable<any>[] = [];

    for (const controlName in form?.controls) {
      if (Object.prototype.hasOwnProperty.call(form?.controls, controlName)) {
        if (excludedControls.indexOf(controlName) === -1) {
          observables.push(form?.controls[controlName].valueChanges);
        }
      }
    }

    return merge(...observables);
  }
}
