import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Authority } from 'src/app/constants/authority';
import { DestroyNotifierComponent } from 'src/app/core/component/destroy/destroy-notifier.component';
import { AuthFactoryService } from 'src/app/core/services/auth/auth-factory.service';
import { ActivityPriorityService } from 'src/app/core/services/http/activity-priority/activity-priority.service';
import { ActivityPriorityType } from 'src/app/core/services/http/activity-priority/enums/activity-priority-type';
import { ActivityPriority } from 'src/app/core/services/http/activity-priority/interfaces/activity-priority';
import { Paging } from 'src/app/core/services/http/general/classes/paging';
import { Status } from 'src/app/core/services/http/general/enums/status';
import { ClassInputType } from '../types/class-input-type';

@Component({
  selector: 'app-priority-type-select',
  templateUrl: './priority-type-select.component.html'
})
export class PriorityTypeSelectComponent extends DestroyNotifierComponent implements OnInit {
  @Input() form: UntypedFormGroup | undefined;
  @Input() className: ClassInputType = 'app-input';
  activityPriorities: ActivityPriority[] | undefined;

  constructor(
    private authFactoryService: AuthFactoryService,
    private activityPriorityService: ActivityPriorityService
  ) {
    super();
  }

  ngOnInit(): void {
    this.loadPriorities();
  }

  loadPriorities() {
    const schoolUserAuthority = this.authFactoryService.service.getSchoolUserAuthority();
    const userRole = schoolUserAuthority?.role;

    this.activityPriorityService
      .getAll(new Paging().getAllRecords(), {
        status: Status.Active,
        orderBy: 'type'
      })
      .pipe(takeUntil(this.destroyNotify))
      .subscribe((activityPriorities: ActivityPriority[]) => {
        this.activityPriorities = activityPriorities.filter((item: ActivityPriority) => {
          return userRole === Authority.Admin
            ? item.type !== ActivityPriorityType.TeacherRequested
            : item.type !== ActivityPriorityType.AdminMustAttend && item.type !== ActivityPriorityType.TeacherRequested;
        });
      });
  }
}
