import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { AppConfig } from 'src/app/configs/app.config';

/**
 * Extends builded-in angular validators
 */
export class FormValidators {
  public static isCheck(validationMessage: string): ValidatorFn {
    const message = {
      isCheck: {
        message: validationMessage
      }
    };

    return (control: AbstractControl): { [key: string]: any } => {
      let result: any = message;
      const isCheck = control.value === true;

      if (isCheck) {
        result = null;
      }

      return result;
    };
  }

  public static validateEqualityOfPasswords(g: UntypedFormGroup): ValidationErrors {
    const message = {
      validateEqualityOfPasswords: {
        message: 'PASSWORDS_NOT_MATCH'
      }
    };

    let result: any = message;

    if (g.get('password')?.value === g.get('rePassword')?.value) {
      result = null;
    }

    if (result) {
      g.get('rePassword')?.setErrors(result);
    }

    return result;
  }

  public static hashTag(control: UntypedFormControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }

    const message = {
      hashTag: {
        message: ''
      }
    };

    let result: any = message;

    const regExp = /(#[a-zA-Z0-9-]+,? *)*#[a-zA-Z0-9-]+/g;
    const controlValueWithoutSpaces = control.value.replace(' ', '');

    const matchesHashtag = regExp.exec(controlValueWithoutSpaces);

    if (matchesHashtag) {
      if (
        controlValueWithoutSpaces.split(' ').length === 1 &&
        controlValueWithoutSpaces.split(',').length === matchesHashtag[0].split(',').length
      ) {
        result = null;
      }
    }

    return result;
  }

  public static onlyLetters(control: UntypedFormControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }

    const message = {
      onlyLetters: {
        message: ''
      }
    };

    let result: any = message;
    const regExp = new RegExp('[A-Za-z' + AppConfig.Inputs.allowedSpecialCharacters + '-]');
    const matchesOnlyLetters: boolean = regExp.test(control.value);

    if (matchesOnlyLetters) {
      result = null;
    }

    return result;
  }

  public static limitFileSize(fileSizeMb: number): ValidatorFn {
    const message = {
      limitFileSize: {
        fileSizeMb
      }
    };

    return (control: AbstractControl): { [key: string]: any } => {
      let result: any = message;

      // converts file size in bytes to mb
      if (!control.value || control.value.size / 1024 / 1024 <= fileSizeMb) {
        result = null;
      }

      return result;
    };
  }

  public static onlyPositiveNumbers(control: UntypedFormControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }

    const message = {
      onlyPositiveNumbers: {
        message: ''
      }
    };

    let result: any = message;

    if (!isNaN(control.value) && control.value > -1) {
      result = null;
    }

    return result;
  }

  public static onlyPositiveIntegerNumbers(control: UntypedFormControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }

    const message = {
      onlyPositiveIntegerNumbers: {
        message: ''
      }
    };

    let result: any = message;

    if (Number.isInteger(Number(control.value)) && Number(control.value) > 0) {
      result = null;
    }

    return result;
  }

  public static email(control: UntypedFormControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }

    const message = {
      email: {
        message: ''
      }
    };

    let result: any = message;
    const matchesEmail: boolean =
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/.test(
        control.value
      );

    if (matchesEmail) {
      result = null;
    }

    return result;
  }

  public static empty(): ValidatorFn {
    // @ts-ignore
    return (control: AbstractControl): { [key: string]: any } => {
      return {};
    };
  }
}
