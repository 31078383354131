import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { DeviceType } from './interfaces/device-type';
import { PlatformSizeSpecifier } from './services/platform-size-specifier';

@Directive({
  selector: '[appVisibilityByDevice]'
})
export class VisibilityByDeviceDirective {
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef,
    private platformSizeSpecifier: PlatformSizeSpecifier
  ) {}

  @Input()
  set appVisibilityByDevice(deviceType: DeviceType) {
    this.updateViewContainerRef(deviceType);
  }

  private updateViewContainerRef(deviceType: DeviceType): void {
    let isMatchTargetDevice = false;
    if (deviceType === DeviceType.LargeScreen) {
      isMatchTargetDevice = this.platformSizeSpecifier.isLargeScreen();
    } else if (deviceType === DeviceType.SmallScreen) {
      isMatchTargetDevice = this.platformSizeSpecifier.isSmallScreen();
    }

    this.viewContainerRef.clear();

    if (isMatchTargetDevice) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    }
  }
}
