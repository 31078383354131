<div class="{{ className }}">
  <label for="type">{{ label | translate }}</label>
  <ng-container *ngIf="form">
    <div *ngIf="activityTypes" [formGroup]="form">
      <ng-select
        [placeholder]="'SELECT_TYPE' | translate"
        [items]="activityTypes"
        [bindLabel]="'title'"
        [bindValue]="'uuid'"
        [formControlName]="controlName"
      >
      </ng-select>
    </div>
  </ng-container>
</div>
