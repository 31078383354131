import { Component, OnChanges } from '@angular/core';

import { DateManagerService } from 'src/app/core/services/date-manager/date-manager.service';
import { EventContent } from 'src/app/core/services/event-manager/models/event-content';
import { EventManagerService } from 'src/app/core/services/event-manager/event-manager.service';
import { EventType } from 'src/app/constants/events-type';
import { ExtendedDate } from 'src/app/core/services/date-manager/classes/extended-date';
import { FlexCalendarComponent } from '../flex-calendar.component';
import { FlexCalendarDay } from '../interfaces/flex-calendar-day';
import { FlexViewCalendarService } from '../services/flex-view-calendar-service';
import { compareDateByDays } from 'src/app/core/utils/utils';

@Component({
  selector: 'app-flex-calendar-attendance',
  templateUrl: './flex-calendar-attendance.component.html',
  styleUrls: ['./flex-calendar-attendance.component.scss']
})
export class FlexCalendarAttendanceComponent extends FlexCalendarComponent implements OnChanges {
  constructor(
    protected flexViewCalendarService: FlexViewCalendarService,
    protected eventManager: EventManagerService,
    protected dateManagerService: DateManagerService
  ) {
    super(flexViewCalendarService, eventManager, dateManagerService);
  }

  ngOnChanges(): void {
    this.setFlexCalendarDays();
    this.setFlexCalendarDaysProperties();
    this.setInitiallySelectedCalendarDates();
    this.initFlexCalendarDaysSelected();
    this.setFlexCalendarSelectedPropertiesBySelected();
  }

  onSelectDay(flexCalendarDay: FlexCalendarDay): void {
    if (flexCalendarDay.availableForSelect) {
      this.updateFlexCalendarDaysSelected(flexCalendarDay.date);
      this.setCalendarPropertiesOnCurrentSelectedDate();
      this.setCalendarPropertiesOnNewSelectedDate(flexCalendarDay);
      this.setTodayFlexCalendarDayProperties();
    }
  }

  protected updateFlexCalendarDaysSelected(date: ExtendedDate): void {
    this.flexCalendarDaysSelected = [date];
  }

  protected setFlexCalendarDaysProperties(): void {
    this.getFlexCalendarDays().forEach(flexCalendarDay => {
      this.setScheduleFlexCalendarDayProperties(flexCalendarDay);
      this.setTodayFlexCalendarDayProperties();
    });
  }

  protected setScheduleFlexCalendarDayProperties(flexCalendarDay: FlexCalendarDay): void {
    const targetScheduledDate = this.scheduledDates?.find(date => {
      return compareDateByDays(date, flexCalendarDay.date) === 0;
    });

    if (!targetScheduledDate) {
      return;
    }

    flexCalendarDay.selected = false;
    flexCalendarDay.availableForSelect = true;
    flexCalendarDay.class = 'available';

    if (compareDateByDays(flexCalendarDay.date, this.today) === 0) {
      flexCalendarDay.class = 'round-btn date-btn-current selected';
    } else if (compareDateByDays(flexCalendarDay.date, this.originalSelectedCalendarDate) === 0) {
      flexCalendarDay.selected = true;
      flexCalendarDay.class = 'round-btn date-btn-current selected';
      flexCalendarDay.availableForSelect = false;
    }
  }

  protected setTodayFlexCalendarDayProperties(): void {
    const targetTodayFlexCalendarDay = this.getFlexCalendarDays().find(
      flexCalendarDay => compareDateByDays(flexCalendarDay.date, this.today) === 0
    );

    if (!targetTodayFlexCalendarDay) {
      return;
    }

    if (compareDateByDays(this.today, this.originalSelectedCalendarDate) === 0) {
      targetTodayFlexCalendarDay.class = 'round-btn date-btn-current selected';
    } else {
      targetTodayFlexCalendarDay.class = 'round-btn date-btn-current available';
    }
  }

  protected setFlexCalendarSelectedPropertiesBySelected() {
    this.getFlexCalendarDays().forEach(flexCalendarDay => {
      const target = this.flexCalendarDaysSelected.find(item => compareDateByDays(item, flexCalendarDay.date) === 0);

      if (target && !flexCalendarDay.selected) {
        flexCalendarDay.selected = true;
        flexCalendarDay.class = 'round-btn date-btn available'; // selected
      }
    });
  }

  private setInitiallySelectedCalendarDates(): void {
    if (this.originalSelectedCalendarDate === this.selectedCalendarDate) {
      const targetFlexCalendarDay = this.flexCalendarDays?.find(flexCalendarDay => {
        return compareDateByDays(flexCalendarDay.date, this.originalSelectedCalendarDate) === 0;
      });

      if (targetFlexCalendarDay && !targetFlexCalendarDay.selected) {
        targetFlexCalendarDay.selected = true;
        targetFlexCalendarDay.availableForSelect = true;
      }
    }
  }

  private broadcastSelectFlexDate(): void {
    this.eventManager.broadcast(new EventContent(EventType.SelectFlexDate, this.flexCalendarDaysSelected[0]));
  }

  private setCalendarPropertiesOnCurrentSelectedDate(): void {
    this.flexCalendarDays?.forEach(item => {
      if (item.selected) {
        item.class = 'round-btn date-btn-current available'; // selected
        item.selected = false;
        item.availableForSelect = true;
      }
    });
  }

  private setCalendarPropertiesOnNewSelectedDate(flexDay: FlexCalendarDay): void {
    flexDay.selected = true;
    flexDay.availableForSelect = false;
    flexDay.class = 'round-btn date-btn selected';
    this.originalSelectedCalendarDate = flexDay.date;
    this.broadcastSelectFlexDate();
  }
}
