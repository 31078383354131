import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from '@angular/core';
import { EventType } from 'src/app/constants/events-type';
import { ExtendedDate } from 'src/app/core/services/date-manager/classes/extended-date';
import { DateManagerService } from 'src/app/core/services/date-manager/date-manager.service';
import { EventManagerService } from 'src/app/core/services/event-manager/event-manager.service';
import { EventContent } from 'src/app/core/services/event-manager/models/event-content';
import { compareDateByDays, trackBy } from 'src/app/core/utils/utils';
import { FlexCalendarDay } from './interfaces/flex-calendar-day';
import { FlexViewCalendarService } from './services/flex-view-calendar-service';

@Component({
  selector: 'app-flex-calendar',
  templateUrl: './flex-calendar.component.html',
  styleUrls: ['./flex-calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class FlexCalendarComponent implements OnChanges, OnInit {
  @Input() originalSelectedCalendarDate: ExtendedDate | undefined;
  @Input() selectedCalendarDate: ExtendedDate | undefined;
  @Input() scheduledDates: ExtendedDate[] | undefined;
  @Input() shouldEditScheduledActivity = false;

  flexCalendarDays: FlexCalendarDay[] | undefined;
  flexCalendarDaysSelected: ExtendedDate[] = [];
  today = new ExtendedDate();
  trackBy = trackBy;

  constructor(
    protected flexViewCalendarService: FlexViewCalendarService,
    protected eventManager: EventManagerService,
    protected dateManagerService: DateManagerService
  ) {}

  ngOnInit(): void {
    this.onScheduledDateChangeConfirm();
  }

  ngOnChanges(): void {
    this.onChanges();
  }

  onSelectDay(flexDay: FlexCalendarDay): void {
    if (flexDay.availableForSelect) {
      this.updateFlexCalendarDayClass(flexDay.date, !flexDay.selected);
      this.updateFlexCalendarDaysSelected(flexDay.date);
      this.broadcastDatesUpdate();
    }
  }

  getFlexCalendarDays(): FlexCalendarDay[] {
    return this.flexCalendarDays?.filter(item => item) || [];
  }

  protected onScheduledDateChangeConfirm(): void {
    this.eventManager.subscribe(EventType.ScheduledDateChangeConfirm, () => {
      this.onChanges();
    });
  }

  protected onChanges(): void {
    this.setFlexCalendarDays();
    this.setFlexCalendarDaysProperties();
    this.initFlexCalendarDaysSelected();
    this.setFlexCalendarSelectedPropertiesBySelected();
  }

  protected updateFlexCalendarDayClass(date: ExtendedDate, selected = false): void {
    const target = this.getFlexCalendarDays().find(
      item => item.visible !== false && item.availableForSelect && compareDateByDays(item.date, date) === 0
    );

    if (!target) {
      return;
    }

    if (!selected) {
      target.selected = false;
      target.class = 'date-btn available';
    } else {
      target.class = 'date-btn selected';
      target.selected = true;
    }
  }

  protected setFlexCalendarDays(): void {
    if (!this.selectedCalendarDate) {
      return;
    }

    this.flexCalendarDays = this.flexViewCalendarService.getFlexCalendarDays(this.selectedCalendarDate);
  }

  protected setFlexCalendarSelectedPropertiesBySelected() {
    this.getFlexCalendarDays().forEach(flexCalendarDay => {
      const target = this.flexCalendarDaysSelected.find(item => compareDateByDays(item, flexCalendarDay.date) === 0);
      //const check = this.scheduledDates?.find(item => compareDateByDays(item, flexCalendarDay.date) === 0);

      if (target) {
        flexCalendarDay.selected = true;
        flexCalendarDay.class = 'date-btn selected';
      }
    });
  }

  protected setFlexCalendarDaysProperties(): void {
    this.getFlexCalendarDays().forEach(flexCalendarDay => {
      this.setScheduleFlexCalendarDayProperties(flexCalendarDay);
      this.setSelectedFlexCalendarDayProperties(flexCalendarDay);
    });
  }

  protected setScheduleFlexCalendarDayProperties(flexCalendarDay: FlexCalendarDay): void {
    const targetScheduledDate = this.scheduledDates?.find(date => {
      return compareDateByDays(date, flexCalendarDay.date) === 0;
    });

    if (!targetScheduledDate) {
      return;
    }

    const comparingDateResult = compareDateByDays(targetScheduledDate, this.today);

    if (comparingDateResult === -1) {
      flexCalendarDay.class = 'date-btn-past';
      if (this.shouldEditScheduledActivity) {
        flexCalendarDay.availableForSelect = false;
      }
    } else {
      if (this.shouldEditScheduledActivity) {
        flexCalendarDay.class = 'date-btn available';
        flexCalendarDay.availableForSelect = true;
      } else {
        flexCalendarDay.class = 'date-btn disabled';

      }
    }
  }

  protected updateFlexCalendarDaysSelected(date: ExtendedDate): void {
    const index = this.flexCalendarDaysSelected.findIndex(item => compareDateByDays(item, date) === 0);

    if (index === -1) {
      this.flexCalendarDaysSelected.push(date);
    } else {
      this.flexCalendarDaysSelected.splice(index, 1);
    }
  }

  protected initFlexCalendarDaysSelected(): void {
    if (this.flexCalendarDaysSelected.length === 0) {
      this.flexCalendarDaysSelected = this.flexCalendarDays
          ?.filter(flexCalendarDay => flexCalendarDay?.selected)
          .map(flexCalendarDay => flexCalendarDay.date) || [];
    }
  }

  protected setSelectedFlexCalendarDayProperties(flexCalendarDay: FlexCalendarDay): void {
    if (this.originalSelectedCalendarDate) {
      if (compareDateByDays(this.originalSelectedCalendarDate, flexCalendarDay.date) === 0) {
        flexCalendarDay.class = 'date-btn-current selected';
        flexCalendarDay.classTitle = 'current';
        flexCalendarDay.selected = true;
        flexCalendarDay.availableForSelect = false;
      }
    }
  }

  protected isDateEqualToOriginalSelectedCalendarDate(date: Date): boolean {
    return compareDateByDays(date, this.originalSelectedCalendarDate) === 0;
  }

  private broadcastDatesUpdate(): void {
    this.eventManager.broadcast(
      new EventContent(
        EventType.DatesUpdate,
        this.flexCalendarDaysSelected.map(date => date)
      )
    );
  }
}
