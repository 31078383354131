import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthFactoryService } from '../../auth/auth-factory.service';
import { Activity } from '../activity/interfaces/activity';
import { Paging } from '../general/classes/paging';
import { RestGenericFileBulkOperations } from '../rest-generic-file-bulk-operations';
import { RestGenericUtilService } from '../rest-generic-util.service';
import { RestGeneric } from '../rest.generic';
import { Roster } from './interfaces/roster';
import { RosterFromFile } from './interfaces/roster-from-file';
import { RosterRequest } from './interfaces/roster-request';
import { ScheduledActivityRead } from '../scheduled-activity/interfaces/scheduled-activity-read';

@Injectable({
  providedIn: 'root'
})
export class RosterService
  extends RestGeneric<RosterRequest, Roster, RosterRequest>
  implements RestGenericFileBulkOperations<RosterFromFile>
{
  constructor(
    protected http: HttpClient,
    protected authFactoryService: AuthFactoryService,
    protected restGenericUtilService: RestGenericUtilService
  ) {
    super(http, authFactoryService, 'roster', restGenericUtilService);
  }

  getActivitiesByRoster(
    uuid: string,
    paging = new Paging(),
    filter?: Record<string, any>
  ): Observable<HttpResponse<Activity[]>> {
    const params = this.restGenericUtilService.toParams({ ...paging.getApiPaginationData(), ...filter });

    return this.http.get<Activity[]>(`${this.baseApiUrl}/${this.endpointName}/${uuid}/activity`, {
      observe: 'response',
      params
    });
  }

  getScheduledActivitiesByRoster(
      uuid: string,
      paging = new Paging(),
      filter?: Record<string, any>
  ): Observable<HttpResponse<ScheduledActivityRead[]>> {
    const params = this.restGenericUtilService.toParams({ ...paging.getApiPaginationData(), ...filter });

    return this.http.get<ScheduledActivityRead[]>(`${this.baseApiUrl}/${this.endpointName}/${uuid}/scheduled-activity`, {
      observe: 'response',
      params
    });
  }

  getListByFileHash(fileContentAsSHA256: string): Observable<RosterFromFile> {
    return this.http.get<RosterFromFile>(`${environment.EduSpireApi.baseUrl}/roster-list/csv/${fileContentAsSHA256}`);
  }

  createListFromFileBase64(fileContentAsBase64: string): Observable<RosterFromFile> {
    return this.http.post<RosterFromFile>(`${environment.EduSpireApi.baseUrl}/roster-list/csv`, {
      contents: fileContentAsBase64
    });
  }
}
