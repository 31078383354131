<button
  type="button"
  class="btn btn-text"
  (click)="clicked.emit()"
  [disabled]="exportStarted || !rowsCount"
  *appHasAnyAuthority="authority"
  [appTooltip]="'EXPORT' | translate"
>
  <i class="ftmi-download"></i>
  <ng-container *ngIf="!exportStarted; else startedTemplate"> {{ 'EXPORT' | translate }} </ng-container>
  <ng-template #startedTemplate>{{ 'EXPORT_STARTED' | translate }} </ng-template>
</button>
