import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { SchoolYearService } from '../../services/date-manager/school-year/school-year.service';

@Directive({
    selector: '[appSchoolYearDisabled]'
})
export class SchoolYearDisabledActionDirective implements OnInit {
    @Input() appSchoolYearDisabled = 'hidden';
    constructor(
        private el: ElementRef,
        private schoolYearService: SchoolYearService
    ) {}

    public ngOnInit() {
        if (!this.schoolYearService.isCurrentSchoolYearSelected()) {
            if (this.appSchoolYearDisabled === 'disabled') {
                this.el.nativeElement.setAttribute('disabled', 'disabled');
            } else {
                this.el.nativeElement.hidden = true;
            }
        }
    }
}
