import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { RosterType } from 'src/app/core/services/http/roster/enums/roster-types';
import { ClassInputType } from '../types/class-input-type';

@Component({
  selector: 'app-roster-type-select',
  templateUrl: './roster-type-select.component.html'
})
export class RosterTypeSelectComponent {
  rosterType = RosterType;
  @Input() controlName = 'type';
  @Input() form: UntypedFormGroup | undefined;
  @Input() className: ClassInputType = 'app-input';
}
