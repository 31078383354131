import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Status } from 'src/app/core/services/http/general/enums/status';
import { ClassInputType } from '../types/class-input-type';

@Component({
  selector: 'app-status-select',
  templateUrl: './status-select.component.html'
})
export class StatusSelectComponent implements OnInit {
  @Input() form: UntypedFormGroup | undefined;
  @Input() className: ClassInputType = 'app-input';
  @Input() value: any = null;

  status = Status;

  ngOnInit(): void {
    this.form?.controls.status.setValue(this.value, { emitEvent: false });
  }
}
