<div
  *ngIf="form && controlName && controlNameSelector && label"
  [formGroup]="form"
  class="timepicker-wrapper"
  dropdown
  [autoClose]="false"
  #time="bs-dropdown"
  (onHidden)="onHideTime()"
>
  <div class="{{ className }}">
    <label for="{{ controlName }}">{{ label }}</label>
    <input
      readonly
      id="{{ controlName }}"
      dropdownToggle
      class="form-control"
      placeholder="{{ appConfig.Formats.TimeFormat }}"
      formControlName="{{ controlName }}"
    />
  </div>

  <ng-content> </ng-content>

  <ul id="basic-link-dropdown" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="basic-link">
    <li>
      <timepicker
        [max]="max"
        [minuteStep]="dateTimeStepMin"
        [min]="min"
        formControlName="{{ controlNameSelector }}"
      ></timepicker>
    </li>
    <div class="dropdown-divider mb-3"></div>
    <button type="button" (click)="time.hide()" class="btn btn-outline-primary btn-sm btn-block">
      {{ 'APPLY' | translate }}
    </button>
  </ul>
</div>
