<ng-container *ngIf="form && controlName && items.length > 0">
  <div class="{{ className }}">
    <label>{{ label }}</label>
    <div [formGroup]="form">
      <ng-select
        [formControlName]="controlName"
        [placeholder]="placeholder || ('SELECT' | translate)"
        [items]="items"
        [bindLabel]="bindLabel"
        [bindValue]="bindValue"
        [multiple]="isMultiple"
        [clearable]="clearable"
      >
      </ng-select>
    </div>
  </div>
</ng-container>
