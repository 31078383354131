import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthFactoryService } from '../../auth/auth-factory.service';
import { RestGenericUtilService } from '../rest-generic-util.service';
import { RestGeneric } from '../rest.generic';
import { School } from './interfaces/school';
import { SchoolRequest } from './interfaces/school-request';

@Injectable({ providedIn: 'root' })
export class SchoolService extends RestGeneric<any, School, any> {
  constructor(
    protected http: HttpClient,
    protected authFactoryService: AuthFactoryService,
    protected restGenericUtilService: RestGenericUtilService
  ) {
    super(http, authFactoryService, '', restGenericUtilService);
  }

  getAll(): Observable<School[]> {
    return this.http.get<School[]>(`${this.baseApiUrl}`);
  }

  patch(updateType: SchoolRequest): Observable<School> {
    return this.http.patch<School>(`${this.baseApiUrl}/${this.schoolUUid}`, updateType);
  }

  getSchool(): Observable<School> {
    return this.http.get<School>(`${this.baseApiUrl}/${this.schoolUUid}`);
  }
}
