<ng-container *ngIf="form">
  <app-select
    [form]="form"
    label="{{ 'ACCESS_TYPE' | translate }}"
    [className]="className"
    [controlName]="controlName"
    [items]="[
      { id: rosterType.Private, name: 'PRIVATE' | translate },
      { id: rosterType.Global, name: 'GLOBAL' | translate },
      { id: rosterType.Shared, name: 'SHARED' | translate },
    ]"
  >
  </app-select>
</ng-container>
