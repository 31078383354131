import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'sanitizeLink' })
export class SanitizeLinkPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(url: any) {
    const sanitizedContent = this.sanitizer.sanitize(SecurityContext.URL, url) || '';
    return this.sanitizer.bypassSecurityTrustUrl(sanitizedContent);
  }
}
