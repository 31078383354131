<ng-container *ngIf="form">
  <div [formGroup]="form" class="{{ className }}">
    <label for="building">{{ label | translate }}</label>

    <ng-select
      placeholder="{{ 'SELECT_BUILDING' | translate }}"
      [items]="(buildings$ | async) || []"
      bindLabel="name"
      bindValue="uuid"
      formControlName="{{ controlName }}"
    >
    </ng-select>
  </div>
</ng-container>
