import { AppConfig } from 'src/app/configs/app.config';
import { ActivityPriorityType } from 'src/app/core/services/http/activity-priority/enums/activity-priority-type';
import { StudentAttendanceType } from 'src/app/core/services/http/student-attendance/enums/student-attendance-type';

export function getPriorityClassByType(type?: ActivityPriorityType): string {
  if (!type) {
    return '';
  }

  let cssClass = '';

  if (AppConfig.PriorityTypeClassMapping[type]) {
    cssClass = AppConfig.PriorityTypeClassMapping[type];
  }

  return cssClass;
}

export function getStudentAttendanceClassAndTransByType(type?: StudentAttendanceType): [string, string, string] {
  let result: [string, string, string] = ['', '', ''];

  if (type === undefined || type === null) {
    return result;
  }

  if (AppConfig.StudentAttendanceTypeClassTransMapping[type]) {
    result = AppConfig.StudentAttendanceTypeClassTransMapping[type];
  }

  return result;
}

export function getScheduledStatus(scheduled: boolean): string {
  let scheduledColor = AppConfig.ScheduleStatus.inActive;

  if (scheduled) {
    scheduledColor = AppConfig.ScheduleStatus.active;
  }

  return scheduledColor;
}

export function getTodaysStatusByDateRange(startDateStr: string, endDateStr: string): string {
  const resetHours = (date: Date) => {
    date.setHours(0, 0, 0, 0);
  };

  const today = new Date();
  resetHours(today);

  const startDate = new Date(startDateStr);
  resetHours(startDate);

  const endDate = new Date(endDateStr);
  resetHours(endDate);

  if (startDate <= today && today <= endDate) {
    return 'status-active';
  }
  return 'status-inactive';
}
