<app-select
  *ngIf="form"
  [form]="form"
  label="{{ 'STATUS' | translate }}"
  [className]="className"
  [controlName]="'status'"
  [items]="[
    { id: status.Active, name: 'ACTIVE' | translate },
    { id: status.Inactive, name: 'INACTIVE' | translate }
  ]"
>
</app-select>
