<ng-container *ngIf="modal">
    <div class="prompt-header">
        <h4 class="prompt-title">{{ modal.title }}</h4>
      </div>
      <div class="prompt-body" [innerHtml]="modal.body | sanitizeHtml"></div>
      <div class="prompt-footer d-flex justify-content-end">
        <button type="button" class="btn btn-outline-primary btn-sm" (click)="onRedirect()">{{ modal.secondaryButtonText }}</button>
        <button type="button" class="btn btn-primary btn-sm ml-3" (click)="onClose()">{{ modal.buttonText }}</button>
      </div>
</ng-container>
