import { Component } from '@angular/core';
import { Authority } from 'src/app/constants/authority';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html'
})
export class MainLayoutComponent {
  authority = Authority;
}
