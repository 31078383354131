import { Component, OnInit } from '@angular/core';
import { combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DestroyNotifierComponent } from 'src/app/core/component/destroy/destroy-notifier.component';
import { AnnouncementService } from 'src/app/core/services/http/announcement/announcement.service';
import { Announcement } from 'src/app/core/services/http/announcement/interfaces/announcement';
import { Paging } from 'src/app/core/services/http/general/classes/paging';
import { LocalStorageService } from 'src/app/core/services/local-storage/local-storage.service';
import * as CryptoJS from 'crypto-js';
import { SchoolYearService } from '../../core/services/date-manager/school-year/school-year.service';

@Component({
  selector: 'app-announcement-broadcast',
  templateUrl: './announcement-broadcast.component.html'
})
export class AnnouncementBroadcastComponent extends DestroyNotifierComponent implements OnInit {
  readonly AnnouncementsLocalStorageKey = 'announcements';

  announcements: Announcement[] | undefined;
  collapsed: string[] = [];
  paging = new Paging();

  constructor(
    private announcementService: AnnouncementService,
    private schoolYearService: SchoolYearService,
    private localStorageService: LocalStorageService
  ) {
    super();
  }

  ngOnInit(): void {
    this.loadAnnouncements();
  }

  onClick(item: Announcement) {
    if (this.collapsed.some(a => a === item.uuid)) {
      this.collapsed = this.collapsed.filter(a => a !== item.uuid);
    } else {
      this.collapsed.push(item.uuid);
    }

    const dataToSave = this.announcements?.filter(a => this.collapsed.some(c => a.uuid === c)).map(f => this.getSHA1(f));
    this.localStorageService.setItem(this.AnnouncementsLocalStorageKey, JSON.stringify(dataToSave));
  }

  getCollapsedState(item: Announcement): boolean {
    return this.collapsed.some(a => a === item.uuid);
  }

  private loadAnnouncementsCollapsedState() {
    const rawSavedData = this.localStorageService.getItem(this.AnnouncementsLocalStorageKey);
    if (!rawSavedData || !this.announcements) {
      return;
    }

    const savedData = JSON.parse(rawSavedData) as string[];
    for (const an of this.announcements) {
      if (savedData.some(a => a === this.getSHA1(an))) {
        this.collapsed.push(an.uuid);
      }
    }
  }

  private getSHA1(object: any) {
    return String(CryptoJS.SHA1(JSON.stringify(object)));
  }

  private loadAnnouncements(): void {
    const ftmAnnouncements$ = this.announcementService.getAll(this.paging.getAllRecords(), {
      todayDate: this.schoolYearService.getDefaultDateBySchoolYear()
    });
    const remoteAnnouncements$ = this.announcementService.getRemoteAnnouncements(this.paging.getAllRecords(), {
      todayDate: this.schoolYearService.getDefaultDateBySchoolYear()
    });

    combineLatest([ftmAnnouncements$, remoteAnnouncements$])
      .pipe(takeUntil(this.destroyNotify))
      .subscribe(([ftmAnnouncements, remoteAnnouncements]) => {
        this.announcements = [...ftmAnnouncements, ...remoteAnnouncements];
        this.loadAnnouncementsCollapsedState();
      });
  }
}
