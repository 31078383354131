import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'subString',
})
export class SubStringPipe implements PipeTransform {
  transform(value: string, start: number, end: number): string | null {
    if (value === '' || start < 0 || end < 1) {
      return null;
    }

    return value.toString().substring(start, end);
  }
}
