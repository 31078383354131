<ng-container *ngIf="form">
  <div [formGroup]="form" class="{{ className }}">
    <label for="{{ className }}">{{ label | translate }}</label>
    <ng-select
      placeholder="{{ 'SELECT_SCHOOL' | translate }}"
      formControlName="{{ controlName }}"
      isProfileSettings="isProfileSettings"
      (change)="changeDefaultSchool()"
    >
      <ng-option *ngFor="let school of schools" [value]="school.membership.uuid">{{ school.fullName }}</ng-option>
    </ng-select>
  </div>
</ng-container>