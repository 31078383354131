import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { EventManagerService } from 'src/app/core/services/event-manager/event-manager.service';
import { ConfirmationModalComponent } from 'src/app/shared/confirmation-modal/confirmation-modal.component';
import { ConfirmationModal } from 'src/app/shared/confirmation-modal/interfaces/confirmation.modal';

@Component({
  selector: 'app-redirection-modal',
  templateUrl: './redirection-modal.component.html',
  styleUrls: ['./redirection-modal.component.scss']
})
export class RedirectionModalComponent extends ConfirmationModalComponent {
  @Input() modal: ConfirmationModal | undefined;
  @Input() redirectUrl: any[] = [];

  constructor(
    protected modalRef: BsModalRef,
    protected eventManager: EventManagerService,
    private router: Router
  ) {
    super(modalRef, eventManager);
  }

  onRedirect() {
    this.router.navigate(this.redirectUrl);
    this.onConfirm();
  }
}
