import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Authority } from 'src/app/constants/authority';
import { ClassInputType } from '../types/class-input-type';

@Component({
  selector: 'app-user-role-select',
  templateUrl: './user-role-select.component.html'
})
export class UserRoleSelectComponent {
  @Input() form: UntypedFormGroup | undefined;
  @Input() className: ClassInputType = 'app-input';
  authority = Authority;
}
