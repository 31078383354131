import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { DestroyNotifierComponent } from 'src/app/core/component/destroy/destroy-notifier.component';
import { DateFilterService } from 'src/app/core/services/date-filter/date-filter.service';
import { DateManagerService } from 'src/app/core/services/date-manager/date-manager.service';

@Component({
  selector: 'app-future-check-box',
  templateUrl: './future-check-box.component.html'
})
export class FutureCheckBoxComponent extends DestroyNotifierComponent implements OnInit, OnChanges {
  @Output() filterChange = new EventEmitter();
  @Input() form: UntypedFormGroup | undefined;
  @Input() filter: Record<string, any> | undefined;
  @Input() shouldVisibleFutureCheckBox = true;
  @Input() shouldDisableFutureCheckBox: boolean | undefined;
  @Input() orderBy = 'scheduledDate';
  @Input() filterForToday = false;

  constructor(private dateFilterService: DateFilterService, private dateManagerService: DateManagerService) {
    super();
  }

  ngOnInit(): void {
    this.onChangeFuture();
  }

  ngOnChanges(): void {
    if (!this.shouldVisibleFutureCheckBox) {
      return;
    }

    if (this.filterForToday) {
     if (this.form?.controls.future.disabled) {
       this.enableFutureAndEmitFilter();
     }
      return;
    }

    if (!this.shouldDisableFutureCheckBox) {
      this.enableFutureAndEmitFilter();
    } else if (this.shouldDisableFutureCheckBox) {
      this.disableFuture();
    }
  }

  private onChangeFuture(): void {
    this.form?.controls.future.valueChanges
      .pipe(takeUntil(this.destroyNotify))
      .subscribe((value: boolean) => {
        this.updateFilterByFuture(value);
      }
    );
  }

  private updateFilterByFuture(value: boolean): void {
    if (!this.filter || !this.shouldVisibleFutureCheckBox) {
      return;
    }

    // Future days
    if (value) {
      const futureFilter = this.dateFilterService.getFilterForFutureDays();

      if (this.isStartAndEndDateCurrentAndPrevValueAreEqual(this.filter, futureFilter)) {
        return;
      }

      this.filter = { ...this.filter, ...futureFilter, orderBy: `-${this.orderBy}` };

      // Past days
    } else {
      const pastFilter = this.dateFilterService.getFilterForPastDays();

      if (this.isStartAndEndDateCurrentAndPrevValueAreEqual(this.filter, pastFilter)) {
        return;
      }

      this.filter = { ...this.filter, ...pastFilter };
    }

    this.filterChange.emit(this.filter);
  }

  private enableFutureAndEmitFilter(): void {
    this.form?.controls.future.enable({ emitEvent: false });
    this.updateFilterByFuture(this.form?.controls.future.value);
  }

  private disableFuture(): void {
    this.form?.controls.future.disable({ emitEvent: false });

    if (this.filter) {
      this.filter.orderBy = null;
    }
  }

  private isStartAndEndDateCurrentAndPrevValueAreEqual(
    currentFilter: Record<string, any>,
    newFilter: Record<string, any>
  ): boolean {
    const isStatDatesAreEqual =
      this.dateManagerService.isDatesEqual(currentFilter.startDate, newFilter.startDate) ||
      currentFilter.startDate === newFilter.startDate;

    const isEndDatesAreEqual =
      this.dateManagerService.isDatesEqual(currentFilter.endDate, newFilter.endDate) ||
      currentFilter.endDate === newFilter.endDate;

    return isStatDatesAreEqual && isEndDatesAreEqual;
  }
}
