<div class="app-daypicker" [class.orderReverse]="orderReverse" [class.directionReverse]="directionReverse">
  <button
    type="button"
    *ngIf="isWorkDay()"
    (click)="onClickToday()"
    [disabled]="isToday()"
    class="btn-pill btn-today today-order mr-2"
  >
    {{ 'TODAY' | translate }}
  </button>
  <div class="d-inline-flex align-items-center">
    <button class="round-btn daypicker-nav" btnRadio="Left" (click)="onClickPrev()"[appTooltip]="'BACK' | translate" placement="left">
      <i class="ftmi-chevron-left"></i>
    </button>

    <div class="datepicker-mask" [appTooltip]="'GO_TO' | translate" placement="bottom">
      <label for="{{ 'calendar-date' + randomString }}"
        ><i class="icon ftmi-calendar"></i>
        <b class="mr-1">{{ formats[2] | translate }} {{ dateValue | date: formats[0] }}</b>
        {{ dateValue | date: formats[1] }}
      </label>

      <input
        type="text"
        inputmode="none"
        placeholder="Datepicker"
        class="date-picker"
        id="{{ 'calendar-date' + randomString }}"
        bsDatepicker
        (bsValueChange)="onDateChange($event)"
        [(ngModel)]="dateValue"
        [bsConfig]="bsConfig"
        placement="bottom"
        inputmode="none"
      />
    </div>

    <button class="round-btn daypicker-nav" btnRadio="Left" (click)="onClickNext()" [disabled]="equalDates" [appTooltip]="'FORWARD' | translate" placement="right">
      <i class="ftmi-chevron-right"></i>
    </button>
  </div>
</div>
