<ng-container *ngIf="form">
  <app-select
    [form]="form"
    label="{{ 'RESTRICTION_PERIOD' | translate }}"
    [className]="className"
    controlName="numberOfWeeks"
    [isMultiple]="false"
    [items]="[
      { id: 1, name: '1 week' },
      { id: 2, name: '2 weeks' },
      { id: 3, name: '3 weeks' },
      { id: 4, name: '4 weeks' }
    ]"
  >
  </app-select>
</ng-container>
