import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthFactoryService } from '../../auth/auth-factory.service';
import { Paging } from '../general/classes/paging';
import { RestGenericUtilService } from '../rest-generic-util.service';
import { RestGeneric } from '../rest.generic';
import { UserTitle } from './interface/user-title';

@Injectable({
  providedIn: 'root'
})
export class UserTitleService extends RestGeneric<any, UserTitle, any> {
  constructor(
    protected http: HttpClient,
    protected authFactoryService: AuthFactoryService,
    protected restGenericUtilService: RestGenericUtilService
  ) {
    super(http, authFactoryService, 'title', restGenericUtilService);
  }

  getAll(paging = new Paging(), filter?: Record<string, any>): Observable<UserTitle[]> {
    const params = this.restGenericUtilService.toParams({ ...paging.getApiPaginationData(), ...filter });

    return this.http.get<UserTitle[]>(`${environment.EduSpireApi.baseUrl}/authentication/title`, {
      params
    });
  }
}
