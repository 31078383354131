import { Injectable } from '@angular/core';
import { DeviceType } from '../interfaces/device-type';

@Injectable({ providedIn: 'root' })
export class PlatformSizeSpecifier {
  isLargeScreen(): boolean {
    return document.documentElement.clientWidth >= DeviceType.LargeScreen;
  }

  isSmallScreen(): boolean {
    return document.documentElement.clientWidth <= DeviceType.SmallScreen;
  }

  isMediumScreen(): boolean {
    return (
      document.documentElement.clientWidth <= DeviceType.MediumSize &&
      document.documentElement.clientWidth >= DeviceType.SmallScreen
    );
  }
}
