<app-auto-complete-list-group
  (selectChange)="onSelectChangeTeacher($event)"
  (searchChange)="onSearchChangeTeacher($event)"
  [itemsData]="employees"
  [selectedItemsData]="form?.get(this.controlName)?.value"
  [shouldShowDefaultMessage]="shouldShowDefaultMessage"
  [defaultMessageContent]="defaultMessageContent"
  [shouldShowSelectedItemsList]="shouldShowSelectedItemsList"
  [shouldBeAbleToEditListGroupItem]="shouldBeAbleToEditListGroupItem"
  [removeSelectedItem]="removeSelectedItem"
  [inputTitle]="inputTitle | translate"
></app-auto-complete-list-group>
