<ng-container *appHasAnyAuthority="[authority.Admin, authority.Teacher, authority.Staff]">
  <div id="mainMenu" class="menu menu-vertical">
    <div class="menu-nav-wrapper">
      <div class="menu-header">
        <div class="app-logo"><img src="assets/images/flex-logo-color.svg" height="24px" alt="FlexTime Manager Logo" /></div>
        <button
          (click)="onImpersonate()"
          *appHasAnyAuthority="[authority.Admin]"
          type="button"
          class="round-btn round-btn-primary ml-auto"
          [appTooltip]="'SIMULATE' | translate"
          placement="left"
        >
          <i class="ftmi-impersonate"></i>
        </button>
      </div>
      <div class="school-header">
        <div class="btn-group" dropdown (isOpenChange)="onOpenChange($event)">
          <a id="school-dropdown" dropdownToggle class="school-brand dropdown-toggle" aria-controls="dropdown-school">
            <span class="school-logo"
              ><img [src]="school?.logo || 'assets/images/school-logo-default.svg' | safeUrl"
            /></span>
            <span class="school-name">{{ school?.name }}</span>
          </a>
          <ul id="dropdown-school" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="school-dropdown">
            <div *ngIf="shouldShowLoading" class="spinner"></div>
            <li *ngFor="let school of schools" role="menuitem">
              <button (click)="onChooseSchool(school)" class="dropdown-item">{{ school.name }}</button>
            </li>
          </ul>
          <app-school-year-selector></app-school-year-selector>
        </div>
        <div *appHasAnyAuthority="authority.Admin" class="school-utility">
          <span *ngIf="!shouldShowSubMenu" (click)="onOpenSubMenu()" class="btn btn-settings">
            <i class="icon ftmi-cog"></i>
            <span class="title">{{ 'SCHOOL_SETTINGS' | translate }}</span>
          </span>

          <span *ngIf="shouldShowSubMenu" (click)="onHideSubMenu()" class="btn btn-settings btn-settings-back">
            <i class="icon ftmi-arrow-left"></i>
            <span class="title">{{ 'BACK' | translate }}</span>
          </span>
        </div>
      </div>

      <ul class="menu-nav">
        <ng-container *ngIf="!shouldShowSubMenu">
          <li *ngFor="let item of menuItems" class="menu-list-parent">
            <a
              *ngIf="item.isParentLink"
              class="menu-list-item menu-list-link"
              (click)="updateActiveItemClass(item)"
              [class.active]="item.isActive"
              routerLink="/{{ item.routerLink }}"
              ><i class="icon {{ item.className }}"></i> <span class="title">{{ item.parentName }}</span></a
            >
            <div *ngIf="!item.isParentLink">
              <button
                type="button"
                class="btn menu-list-item"
                (click)="item.isCollapsed = !item.isCollapsed"
                [attr.aria-expanded]="!item.isCollapsed"
                [attr.aria-controls]="item.id"
                [ngClass]="{ 'expanded-parent': !item.isCollapsed }"
              >
                {{ item.parentName }}
                <i class="icon-dd ftmi-chevron-down"></i>
              </button>

              <div id="{{ item.id }}" [collapse]="item.isCollapsed || false" [isAnimated]="true">
                <ul class="menu-list">
                  <ng-container *ngFor="let childItem of item.items">
                    <li class="menu-list-child">
                      <a
                        class="menu-list-item menu-list-link"
                        (click)="updateActiveItemClass(childItem)"
                        [class.active]="childItem.isActive"
                        [class.disabled]="childItem.isActive"
                        routerLink="/{{ childItem.routerLink }}"
                        ><i class="icon {{ childItem.className }}"></i>
                        <span class="title">{{ childItem.childName }}</span></a
                      >
                    </li>
                  </ng-container>
                </ul>
              </div>
            </div>
            <div class="menu-list-separator"><hr /></div>
          </li>
        </ng-container>

        <ng-container *ngIf="shouldShowSubMenu">
          <li *ngFor="let item of subMenuItems" class="menu-list-parent">
            <button
              type="button"
              class="btn menu-list-item"
              (click)="item.isCollapsed = !item.isCollapsed"
              [attr.aria-expanded]="!item.isCollapsed"
              [attr.aria-controls]="item.id"
            >
              {{ item.parentName }}
            </button>

            <div id="{{ item.id }}" [collapse]="item.isCollapsed || false" [isAnimated]="true">
              <ul class="menu-list">
                <ng-container *ngFor="let childItem of item.items">
                  <li class="menu-list-child">
                    <a
                      class="menu-list-item menu-list-link"
                      (click)="updateActiveItemClass(childItem)"
                      [class.active]="childItem.isActive"
                      [class.disabled]="childItem.isActive"
                      routerLink="/{{ childItem.routerLink }}"
                      ><i class="icon {{ childItem.className }}"></i>
                      <span class="title">{{ childItem.childName }}</span></a
                    >
                  </li>
                </ng-container>
              </ul>
            </div>
          </li>
          <li class="menu-list-separator"><hr /></li>
        </ng-container>
      </ul>
    </div>
    <div class="menu-utility">
      <div class="user-utility">
        <div class="btn-group" dropdown [dropup]="true">
          <button
            id="button-basic"
            dropdownToggle
            type="button"
            class="btn user-dropdown dropdown-toggle w-100 px-0"
            aria-controls="dropdown-basic"
            [appTooltip]="'MY_PROFILE' | translate"
          >
            <span class="user-name">{{ user?.user?.firstName }}</span>
            <span class="caret"></span>
          </button>
          <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu w-100" role="menu" aria-labelledby="button-basic">
            <li role="menuitem">
              <a class="dropdown-item" routerLink="/{{ appRoute.MyProfile }}">{{ 'MY_PROFILE' | translate }}</a>
            </li>
            <li class="divider dropdown-divider"></li>
            <li role="menuitem">
              <span class="btn btn-sm logout" (click)="onLogOut()">{{ 'LOGOUT' | translate }}</span>
            </li>
          </ul>
        </div>
        <a routerLink="/notifications" class="btn btn-notifications" appFeatureFlagHidden="notifications" [appTooltip]="'NOTIFICATIONS' | translate">
          <i class="ftmi-notification"></i>
          <span class="unread" *ngIf="unreadNotificationCount > 0">{{unreadNotificationCount}}</span>
        </a>
      </div>
    </div>
  </div>

  <div id="mobileMenu" class="mobile-menu-btn" (click)="openMenu()">
    <div class="bar-top"></div>
    <div class="bar-middle"></div>
    <div class="bar-bottom"></div>
  </div>
</ng-container>

<ng-container *appHasAnyAuthority="[authority.Substitute, authority.Student]">
  <div id="mainMenu" class="menu menu-horizontal">
    <div class="menu-nav-wrapper">
      <div class="menu-header">
        <div class="app-logo"><img src="assets/images/flex-logo-color.svg" height="24px" alt="FlexTime Manager Logo" /></div>
      </div>
      <ul class="menu-nav">
        <li *ngFor="let item of menuItems" class="menu-list-child">
          <a
            *ngIf="item.isParentLink"
            class="menu-list-item"
            (click)="updateActiveItemClass(item)"
            [class.active]="item.isActive"
            routerLink="/{{ item.routerLink }}"
            ><i class="icon {{ item.className }}"></i> <span class="title">{{ item.parentName }}</span></a
          >
          <div *ngIf="!item.isParentLink" dropdown class="dd-menu">
            <button
              type="button"
              class="btn menu-list-item dropdown-toggle dropdown-toggle"
              (click)="item.isCollapsed = !item.isCollapsed"
              [attr.aria-expanded]="!item.isCollapsed"
              [attr.aria-controls]="item.id"
              [ngClass]="{ 'expanded-parent': !item.isCollapsed }"
              dropdownToggle
            >
              <i class="icon {{ item.className }}"></i>
              {{ item.parentName }}
            </button>

            <ul
              id="{{ item.id }}"
              *dropdownMenu
              class="dropdown-menu dropdown-main-menu"
              role="menu"
              [attr.aria-labelledby]="item.id"
            >
              <ng-container *ngFor="let childItem of item.items">
                <li>
                  <a
                    class="menu-list-item menu-list-link"
                    (click)="updateActiveItemClass(childItem)"
                    [class.active]="childItem.isActive"
                    [class.disabled]="childItem.isActive"
                    routerLink="/{{ childItem.routerLink }}"
                    ><i class="icon {{ childItem.className }}"></i>
                    <span class="title">{{ childItem.childName }}</span></a
                  >
                </li>
              </ng-container>
            </ul>
          </div>
        </li>
      </ul>
    </div>
    <div class="menu-utility">
      <div class="user-utility">
        <a (click)="navigateToNotifications()" class="round-btn round-btn-plain btn-notifications" appFeatureFlagHidden="notifications" [appTooltip]="'NOTIFICATIONS' | translate">
          <i class="icon ftmi-notification"></i>
          <span class="unread" *ngIf="unreadNotificationCount > 0">{{unreadNotificationCount}}</span>
        </a>
        <div class="school-header-horizontal">
          <div class="btn-group" dropdown (isOpenChange)="onOpenChange($event)">
            <a id="school-dropdown-horizontal" dropdownToggle class="school-brand dropdown-toggle" aria-controls="dropdown-school">
              <span class="school-logo-horizontal"
              ><img [src]="school?.logo || 'assets/images/school-logo-default.svg' | safeUrl"
              /></span>
              <span class="school-name">{{ school?.name }}</span>
            </a>
  
            <ul id="dropdown-school-horizontal" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="school-dropdown">
              <div *ngIf="shouldShowLoading" class="spinner"></div>
              <li *ngFor="let school of schools" role="menuitem">
                <button (click)="onChooseSchool(school)" class="dropdown-item">{{ school.name }}</button>
              </li>
            </ul>
          </div>
        </div>
        <div *appHasAnyAuthority="authority.Student" class="btn-group dd-menu user-dropdown" dropdown>
          <button
            id="button-basic"
            dropdownToggle
            type="button"
            class="btn dropdown-toggle"
            aria-controls="dropdown-basic"
          >
            <i class="icon ftmi-user"></i>
            <span class="user-name">{{ user?.user?.firstName }}</span>
            <span class="caret"></span>
          </button>
          <ul
            id="dropdown-basic"
            *dropdownMenu
            class="dropdown-menu dropdown-menu-right"
            role="menu"
            aria-labelledby="button-basic"
          >
            <li role="menuitem">
              <a class="dropdown-item" routerLink="/{{ appRoute.MyProfile }}">{{ 'MY_PROFILE' | translate }}</a>
            </li>
            <li class="divider dropdown-divider"></li>
            <li role="menuitem">
              <span class="btn btn-outline-primary btn-xs logout" (click)="onLogOut()">{{ 'LOGOUT' | translate }}</span>
            </li>
          </ul>
        </div>
        <div *appHasAnyAuthority="authority.Substitute" class="menu-list-child">
          <span class="menu-list-item" (click)="onLogOut()"
            ><i class="icon ftmi-logout"></i>{{ 'LOGOUT' | translate }}</span
          >
        </div>
      </div>
    </div>
  </div>

  <div id="mobileMenu" class="mobile-menu-btn" (click)="openMenu()">
    <div class="bar-top"></div>
    <div class="bar-middle"></div>
    <div class="bar-bottom"></div>
  </div>
</ng-container>
