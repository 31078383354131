import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { DestroyNotifierComponent } from 'src/app/core/component/destroy/destroy-notifier.component';
import { FlexService } from 'src/app/core/services/http/flex/flex.service';
import { Flex } from 'src/app/core/services/http/flex/interfaces/flex';
import { DateManagerService } from 'src/app/core/services/date-manager/date-manager.service';
import { Paging } from 'src/app/core/services/http/general/classes/paging';
import { ExtendedDate } from 'src/app/core/services/date-manager/classes/extended-date';
import { EventType } from "src/app/constants/events-type";
import { EventManagerService } from "src/app/core/services/event-manager/event-manager.service";
import { DashboardService } from 'src/app/core/services/http/dashboard/dashboard.service';

@Component({
  selector: 'app-flex-drop-down',
  templateUrl: './flex-drop-down.component.html'
})
export class FlexDropDownComponent extends DestroyNotifierComponent implements OnInit {
  @Output() selectedFlexChange = new EventEmitter<Flex>();
  @Output() flexsLoaded = new EventEmitter<Flex[]>();

  @Input() shouldShowAllFlexs: boolean | undefined;
  @Input() flexs: Flex[] | undefined;
  @Input() selectedCalendarDate: ExtendedDate | undefined;

  constructor(
    private flexService: FlexService,
    private dateManagerService: DateManagerService,
    private eventManager: EventManagerService,
    private dashboardService: DashboardService,
  ) {
    super();
  }

  ngOnChanges(): void {
    this.setRunAlgoButtonStatus()
  }

  ngOnInit(): void {
    if (!this.flexs) {
      this.loadFlexs();
      this.eventManager.subscribe(EventType.AutoAssignStatus, () => {
        this.setRunAlgoButtonStatus()
      });
    }
  }

  onSelectFlex(flex: Flex): void {
    this.selectedFlexChange.emit(flex);
  }

  private loadFlexs(): void {
    let filter = { status: 1 };

    if (this.selectedCalendarDate) {
      filter = { ...filter, ...{ date: this.dateManagerService.dateToString(this.selectedCalendarDate) } };
    }

    this.flexService
      .getAll(new Paging().getAllRecords(), filter)
      .pipe(takeUntil(this.destroyNotify))
      .subscribe(flexs => {
        this.flexs = this.addAllFlexsByShouldShowAllFlexs(flexs);
        this.flexsLoaded.emit(this.flexs);
      });
  }

  private addAllFlexsByShouldShowAllFlexs(flexs: Flex[]): Flex[] {
    if (this.shouldShowAllFlexs) {
      const clonedFlexs = [...flexs];
      // @ts-ignore
      clonedFlexs.unshift({ name: 'All Flex Periods', flexTimeStr: '' });

      return clonedFlexs;
    } else {
      return flexs;
    }
  }

  setRunAlgoButtonStatus(){
     
    this.flexs?.forEach((flex)=>{
      flex.isAssignMentIsInprocess = false;
    })

    const selectedCalendarDate = this.dashboardService.formatDateToString(this.selectedCalendarDate as ExtendedDate);
    
    const uuidsForSelectedDate = new Set(
      this.dashboardService.listOfInProcessAlgorithmFlex
          .filter((item:any) => selectedCalendarDate === this.dashboardService.formatDateToString(item.date))
          .map((item:any) => item.uuid)
    );

    if (this.flexs?.length) {
      this.flexs.forEach(item => {
          if (uuidsForSelectedDate.has(item.uuid)) {
              item.isAssignMentIsInprocess = true;
          }
      });
    }
  }
}
