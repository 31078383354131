import { Injectable, isDevMode } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { throwError } from 'rxjs';
import { AppRoute } from 'src/app/constants/app.route';
import { Authority } from 'src/app/constants/authority';
import { MenuItem } from './interfaces/menu-item';

@Injectable({
  providedIn: 'root'
})
export class MenuBuilderService {
  constructor(private translate: TranslateService) {}

  menuItems: MenuItem[] | undefined;
  subMenuItems: MenuItem[] | undefined;
  shouldShowSubMenu: boolean | undefined;

  menuBuilders = {
    [Authority.Admin]: this.getAdminMenu,
    [Authority.Staff]: this.getStaffMenu,
    [Authority.Substitute]: this.getSubstituteMenu,
    [Authority.Teacher]: this.getTeacherMenu,
    [Authority.Student]: this.getStudentMenu
  } as Record<Authority, () => Promise<MenuItem[]>>;

  getShouldShowSubMenuState(): boolean | undefined {
    return this.shouldShowSubMenu;
  }

  setShouldShowSubMenuState(shouldShowSubMenu: boolean): void {
    this.shouldShowSubMenu = shouldShowSubMenu;
  }

  async getMenuByAuthority(authority: Authority): Promise<MenuItem[] | undefined> {
    if (this.menuItems) {
      return this.menuItems;
    }

    if (this.menuBuilders[authority]) {
      this.menuItems = await this.filterMenuItemsByVisibility(this.menuBuilders[authority].bind(this)());
    } else {
      if (isDevMode()) {
        throwError(`There is no defined mapping for this authority - ${authority} and method that must build the menu`);
      }
    }

    return this.menuItems;
  }

  async getSubMenuByAuthority(authority: Authority): Promise<MenuItem[] | undefined> {
    if (this.subMenuItems) {
      return this.subMenuItems;
    }

    if (authority !== Authority.Admin) {
      return;
    }

    const translations = await this.translate
      .get([
        'MY_SCHOOL',
        'FLEX_PERIODS',
        'ACTIVITY_TYPES',
        'PRIORITIES',
        'ROOMS',
        'USERS',
        'ANNOUNCEMENTS',
        'LOG',
        'SYSTEM_SETTINGS',
        'SFTP_FILES',
        'A_B_POLARITY'
      ])
      .toPromise();

    this.subMenuItems = [
      {
        parentName: translations.MY_SCHOOL,
        id: 'collapseSchool',
        isCollapsed: false,
        items: [
          {
            className: 'ftmi-flex-period',
            childName: translations.FLEX_PERIODS,
            routerLink: AppRoute.Flex
          },
          {
            className: 'ftmi-types',
            childName: translations.ACTIVITY_TYPES,
            routerLink: AppRoute.ActivityTypes
          },
          {
            className: 'ftmi-priority',
            childName: translations.PRIORITIES,
            routerLink: AppRoute.Priorities
          },
          {
            className: 'ftmi-school',
            childName: translations.ROOMS,
            routerLink: AppRoute.Room
          },
          {
            className: 'ftmi-users',
            childName: translations.USERS,
            routerLink: AppRoute.User
          },
          {
            className: 'ftmi-announcement',
            childName: translations.ANNOUNCEMENTS,
            routerLink: AppRoute.Announcement
          },
          // {
          //   className: 'ftmi-log',
          //   childName: translations.LOG,
          //   routerLink: AppRoute.Log
          // },
          {
            className: 'ftmi-cog',
            childName: translations.SYSTEM_SETTINGS,
            routerLink: AppRoute.SystemSettings
          },
          {
            className: 'ftmi-archive',
            childName: translations.SFTP_FILES,
            routerLink: AppRoute.SftpFiles
          }
        ]
      }
    ];

    return this.subMenuItems;
  }

  private async getAdminMenu(): Promise<MenuItem[]> {
    const translations = await this.translate
      .get([
        'REPORTS',
        'CUT_LIST',
        'FULL_ATTENDANCE',
        'EXCLUDED_STUDENTS',
        'ATTENDANCE_ACTIVITY',
        'ROOM_USE',
        'STUDENT_SEARCH',
        'STUDENT_LIST',
        'ROSTERS_LEGACY',
        'RESTRICTED_STUDENTS',
        'A_B_POLARITY',
        'TEACHERS',
        'TEACHER_LIST',
        'TEACHER_ATTENDANCE',
        'SUBSTITUTES'
      ])
      .toPromise();

    const dashboardMenuCategory = await this.getDashboardMenuCategory();
    const myScheduleMenuCategory = await this.getMyScheduleMenuCategory();
    const helpMenuCategory = await this.getHelpMenuCategory();
    const attendanceMenuCategory = await this.getAttendanceMenuCategory();
    const studentsMenuCategory = await this.getStudentsMenuCategory();

    const activitiesListMenuItem = await this.getActivitiesListMenuItem();
    const directoryMenuItem = await this.getDirectoryMenuItem();
    const cancellationMenuItem = await this.getCancellationMenuItem();
    myScheduleMenuCategory.items?.push(activitiesListMenuItem, directoryMenuItem, cancellationMenuItem);

    const polarityMenuItem = await this.getABPolarityMenuItem();
    studentsMenuCategory.items?.push(polarityMenuItem);

    return [
      dashboardMenuCategory,
      myScheduleMenuCategory,
      {
        parentName: translations.REPORTS,
        id: 'collapseReports',
        isCollapsed: true,
        items: [
          {
            className: 'ftmi-custlist',
            childName: translations.CUT_LIST,
            routerLink: AppRoute.CutList
          },
          {
            className: 'ftmi-full-attendance',
            childName: translations.FULL_ATTENDANCE,
            routerLink: AppRoute.FullAttendance
          },
          {
            className: 'ftmi-excluded-students',
            childName: translations.EXCLUDED_STUDENTS,
            routerLink: AppRoute.ExcludedStudents
          },
          {
            className: 'ftmi-attendance-activity',
            childName: translations.ATTENDANCE_ACTIVITY,
            routerLink: AppRoute.AttendanceActivity
          },
          {
            className: 'ftmi-room-use',
            childName: translations.ROOM_USE,
            routerLink: AppRoute.RoomUse
          }
        ]
      },
      attendanceMenuCategory,
      studentsMenuCategory,
      {
        parentName: translations.TEACHERS,
        id: 'collapseTeachers',
        isCollapsed: true,
        items: [
          {
            className: 'ftmi-teacher',
            childName: translations.TEACHER_LIST,
            routerLink: AppRoute.TeacherList
          },
          {
            className: 'ftmi-teacher-attendance',
            childName: translations.TEACHER_ATTENDANCE,
            routerLink: AppRoute.TeacherAttendance
          },
          {
            className: 'ftmi-substitute',
            childName: translations.SUBSTITUTES,
            routerLink: AppRoute.Substitute
          }
        ]
      },
      helpMenuCategory
    ];
  }

  private async getSubstituteMenu(): Promise<MenuItem[]> {
    const translations = await this.translate.get(['STUDENT_ATTENDANCE']).toPromise();
    const helpMenuCategory = await this.getHelpMenuCategory();

    return [
      {
        id: 'collapseAttendance',
        parentName: translations.STUDENT_ATTENDANCE,
        className: 'ftmi-student-attendance',
        isCollapsed: true,

        routerLink: AppRoute.StudentAttendance,
        isParentLink: true
      },
      helpMenuCategory
    ];
  }

  private async getStaffMenu(): Promise<MenuItem[]> {
    const translations = await this.translate
      .get([
        'REPORTS',
        'CUT_LIST',
        'FULL_ATTENDANCE',
        'EXCLUDED_STUDENTS',
        'ATTENDANCE_ACTIVITY',
        'ROOM_USE',
        'STUDENT_SEARCH',
        'STUDENT_LIST',
        'ROSTERS_LEGACY',
        'RESTRICTED_STUDENTS',
        'TEACHERS',
        'TEACHER_LIST',
        'TEACHER_ATTENDANCE',
        'SUBSTITUTES'
      ])
      .toPromise();

    const myScheduleMenuCategory = await this.getMyScheduleMenuCategory();
    const attendanceMenuCategory = await this.getAttendanceMenuCategory();
    const studentsMenuCategory = await this.getStudentsMenuCategory();
    const helpMenuCategory = await this.getHelpMenuCategory();

    const activitiesListMenuItem = await this.getActivitiesListMenuItem();
    const directoryMenuItem = await this.getDirectoryMenuItem();
    const cancellationMenuItem = await this.getCancellationMenuItem();
    myScheduleMenuCategory.items?.push(activitiesListMenuItem, directoryMenuItem, cancellationMenuItem);

    return [
      myScheduleMenuCategory,
      {
        parentName: translations.REPORTS,
        id: 'collapseReports',
        isCollapsed: true,
        items: [
          {
            className: 'ftmi-custlist',
            childName: translations.CUT_LIST,
            routerLink: AppRoute.CutList
          },
          {
            className: 'ftmi-full-attendance',
            childName: translations.FULL_ATTENDANCE,
            routerLink: AppRoute.FullAttendance
          },
          {
            className: 'ftmi-excluded-students',
            childName: translations.EXCLUDED_STUDENTS,
            routerLink: AppRoute.ExcludedStudents
          },
          {
            className: 'ftmi-attendance-activity',
            childName: translations.ATTENDANCE_ACTIVITY,
            routerLink: AppRoute.AttendanceActivity
          },
          {
            className: 'ftmi-room-use',
            childName: translations.ROOM_USE,
            routerLink: AppRoute.RoomUse
          }
        ]
      },
      attendanceMenuCategory,
      studentsMenuCategory,
      {
        parentName: translations.TEACHERS,
        id: 'collapseTeachers',
        isCollapsed: true,
        items: [
          {
            className: 'ftmi-teacher-attendance',
            childName: translations.TEACHER_ATTENDANCE,
            routerLink: AppRoute.TeacherAttendance
          },
          {
            className: 'ftmi-substitute',
            childName: translations.SUBSTITUTES,
            routerLink: AppRoute.Substitute
          }
        ]
      },
      helpMenuCategory
    ];
  }

  private async getTeacherMenu(): Promise<MenuItem[]> {
    const myScheduleMenuCategory = await this.getMyScheduleMenuCategory();
    const attendanceMenuCategory = await this.getAttendanceMenuCategory();
    const studentsMenuCategory = await this.getStudentsMenuCategory();
    const helpMenuCategory = await this.getHelpMenuCategory();

    const activitiesListMenuItem = await this.getActivitiesListMenuItem();
    const directoryMenuItem = await this.getDirectoryMenuItem();
    const cancellationMenuItem = await this.getCancellationMenuItem();
    myScheduleMenuCategory.items?.push(activitiesListMenuItem, directoryMenuItem, cancellationMenuItem);

    return [myScheduleMenuCategory, attendanceMenuCategory, studentsMenuCategory, helpMenuCategory];
  }

  private async getStudentMenu(): Promise<MenuItem[]> {
    const translations = await this.translate.get(['MY_SCHEDULE', 'DIRECTORY', 'QR']).toPromise();
    const helpMenuCategory = await this.getHelpMenuCategory();
    return [
      {
        id: 'collapseActivities',
        parentName: translations.MY_SCHEDULE,
        className: 'ftmi-schedule',
        routerLink: AppRoute.Schedule,
        isParentLink: true
      },
      {
        id: 'collapseActivities',
        parentName: translations.DIRECTORY,
        className: 'ftmi-directory',
        routerLink: AppRoute.Directory,
        isParentLink: true
      },
      {
        id: 'collapseActivities',
        parentName: translations.QR,
        className: 'ftmi-qr',
        routerLink: AppRoute.QRCode,
        isParentLink: true
      },
      helpMenuCategory
    ];
  }

  private async getHelpMenuCategory(): Promise<MenuItem> {
    const translations = await this.translate.get(['HELP', 'TRAINING_VIDEOS', 'DOCUMENTATION', 'SUPPORT']).toPromise();

    return {
      parentName: translations.HELP,
      id: 'collapseHelp',
      isCollapsed: true,
      className: 'ftmi-help',
      items: [
        {
          className: 'ftmi-play',
          childName: translations.TRAINING_VIDEOS,
          routerLink: AppRoute.TrainingVideos
        },
        {
          className: 'ftmi-file',
          childName: translations.DOCUMENTATION,
          routerLink: AppRoute.Documentation
        }
        // {
        //   className: 'ftmi-help',
        //   childName: translations.SUPPORT,
        //   routerLink: '' as any
        // }
      ]
    };
  }

  private async getDashboardMenuCategory(): Promise<MenuItem> {
    const translations = await this.translate.get(['DASHBOARD']).toPromise();

    return {
      parentName: translations.DASHBOARD,
      id: 'collapseDashboard',
      className: 'ftmi-dashboard',
      routerLink: AppRoute.Dashboard,
      isParentLink: true
    };
  }

  private async getMyScheduleMenuCategory(): Promise<MenuItem> {
    const translations = await this.translate.get(['MY_SCHEDULE', 'ACTIVITIES']).toPromise();

    return {
      parentName: translations.ACTIVITIES,
      id: 'collapseActivities',
      isCollapsed: true,
      items: [
        {
          className: 'ftmi-schedule',
          childName: translations.MY_SCHEDULE,
          routerLink: AppRoute.Schedule
        }
      ]
    };
  }

  private async getStudentsMenuCategory(): Promise<MenuItem> {
    const translations = await this.translate
      .get(['STUDENTS', 'STUDENT_SEARCH', 'STUDENT_LIST', 'ROSTERS_LEGACY', 'RESTRICTED_STUDENTS'])
      .toPromise();

    return {
      parentName: translations.STUDENTS,
      id: 'collapseStudents',
      isCollapsed: true,
      items: [
        {
          className: 'ftmi-student-search',
          childName: translations.STUDENT_SEARCH,
          routerLink: AppRoute.StudentSearch
        },
        {
          className: 'ftmi-group',
          childName: translations.STUDENT_LIST,
          routerLink: AppRoute.StudentList
        },
        {
          className: 'ftmi-roster',
          childName: translations.ROSTERS_LEGACY,
          routerLink: AppRoute.Roster
        },
        {
          className: 'ftmi-restricted-student',
          childName: translations.RESTRICTED_STUDENTS,
          routerLink: AppRoute.RestrictedStudents
        }
      ]
    };
  }

  private async getAttendanceMenuCategory(): Promise<MenuItem> {
    const translations = await this.translate.get(['ATTENDANCE', 'STUDENT_ATTENDANCE']).toPromise();

    return {
      parentName: translations.ATTENDANCE,
      id: 'collapseAttendance',
      isCollapsed: true,
      items: [
        {
          className: 'ftmi-student-attendance',
          childName: translations.STUDENT_ATTENDANCE,
          routerLink: AppRoute.StudentAttendance
        }
      ]
    };
  }

  private async getActivitiesListMenuItem(): Promise<MenuItem> {
    const translations = await this.translate.get(['ACTIVITIES_LIST']).toPromise();

    return {
      className: 'ftmi-activities',
      childName: translations.ACTIVITIES_LIST,
      routerLink: AppRoute.Activity
    };
  }

  private async getDirectoryMenuItem(): Promise<MenuItem> {
    const translations = await this.translate.get(['DIRECTORY']).toPromise();

    return {
      className: 'ftmi-directory',
      childName: translations.DIRECTORY,
      routerLink: AppRoute.Directory
    };
  }

  private async getABPolarityMenuItem(): Promise<MenuItem> {
    const translations = await this.translate.get(['A_B_POLARITY']).toPromise();

    return {
      className: 'ftmi-a-b-polarity',
      childName: translations.A_B_POLARITY,
      routerLink: AppRoute.ABPolarity
    };
  }

  private async getCancellationMenuItem(): Promise<MenuItem> {
    const translations = await this.translate.get(['CANCELLATION']).toPromise();

    return {
      className: 'ftmi-cancellation',
      childName: translations.CANCELLATION,
      routerLink: AppRoute.Cancellation
    };
  }

  private async filterMenuItemsByVisibility(menuitems: Promise<MenuItem[]>): Promise<MenuItem[]> {
    return (await menuitems).filter(item => item.visibility !== false);
  }
}
