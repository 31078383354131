import { Component } from '@angular/core';
import { SchoolYear } from '../../core/services/date-manager/school-year/school-year.interface';
import { SchoolYearRegistry } from '../../core/services/date-manager/school-year/school-year.registry';
import { AuthFactoryService } from '../../core/services/auth/auth-factory.service';
import { Authority } from '../../constants/authority';

@Component({
    selector: 'app-school-year-selector',
    templateUrl: './school-year-selector.component.html'
})
export class SchoolYearSelectorComponent
{
    public constructor(
        public schoolYearRegistry: SchoolYearRegistry,
        private authFactoryService: AuthFactoryService
    ) {
    }

    public onChooseSchoolYear(schoolYear: SchoolYear): void {
        this.schoolYearRegistry.selectedSchoolYear = schoolYear;
        this.reloadApplication();
    }

    private reloadApplication(): void {
        if (this.authFactoryService.service.getSchoolUserAuthority()?.role === Authority.Admin) {
            window.location.assign('/dashboard');
        } else {
            window.location.assign('/schedule');
        }
    }
}

