import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthFactoryService } from '../../auth/auth-factory.service';
import { RestGenericUtilService } from '../rest-generic-util.service';
import { RestGeneric } from '../rest.generic';
import { DashboardActivity } from './interfaces/dashboard-activity';
import { FlexPeriodCutoff } from './interfaces/flex-period-cutoff';
import { FlexPeriodMetrics } from './interfaces/flex-period-metrics';
import { StudentMetrics } from './interfaces/student-metrics';
import { TeacherMetrics } from './interfaces/teacher-metrics';
import { TeacherActivitiesMetrics } from './interfaces/teacher-activities-metrics';
import { StudentRestrictionMetrics } from './interfaces/student-restriction-metrics';
import { map } from 'rxjs/operators';
import { DateManagerService } from '../../date-manager/date-manager.service';
import { ExtendedDate } from '../../date-manager/classes/extended-date';

@Injectable({
  providedIn: 'root'
})
export class DashboardService extends RestGeneric<any, DashboardActivity, any> {
  listOfInProcessAlgorithmFlex:any = [];

  constructor(
    protected http: HttpClient,
    protected authFactoryService: AuthFactoryService,
    protected restGenericUtilService: RestGenericUtilService,
    private dateManagerService: DateManagerService
  ) {
    super(http, authFactoryService, 'dashboard', restGenericUtilService);
  }

  getFlexPeriodMetrics(flexPeriodUuid: string, filter?: Record<string, any>): Observable<FlexPeriodMetrics> {
    const params = this.restGenericUtilService.toParams({
      ...filter
    });

    return this.http.get<FlexPeriodMetrics>(
      `${environment.EduSpireApi.baseUrl}/district/school/${this.schoolUUid}/dashboard/admin-flex-period-details/${flexPeriodUuid}`,
      {
        params
      }
    );
  }

  getEmployeeMetrics(flexPeriodUuid: string): Observable<TeacherMetrics> {
    const params = this.restGenericUtilService.toParams({
      ...{ flexPeriod: flexPeriodUuid }
    });

    return this.http.get<TeacherMetrics>(
      `${environment.EduSpireApi.baseUrl}/district/school/${this.schoolUUid}/dashboard/teacher-flex-period-details`,
      {
        params
      }
    );
  }

  getEmployeeAllFlexPeriodsMetrics(): Observable<TeacherMetrics> {
    const params = this.restGenericUtilService.toParams({
      ...{ allFlexPeriods: true }
    });

    return this.http.get<TeacherMetrics>(
      `${environment.EduSpireApi.baseUrl}/district/school/${this.schoolUUid}/dashboard/teacher-flex-period-details`,
      {
        params
      }
    );
  }

  getEmployeeActivitiesMetrics(flexPeriodUuid: string|null): Observable<TeacherActivitiesMetrics> {
    const params = this.restGenericUtilService.toParams({
      ...{ flexPeriod: flexPeriodUuid }
    });

    return this.http.get<TeacherActivitiesMetrics>(
      `${environment.EduSpireApi.baseUrl}/district/school/${this.schoolUUid}/dashboard/teacher-activities`,
      {
        params
      }
    );
  }

  getStudentsMetrics(): Observable<StudentMetrics> {
    return this.http.get<StudentMetrics>(
      `${environment.EduSpireApi.baseUrl}/district/school/${this.schoolUUid}/dashboard/student-flex-period-details`
    );
  }

  getStudentsRestrictions(): Observable<StudentRestrictionMetrics> {
    return this.http.get<StudentRestrictionMetrics>(
      `${environment.EduSpireApi.baseUrl}/district/school/${this.schoolUUid}/dashboard/student-restrictions`
    );
  }

  getFlexPeriodCutoffs(filter?: Record<string, any>): Observable<FlexPeriodCutoff[]> {
    if (!filter?.date) {
      return throwError('filter.date can`t be null');
    }

    const params = this.restGenericUtilService.toParams({
      ...filter
    });

    return this.http
      .get<FlexPeriodCutoff[]>(
        `${environment.EduSpireApi.baseUrl}/district/school/${this.schoolUUid}/dashboard/cutofftimes`,
        {
          params
        }
      )
      .pipe(
        map(res => {
          res.map(item => {
            const cutoffDate = this.dateManagerService.getDateTimeBy(
              item.flexPeriod.cutoffDays,
              item.flexPeriod.cutoffTime,
              item.flexPeriod.cutoffCountWeekends,
              filter.date
            );

            const dateBySchoolTimeZone = this.dateManagerService.getDateBySchoolUtcOffset(new ExtendedDate());

            item.isCutOffTimeElapsed = dateBySchoolTimeZone > cutoffDate;

            return item;
          });

          return res;
        })
      );
  }

  runAutoAssignAlgorithm(flexPeriodUuid: string|null,archiveOld:number,selectedDate:Date) {
    const params = this.restGenericUtilService.toParams({
      ...{ date: selectedDate,archiveOld: archiveOld }
    });

    return this.http.get<TeacherActivitiesMetrics>(
      `${environment.EduSpireApi.baseUrl}/district/school/${this.schoolUUid}/dashboard/flex-period/${flexPeriodUuid}/auto-assign-algorithm`,
      {
        params
      }
    );
  }

  formatDateToString(dateObj:Date) {
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const day = String(dateObj.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
}
