import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthFactoryService } from '../../auth/auth-factory.service';
import { RestGenericUtilService } from '../rest-generic-util.service';
import { RestGeneric } from '../rest.generic';
import { ActivityPriorityType } from './enums/activity-priority-type';
import { ActivityPriority } from './interfaces/activity-priority';
import { ActivityPriorityRequest } from './interfaces/activity-priority-request';

@Injectable({
  providedIn: 'root'
})
export class ActivityPriorityService extends RestGeneric<ActivityPriorityRequest, ActivityPriority, any> {
  constructor(
    protected http: HttpClient,
    protected authFactoryService: AuthFactoryService,
    protected restGenericUtilService: RestGenericUtilService
  ) {
    super(http, authFactoryService, 'activity-priority', restGenericUtilService);
  }

  getByType(type: ActivityPriorityType): Observable<ActivityPriority> {
    return this.http.get<ActivityPriority>(`${this.baseApiUrl}/${this.schoolUUid}/${this.endpointName}/${type}`);
  }

  patchByType(
    activityPriorityRequest: ActivityPriorityRequest,
    type: ActivityPriorityType
  ): Observable<ActivityPriority> {
    return this.http.patch<ActivityPriority>(
      `${this.baseApiUrl}/${this.schoolUUid}/${this.endpointName}/${type}`,
      activityPriorityRequest
    );
  }
}
