import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { Authority } from 'src/app/constants/authority';
import { AuthFactoryService } from '../../services/auth/auth-factory.service';

@Directive({
  selector: '[appHasAnyAuthority]'
})
export class HasAnyAuthorityDirective {
  constructor(
    private authFactoryService: AuthFactoryService,
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef
  ) {}

  @Input()
  set appHasAnyAuthority(value: Authority | Authority[]) {
    const authority = Array.isArray(value) ? value : [value];
    this.updateViewContainerRef(authority);
  }

  private updateViewContainerRef(authority: Authority[]): void {
    const hasAnyAuthority = this.authFactoryService.service.hasAnyAuthority(authority);
    this.viewContainerRef.clear();

    if (hasAnyAuthority) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    }
  }
}
