import { Injectable } from '@angular/core';
import { weekDaysShortNames } from 'src/app/constants/week-days';
import { ExtendedDate } from 'src/app/core/services/date-manager/classes/extended-date';
import { DateManagerService } from 'src/app/core/services/date-manager/date-manager.service';
import { Flex } from 'src/app/core/services/http/flex/interfaces/flex';
import { FlexPeriodScheduledActivity } from 'src/app/core/services/http/scheduled-activity/interfaces/flex-period-scheduled-activity';
import { SystemSettings } from 'src/app/core/services/http/system-settings/interfaces/system-settings';
import { FlexCalendarDay } from '../interfaces/flex-calendar-day';

@Injectable({
  providedIn: 'root'
})
export class FlexViewCalendarService {
  constructor(private dateManagerService: DateManagerService) {}

  getFlexCalendarDays(date = new ExtendedDate()): FlexCalendarDay[] {
    const days: FlexCalendarDay[] = [];
    const currentDate = new ExtendedDate(date);
    currentDate.setDate(1);
    let day = currentDate.getDate();

    while (currentDate.getMonth() === date.getMonth()) {
      currentDate.setDate(day++);

      if (currentDate.getMonth() !== date.getMonth()) {
        break;
      }

      if (!this.dateManagerService.isWorkDay(currentDate.getDay())) {
        continue;
      }

      const flexCalendarDay: FlexCalendarDay = {
        date: new ExtendedDate(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()),
        dateNumber: currentDate.getDate(),
        name: weekDaysShortNames[currentDate.getDay()]
      };

      days.push(flexCalendarDay);
    }

    return days;
  }

  getPrevMonthFlexCalendarDays(flexCalendarDays?: FlexCalendarDay[]): FlexCalendarDay[] | undefined {
    if (!flexCalendarDays || flexCalendarDays.length === 0) {
      return;
    }

    const flexCalendarDaysCloned = [...flexCalendarDays];
    const firstDay = flexCalendarDaysCloned[0].name;
    const shiftSize = weekDaysShortNames.indexOf(firstDay) - 1;

    flexCalendarDaysCloned.unshift(...this.getPrevMonthFlexCalendarDaysBy(flexCalendarDaysCloned, shiftSize));

    return flexCalendarDaysCloned;
  }

  isDateAvailableByFlex(calendarDate: ExtendedDate, flex: Flex): boolean {
    const resultComparingStartDate = this.dateManagerService.compareDateByDays(calendarDate, flex.startDateDate);
    const resultComparingEndDate = this.dateManagerService.compareDateByDays(calendarDate, flex.endDateDate);

    return (
      (resultComparingStartDate === 0 || resultComparingStartDate === 1) &&
      (resultComparingEndDate === 0 || resultComparingEndDate === -1)
    );
  }

  isDateAvailableByRecurrence(calendarDate: ExtendedDate, flex: Flex): boolean {
    if (!flex) {
      return false;
    }

    // Returns true if there is no defined recurrence which means daily
    if (!flex.recurrence || flex.recurrence.length === 0) {
      return true;
    }

    return flex.recurrence.indexOf(calendarDate.getDay()) !== -1;
  }

  isDateAvailableFromTomorrow(calendarDate: ExtendedDate): boolean {
    return (
      this.dateManagerService.compareDateByDays(calendarDate, this.dateManagerService.getDateBySchoolUtcOffset()) === 1
    );
  }

  isDateAvailableFromToday(calendarDate: ExtendedDate): boolean {
    return (
      this.dateManagerService.compareDateByDays(calendarDate, this.dateManagerService.getDateBySchoolUtcOffset()) !== -1
    );
  }

  isDateAvailableBySelectedDate(calendarDate: ExtendedDate, calendarDateSelectedDate: ExtendedDate): boolean {
    return this.dateManagerService.compareDateByDays(calendarDate, calendarDateSelectedDate) === 0;
  }

  isCutOffTimeElapsed(
    cutoffDays: number,
    cutoffTime: number,
    cutoffCountWeekends: boolean,
    calendarDate: ExtendedDate,
    todayUtc = new ExtendedDate(),
    isDST?: boolean
  ): boolean | never {
    const dateBySchoolTimeZone = this.dateManagerService.getDateBySchoolUtcOffset(todayUtc, isDST);
    const cutoffDate = this.dateManagerService.getDateTimeBy(cutoffDays, cutoffTime, cutoffCountWeekends, calendarDate);

    return dateBySchoolTimeZone > cutoffDate;
  }

  isThereOnlyOneLunchAvailableBy(
    systemSettings: SystemSettings,
    calendarDate: ExtendedDate,
    flex: FlexPeriodScheduledActivity
  ): boolean {
    const isLunchMandatoryInSystemSettings = systemSettings.lunchMandatory === true;

    const isDateWithLunchThatNoExistInOtherFlexs =
      flex.datesWithLunchThatNoExistInOtherFlexs.find(
        item => this.dateManagerService.isDatesEqual(item.lunchDate, calendarDate) && item.flexUuid === flex.uuid
      ) !== undefined;

    return isLunchMandatoryInSystemSettings && isDateWithLunchThatNoExistInOtherFlexs;
  }

  getCutOffDate(
    cutoffDays: number,
    cutoffTime: number,
    cutoffCountWeekends: boolean,
    calendarDate: ExtendedDate
  ): Date {
    const dateBySchoolTimeZone = this.dateManagerService.getDateBySchoolUtcOffset();
    const cutoffDate = this.dateManagerService.getDateTimeBy(cutoffDays, cutoffTime, cutoffCountWeekends, calendarDate);

    if (dateBySchoolTimeZone > cutoffDate) {
      cutoffDate.setDate(cutoffDate.getDate() + 1);
    }

    return cutoffDate;
  }

  getBulkDeleteDatesAfter(selectedDate: ExtendedDate, dates: string[]): string[] {
    return dates.filter(item => this.dateManagerService.compareDateByDays(new ExtendedDate(item), selectedDate) !== -1);
  }

  private getPrevMonthFlexCalendarDaysBy(flexCalendarDays: FlexCalendarDay[], shiftSize: number): FlexCalendarDay[] {
    if (shiftSize === 0) {
      return [];
    }

    const prevMonthflexCalendarDays = [];

    const lastWeekDateOfPrevMonth = new ExtendedDate(flexCalendarDays[0].date);
    lastWeekDateOfPrevMonth.setDate(lastWeekDateOfPrevMonth.getDate() - shiftSize);

    const date = new ExtendedDate(lastWeekDateOfPrevMonth);

    for (let index = 0; index < shiftSize; index++) {
      if (date.getMonth() === lastWeekDateOfPrevMonth.getMonth()) {
        prevMonthflexCalendarDays.push({
          visible: false,
          date,
          dateNumber: date.getDate(),
          name: weekDaysShortNames[date.getDay() - 1]
        });
      }
    }

    return prevMonthflexCalendarDays;
  }
}
