import { Injectable } from '@angular/core';
import { DateFilter } from './interfaces/date-filter';
import { SchoolYearService } from '../date-manager/school-year/school-year.service';
import { SchoolYearRegistry } from '../date-manager/school-year/school-year.registry';
import { SchoolYear } from '../date-manager/school-year/school-year.interface';

@Injectable({
  providedIn: 'root'
})
export class DateFilterService {
  selectedDate = this.schoolYearService.getDefaultDateBySchoolYear();

  constructor(
    private schoolYearService: SchoolYearService,
    private schoolYearRegistry: SchoolYearRegistry
  ) {}

  getFilterForPastDays(): DateFilter {
    return { startDate: this.schoolYearRegistry.selectedSchoolYear.startDate, endDate: this.selectedDate };
  }

  getFilterForFutureDays(): DateFilter {
    return { startDate: this.selectedDate, endDate: this.schoolYearRegistry.selectedSchoolYear.endDate };
  }

  getFilterForToday(): DateFilter {
    return { startDate: this.selectedDate, endDate: this.selectedDate };
  }

  getSelectedSchoolYear(): SchoolYear
  {
    return this.schoolYearRegistry.selectedSchoolYear;
  }
}
