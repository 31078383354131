<ng-container *ngIf="form">
  <div [formGroup]="form" class="{{ className }}">
    <label for="building">{{ label | translate }}</label>

    <ng-select
      *ngIf="flexPeriods$ | async as flexPeriods"
      placeholder="{{ 'SELECT_FLEX_PERIODS' | translate }}"
      [items]="flexPeriods"
      [bindLabel]="'name'"
      [bindValue]="'uuid'"
      [formControlName]="controlName"
      [multiple]="true"
    >
    </ng-select>
  </div>
</ng-container>
