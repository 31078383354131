<app-select
  *ngIf="form"
  [form]="form"
  label="{{ 'SCHEDULED' | translate }}"
  [className]="className"
  [controlName]="'scheduled'"
  [items]="[
    { id: 1, name: 'YES' | translate },
    { id: 0, name: 'NO' | translate }
  ]"
>
</app-select>
