import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AppConfig } from 'src/app/configs/app.config';
import { DestroyNotifierComponent } from 'src/app/core/component/destroy/destroy-notifier.component';
import { Paging } from 'src/app/core/services/http/general/classes/paging';
import { Status } from 'src/app/core/services/http/general/enums/status';
import { ObservableWrapperService } from 'src/app/core/services/observable-wrapper/observable-wrapper.service';
import { AutoCompleteItem } from '../../interfaces/autocomplete-item';
import { RosterService } from 'src/app/core/services/http/roster/roster.service';
import { RosterAdapterService } from 'src/app/core/services/http/roster/adapters/roster-adapter.service';
import { Roster } from 'src/app/core/services/http/roster/interfaces/roster';

@Component({
  selector: 'app-auto-complete-list-group-roster',
  templateUrl: './auto-complete-list-group-roster.component.html'
})
export class AutoCompleteListGroupRosterComponent extends DestroyNotifierComponent {
  @Input() inputTitle = 'SELECT_GROUPS';
  @Input() controlName = 'groupsSelect';
  @Input() defaultMessageContent = 'NO_GROUPS_SELECTED';
  @Input() form: UntypedFormGroup | undefined;
  @Input() shouldBeAbleToEditListGroupItem = true;
  @Input() shouldShowSelectedItemsList = true;
  @Input() shouldShowDefaultMessage = true;
  @Input() removeSelectedItem: AutoCompleteItem | undefined;
  @Input() placeholder = 'SEARCH_BY_ROSTER_NAME';
  @Input() customListClass = '';
  @Input() additionalRequestFilters: Record<string, any>[] | undefined;

  @Output() selectChange = new EventEmitter<AutoCompleteItem[]>();
  @Output() itemClicked: EventEmitter<AutoCompleteItem> = new EventEmitter<AutoCompleteItem>();

  rosters: AutoCompleteItem[] = [];
  paging = new Paging({ pageSize: AppConfig.AutoComplete.pageSize });

  constructor(
    private rosterService: RosterService,
    private rosterAdapterService: RosterAdapterService,
    private observableWrapperService: ObservableWrapperService
  ) {
    super();
  }

  onSearchChangeRoster(searchedItem: string): void {
    this.observableWrapperService.unsubscribeWrapper<Roster[]>(
      this.rosterService.getAll(this.paging, { filter: searchedItem, status: Status.Active }),
      this.destroyNotify,
      rosters => {
        this.rosters = this.rosterAdapterService.rosterToAutoCompleteItem(rosters);
      }
    );
  }

  onSelectChangeRoster(items: AutoCompleteItem[]): void {
    this.selectChange.emit(items);
    this.setFormControlValue(items);
  }
  onItemClick(value: AutoCompleteItem) {
    this.itemClicked.emit(value);
  }
  private setFormControlValue(items: AutoCompleteItem[]): void {
    if (!this.form || !this.controlName) {
      return;
    }

    this.form.get(this.controlName)?.setValue(items);
  }
}
