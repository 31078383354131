import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthFactoryService } from '../../auth/auth-factory.service';
import { RestGenericUtilService } from '../rest-generic-util.service';
import { RestGeneric } from '../rest.generic';
import { Building } from './interfaces/building';

@Injectable({
  providedIn: 'root'
})
export class BuildingService extends RestGeneric<any, Building, any> {
  constructor(
    protected http: HttpClient,
    protected authFactoryService: AuthFactoryService,
    protected restGenericUtilService: RestGenericUtilService
  ) {
    super(http, authFactoryService, 'building', restGenericUtilService);
  }
}
