import {
  Directive,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';

@Directive({
  selector: '[appDelayRendering]'
})
export class DelayRenderingDirective implements OnInit {
  private delayTime: number | undefined;

  constructor(
    private template: TemplateRef<any>,
    private container: ViewContainerRef
  ) {}

  @Input()
  set appDelayRendering(delayTime: number) {
    this.delayTime = delayTime;
  }

  ngOnInit() {
    this.container.clear();

    setTimeout(() => {
      this.container.createEmbeddedView(this.template);
    }, this.delayTime);
  }
}
