import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { DestroyNotifierComponent } from 'src/app/core/component/destroy/destroy-notifier.component';
import { ActivityPriorityService } from 'src/app/core/services/http/activity-priority/activity-priority.service';
import { ActivityPriority } from 'src/app/core/services/http/activity-priority/interfaces/activity-priority';
import { Paging } from 'src/app/core/services/http/general/classes/paging';
import { Status } from 'src/app/core/services/http/general/enums/status';

@Component({
  selector: 'app-activity-priority-legend',
  templateUrl: './activity-priority-legend.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActivityPriorityLegendComponent extends DestroyNotifierComponent implements OnInit {
  activityPriorities$: Observable<ActivityPriority[]> | undefined;

  constructor(private activityPriorityService: ActivityPriorityService) {
    super();
  }

  ngOnInit(): void {
    this.activityPriorities$ = this.activityPriorityService.getAll(new Paging().getAllRecords(), {
      status: Status.Active,
      orderBy: 'type'
    });
  }

  collapseLegend(elem: HTMLElement) {
    elem.classList.toggle('collapsed');
  }
}
