import { Component, Input } from '@angular/core';
import { AppConfig } from 'src/app/configs/app.config';

@Component({
  selector: 'app-qrcode-generator',
  templateUrl: './qrcode-generator.component.html'
})
export class QRCodeGeneratorComponent {
  @Input() qrdata: string | undefined;

  appConfig = AppConfig;
}
