<div class="app-table-actions px-2">
    <div class="bulk-actions" #bulkActions>
        <span *ngIf="bulkActions.children.length !== 0" class="bulk-label">{{ 'BULK_ACTIONS' | translate }}: </span>
        <ng-content select="[bulkActions]" ></ng-content>
    </div>
    <div class="additional-actions">
        <ng-content select="[additionalActions]"></ng-content>
    </div>
</div>

