import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { EventType } from 'src/app/constants/events-type';
import { EventContent } from 'src/app/core/services/event-manager/models/event-content';
import { EventManagerService } from '../../../core/services/event-manager/event-manager.service';
import { Alert } from '../../alert/types/alert';
import { AutoCompleteComponent } from '../auto-complete.component';
import { AutoCompleteItem } from '../interfaces/autocomplete-item';

@Component({
  selector: 'app-auto-complete-list-group',
  templateUrl: './auto-complete-list-group.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AutoCompleteListGroupComponent extends AutoCompleteComponent {
  @ViewChild(NgSelectComponent) ngSelectComponent: NgSelectComponent | undefined;

  selectedItems: AutoCompleteItem[] = [];
  @Input() set selectedItemsData(selectedItems: AutoCompleteItem[]) {
    if (!selectedItems) {
      this.selectedItems = [];
      return;
    }

    this.selectedItems = [...selectedItems];
  }
  /**
   * Uses for external binding of selected items,
   * for example from file
   */
  @Input() set externalSelectedItemsData(selectedItems: AutoCompleteItem[]) {
    if (!selectedItems || selectedItems.length === 0) {
      return;
    }

    this.emitSelectChange(selectedItems);
  }

  @Input() set removeSelectedItem(item: AutoCompleteItem | undefined) {
    if (!item) {
      return;
    }

    this.remove(item);
  }

  @Input() placeholder = 'SEARCH';
  @Input() shouldShowSelectedItemsList = false;
  @Input() shouldShowDefaultMessage = false;
  @Input() defaultMessageContent = 'ADD_ITEMS';
  @Input() notAbleToEditListGroupItemLabel = 'NOT_ABLE_TO_EDIT';
  @Input() inputTitle: string | undefined;
  @Input() shouldBeAbleToEditListGroupItem = true;
  @Input() customListClass = '';
  @Input() rosterStudents?:any | undefined;

  @Output() itemClicked: EventEmitter<AutoCompleteItem> = new EventEmitter<AutoCompleteItem>();

  constructor(private eventManagerService: EventManagerService) {
    super();
  }

  onRemove(item: AutoCompleteItem): void {
    this.remove(item);
  }

  onItemClick(item: AutoCompleteItem): void {
    this.itemClicked.emit(item);
  }

  remove(item: AutoCompleteItem): void {
    if (!item) {
      return;
    }

    // Removes item from the list below the autocomplete input
    this.selectedItems = this.selectedItems.filter(selectedItem => selectedItem.value !== item.value);
    this.selectChange.emit(this.selectedItems);
  }

  onChange(item: AutoCompleteItem): void {
    this.emitSelectChange([item]);
    this.ngSelectComponent?.handleClearClick();
  }

  emitSelectChange(selectedItems: AutoCompleteItem[]): void {
    selectedItems.forEach(selectedItem => {
      if (!selectedItem) {
        return;
      }

      if (this.isThereDublicate(selectedItem)) {
        return;
      }

      this.selectedItems.push(selectedItem);
      this.selectChange.emit(this.selectedItems);
    });
  }

  private isThereDublicate(selectedItem: AutoCompleteItem): boolean {
    const targetSelectedItem = this.selectedItems.find(item => item.value === selectedItem.value);
    
    const targetSelectedItemInRoster = this.rosterStudents && this.rosterStudents.find((item:any) => item.uuid === selectedItem.value);

    if(!targetSelectedItem && !targetSelectedItemInRoster){
      return false;
    }

    const alert: Alert = {
      type: 'danger',
      dismissOnTimeout: 0,
      translateKey: 'USER_HAVE_ALREADY_BEEN_ADDED',
      interpolateParams: { value: selectedItem.extraLabels.value }
    };
    this.eventManagerService.broadcast(new EventContent(EventType.AlertMessage, alert));

    return true;
  }
}
