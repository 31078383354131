import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { EventType } from 'src/app/constants/events-type';
import { DestroyNotifierComponent } from 'src/app/core/component/destroy/destroy-notifier.component';
import { EventManagerService } from 'src/app/core/services/event-manager/event-manager.service';
import { EventContent } from 'src/app/core/services/event-manager/models/event-content';
import { clearFormControlsValuesByFormGroup } from 'src/app/core/utils/utils';
import { ConfirmationModal } from './interfaces/confirmation.modal';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent extends DestroyNotifierComponent {
  modal: ConfirmationModal | undefined;

  clearFormControlsValuesByFormGroup = clearFormControlsValuesByFormGroup;
  checkboxChecked: boolean | undefined;
  confirmButtonText = '';

  constructor(protected modalRef: BsModalRef, protected eventManager: EventManagerService) {
    super();
  }

  ngOnInit(){
    this.confirmButtonText = this.modal?.buttonText ?? '';
  }

  onClose(): void {
    this.modalRef.hide();

    if (this.modal?.cancelCallback) {
      this.modal.cancelCallback();
    }
  }

  handleCheckboxChange(): void {
    if(this.modal && this.modal.buttonText){
      this.modal.buttonText = this.checkboxChecked ? this.modal?.secondaryConfirmationButtonText : this.confirmButtonText
      this.modal.buttonClass = this.checkboxChecked ? 'btn-danger' : 'btn-primary'
    }
  }

  onConfirm(): void {
    this.modalRef.hide();

    if (this.modal?.callback) {
      this.modal.callback(this.checkboxChecked);
    }
  }

  onSuccess(): void {
    this.eventManager.broadcast(new EventContent(EventType.PreloaderShow, false));
    this.modalRef.hide();

    if (this.modal?.callback) {
      this.modal.callback();
    }
  }

  onError(): void {
    this.eventManager.broadcast(new EventContent(EventType.PreloaderShow, false));
  }
}
