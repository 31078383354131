import { Directive, ElementRef, Input, OnChanges } from '@angular/core';
import { AppConfig } from 'src/app/configs/app.config';
import { getScheduledStatus } from 'src/app/shared/data-table/utils/utils';

@Directive({
  selector: '[appScheduledClass]'
})
export class ScheduledClassDirective implements OnChanges {
  @Input() scheduled: boolean | undefined;

  readonly statusClasses = [AppConfig.ScheduleStatus.inActive, AppConfig.ScheduleStatus.active];

  constructor(private el: ElementRef) {}

  ngOnChanges(): void {
    const cssClass = getScheduledStatus(this.scheduled ?? false);

    const dOMTokenList = this.el.nativeElement.classList as DOMTokenList;

    // If class that we should to add already exist breaks further execution of the method
    if (dOMTokenList.contains(cssClass)) {
      return;
    }

    this.removeExistingClass(dOMTokenList);

    this.el.nativeElement.classList.add(cssClass);
  }

  private removeExistingClass(dOMTokenList: DOMTokenList): void {
    const isThereStatusClass = dOMTokenList.value.indexOf('status-') !== -1;

    if (!isThereStatusClass) {
      return;
    }

    dOMTokenList.forEach((value: string) => {
      if (this.statusClasses.includes(value)) {
        dOMTokenList.remove(value);
      }
    });
  }
}
