import { ChangeDetectorRef, Component, ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { AppConfig } from 'src/app/configs/app.config';
import { AppRoute } from 'src/app/constants/app.route';
import { Authority } from 'src/app/constants/authority';
import { EventType } from 'src/app/constants/events-type';
import { DestroyNotifierComponent } from 'src/app/core/component/destroy/destroy-notifier.component';
import { AuthFactoryService } from 'src/app/core/services/auth/auth-factory.service';
import { EventManagerService } from 'src/app/core/services/event-manager/event-manager.service';
import { EventContent } from 'src/app/core/services/event-manager/models/event-content';
import { AuthenticatedUserService } from 'src/app/core/services/http/authenticated-user/authenticated-user.service';
import { School } from 'src/app/core/services/http/school/interfaces/school';
import { SchoolService } from 'src/app/core/services/http/school/school.service';
import { User } from 'src/app/core/services/http/user/interfaces/user';
import { MenuItem } from 'src/app/core/services/menu-builder/interfaces/menu-item';
import { MenuBuilderService } from 'src/app/core/services/menu-builder/menu-builder.service';
import { assignLocation, isFeatureEnabled } from 'src/app/core/utils/utils';
import { LiveNotificationService } from '../../core/services/notification/live-notification.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html'
})
export class MenuComponent extends DestroyNotifierComponent implements OnInit {
  user: User | null | undefined;
  appRoute = AppRoute;
  appConfig = AppConfig;
  authority = Authority;
  menuItems: MenuItem[] | undefined;
  subMenuItems: MenuItem[] | undefined;
  shouldShowSubMenu: boolean | undefined;
  school: School | undefined | null;
  shouldShowLoading: boolean | undefined;
  schools: School[] | undefined;
  unreadNotificationCount = 0;

  assingLocation = assignLocation;

  constructor(
    private schoolService: SchoolService,
    private eventManager: EventManagerService,
    private menuBuilderService: MenuBuilderService,
    private authFactoryService: AuthFactoryService,
    private router: Router,
    private el: ElementRef,
    private authenticatedUserService: AuthenticatedUserService,
    private liveNotificationService: LiveNotificationService,
    private cdRef: ChangeDetectorRef
  ) {
    super();
  }

  async ngOnInit(): Promise<void> {
    this.school = this.authFactoryService.service.getSchoolUserAuthority();

    if (!this.school) {
      return;
    }

    this.shouldShowSubMenu = this.menuBuilderService.getShouldShowSubMenuState();
    this.menuItems = await this.menuBuilderService.getMenuByAuthority(this.school.role);
    this.subMenuItems = await this.menuBuilderService.getSubMenuByAuthority(this.school.role);
    this.setIsActiveByCurrentRoute();
    await this.setUser();

    if (this.user && isFeatureEnabled('notifications')) {
      this.unreadNotificationCount = this.liveNotificationService.unreadCount;
      this.liveNotificationService
          .unreadNotificationCountChanged
          .subscribe((unreadCount: number) => {
            this.unreadNotificationCount = unreadCount;
            this.cdRef.detectChanges();
          });
    }
    this.gainsightIdentifyEvent();
  }

  generateGainsightPXScript(aptrinsicKey: string) {
    const scriptTag = document.createElement('script');
    scriptTag.type = 'text/javascript';
    scriptTag.innerHTML = `(function(n,t,a,e,co){var i="aptrinsic";n[i]=n[i]||function(){
    (n[i].q=n[i].q||[]).push(arguments)},n[i].p=e;n[i].c=co;
    var r=t.createElement("script");r.async=!0,r.src=a+"?a="+e;
    var c=t.getElementsByTagName("script")[0];c.parentNode.insertBefore(r,c)
  })(window,document,"https://web-sdk.aptrinsic.com/api/aptrinsic.js",'${aptrinsicKey}');`;

    return scriptTag;
  }
  gainsightIdentifyEvent(): void {
    if (this.authenticatedUserService.isGainsightPxDataSend) {
      return;
    }
    if (!this.user || !this.user?.user || !this.school) {
      return;
    }
    const userData = {
      id: this.user.user.email,
      email: this.user.user.email,
      firstName: this.user.user.firstName,
      lastName: this.user.user.lastName,
      roleFlex: this.user.role.code
    };
    const accountData = {
      id: this.school.uuid,
      name: this.school.name
    };
  
    const script = this.generateGainsightPXScript(environment.aptrinsicApiKey);
    
    document.body.appendChild(script);
    const aptrinsic = (window as any)?.aptrinsic || null;
    
    if (!aptrinsic) {
      return;
    }
   
    aptrinsic('identify', userData, accountData);
    this.authenticatedUserService.isGainsightPxDataSend = true;
  }

  updateActiveItemClass(selectedChildItem: MenuItem): void {
    this.setActiveToInActive(this.menuItems);
    this.setActiveToInActive(this.subMenuItems);

    selectedChildItem.isActive = true;
  }

  onChooseSchool(school: School): void {
    const schoolUserAuthority = this.authFactoryService.service.getSchoolUserAuthority();

    if (schoolUserAuthority?.uuid === school.uuid) {
      return;
    }

    this.authFactoryService.service.setSchoolUserAuthority(school);
    const defaultRoute = AppConfig.DefaultRouteUserRoleMapping[school.role];

    if (defaultRoute) {
      this.navigate(defaultRoute);
    }
  }

  onLogOut(): void {
    this.authFactoryService.service.logOut();
  }

  onOpenSubMenu(): void {
    this.shouldShowSubMenu = true;
    this.menuBuilderService.setShouldShowSubMenuState(true);
  }

  onHideSubMenu(): void {
    this.shouldShowSubMenu = false;
    this.menuBuilderService.setShouldShowSubMenuState(false);
  }

  openMenu(): void {
    this.el.nativeElement.querySelector('#mobileMenu').classList.toggle('open');
    this.el.nativeElement.querySelector('#mainMenu').classList.toggle('show');
  }

  onImpersonate(): void {
    this.eventManager.broadcast(new EventContent(EventType.SimulateShow, null));
  }

  onOpenChange(isOpen: boolean): void {
    if (isOpen) {
      this.schools = undefined;
      this.loadSchools();
    }
  }

  navigateToNotifications() {
    this.router.navigateByUrl(AppRoute.Notifications);
  }

  private loadSchools(): void {
    this.shouldShowLoading = true;

    this.schoolService
      .getAll()
      .pipe(takeUntil(this.destroyNotify))
      .subscribe(schools => {
        this.schools = schools;
        this.shouldShowLoading = false;
      });
  }

  private setIsActiveByCurrentRoute(): void {
    const routerUrl = this.getAppRoute();
    let shouldSkipMenuItems: boolean | undefined;

    const deactivateMenuItems = (menuItems: Array<MenuItem>) => {
      menuItems.forEach((item: MenuItem) => {
        item.isActive = false;

        if (item.hasOwnProperty('items')) {
          item.items?.forEach((childItem: MenuItem) => childItem.isActive = false);
        }
      });
    };  

    if (this.menuItems) {
      deactivateMenuItems(this.menuItems);
    }

    if (this.menuItems  && this.subMenuItems) {
      deactivateMenuItems(this.menuItems);
      deactivateMenuItems(this.subMenuItems);
    }

    // Sub menu child items
    this.subMenuItems?.forEach(item => {
      const newActiveChildItem = item.items?.find(childItem => childItem.routerLink === routerUrl);
      if (newActiveChildItem && !newActiveChildItem.isActive) {
        if (item.isCollapsed) {
          item.isCollapsed = false;
        }

        this.shouldShowSubMenu = true;
        newActiveChildItem.isActive = true;
        shouldSkipMenuItems = true;
      }
    });

    // Skips matching menuitem with route if there is active menuitem in subMenuItems
    if (shouldSkipMenuItems) {
      return;
    }

    this.menuItems?.forEach(item => {
      const newActiveChildItem = item.items?.find(childItem => childItem.routerLink === routerUrl);

      if (newActiveChildItem && !newActiveChildItem.isActive) {
        if (item.isCollapsed) {
          item.isCollapsed = false;
        }
        newActiveChildItem.isActive = true;
      }

      // Deactivates prev selected menu item
      item.isActive = false;

      // Items without parent
      if (item.routerLink === routerUrl) {
        if (!item.isActive) {
          if (item.isCollapsed) {
            item.isCollapsed = false;
          }
          item.isActive = true;
        }
      }
    });
  }

  private getAppRoute(): string {
    let routerUrl = this.router.url.replace('/', '');
    const queryParamSegmentIndex = routerUrl.indexOf('?');

    if (queryParamSegmentIndex !== -1) {
      routerUrl = routerUrl.substring(0, routerUrl.indexOf('?'));
    }

    return routerUrl as AppRoute;
  }

  private async setUser(): Promise<void> {
    this.user = await this.authenticatedUserService.getAuthenticatedUser().toPromise();
  }

  private navigate(appRoute: AppRoute): void {
    this.assingLocation(appRoute);
  }

  private setActiveToInActive(menuItems?: MenuItem[]): void {
    menuItems?.forEach(item => {
      const activeChildItem = item.items?.find(childItem => childItem.isActive === true);

      if (item.isActive) {
        item.isActive = false;
      }

      if (activeChildItem?.isActive) {
        activeChildItem.isActive = false;
      }
    });
  }
}
