import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss']
})
export class FileUploaderComponent {
  @Output() fileChange = new EventEmitter<File>();

  file: File | undefined;
  cssClass = '';

  onFileChange(event: any): void {
   
    if (event.target.files.length > 0) {
      const file = event.target.files[0] as File;
      this.file = file;
      this.cssClass = "fileSelected";

      this.fileChange.emit(file);
    }
  }
}
