import { Directive, ElementRef, Input, OnChanges } from '@angular/core';
import { StudentAttendance } from '../../services/http/student-attendance/interfaces/student-attendance';

@Directive({
  selector: '[appBtnDarkClass]'
})
export class BtnDarkClassDirective implements OnChanges {
  @Input() studentsAttendances: StudentAttendance[] | undefined;

  constructor(private el: ElementRef) {}

  ngOnChanges(): void {
    const isThereStudentAttendanceForSubmitting =
      this.studentsAttendances?.find(item => item.studentAttendanceIsForSubmitting) !== undefined;

    if (isThereStudentAttendanceForSubmitting) {
      this.el.nativeElement.classList.add('round-btn-dark');
    }
  }
}
