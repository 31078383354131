import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AppConfig } from 'src/app/configs/app.config';
import { ClassInputType } from '../types/class-input-type';

@Component({
  selector: 'app-graduation-year-select',
  templateUrl: './graduation-year-select.component.html'
})
export class GraduationYearSelectComponent implements OnInit {
  @Input() isMultiple = false;
  @Input() form: UntypedFormGroup | undefined;
  @Input() className: ClassInputType = 'app-input';
  graduationYearList: [{ id: number; name: string }] | undefined;

  ngOnInit(): void {
    this.setGraduationYearList();
  }

  private setGraduationYearList(): void {
    if (this.graduationYearList) {
      return;
    }

    const date = new Date();
    const currentYear = date.getFullYear();

    for (
      let index = currentYear - AppConfig.GraduationYearMinusLimitInYears;
      index <= currentYear + AppConfig.GraduationYearPlusLimitInYears;
      index++
    ) {
      if (!this.graduationYearList) {
        this.graduationYearList = [{ id: index, name: index.toString() }];
      } else {
        this.graduationYearList.push({ id: index, name: index.toString() });
      }
    }
  }
}
