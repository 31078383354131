import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { BuildingService } from 'src/app/core/services/http/building/building.service';
import { Building } from 'src/app/core/services/http/building/interfaces/building';
import { Paging } from 'src/app/core/services/http/general/classes/paging';
import { Status } from 'src/app/core/services/http/general/enums/status';
import { ClassInputType } from '../types/class-input-type';

@Component({
  selector: 'app-building-select',
  templateUrl: './building-select.component.html'
})
export class BuildingSelectComponent implements OnInit {
  @Input() label = 'BUILDING';
  @Input() form: UntypedFormGroup | undefined;
  @Input() controlName = 'building';
  @Input() className: ClassInputType = 'app-input';
  buildings$: Observable<Building[]> | undefined;

  constructor(private buildingService: BuildingService) {}

  ngOnInit(): void {
    this.buildings$ = this.buildingService.getAll(new Paging().getAllRecords(), { status: Status.Active });
  }
}
