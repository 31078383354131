import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { EventType } from 'src/app/constants/events-type';
import { ExtendedDate } from 'src/app/core/services/date-manager/classes/extended-date';
import { DateManagerService } from 'src/app/core/services/date-manager/date-manager.service';
import { EventManagerService } from 'src/app/core/services/event-manager/event-manager.service';
import { EventContent } from 'src/app/core/services/event-manager/models/event-content';
import { Flex } from 'src/app/core/services/http/flex/interfaces/flex';
import { compareDateByDays, isDatesEqual } from 'src/app/core/utils/utils';
import { ScheduleNavigateObject } from 'src/app/features/schedule/interfaces/schedule-navigate-object';
import { FlexCalendarComponent } from '../flex-calendar.component';
import { FlexCalendarDay } from '../interfaces/flex-calendar-day';
import { FlexViewCalendarService } from '../services/flex-view-calendar-service';

@Component({
  selector: 'app-flex-calendar-vertical',
  templateUrl: './flex-calendar-vertical.component.html',
  styleUrls: ['./flex-calendar-vertical.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlexCalendarVerticalComponent extends FlexCalendarComponent implements OnChanges {
  @Input() scheduleNavigateObject: ScheduleNavigateObject | undefined | null;
  @Input() bulkDates: ExtendedDate[] | undefined;
  @Input() showAddBulkDates: boolean | undefined;
  @Input() flex: Flex | undefined;

  constructor(
    protected flexViewCalendarService: FlexViewCalendarService,
    protected eventManager: EventManagerService,
    protected dateManagerService: DateManagerService
  ) {
    super(flexViewCalendarService, eventManager, dateManagerService);
  }

  ngOnChanges(): void {
    this.setFlexCalendarDays();
    this.setFlexCalendarDaysProperties();
    this.initFlexCalendarDaysSelected();
    this.setFlexCalendarSelectedPropertiesBySelected();
    this.setCalendarDaysSelectedByBulkDates();
    this.broadcastScheduledDateChange();
  }

  onSelectDay(flexDay: FlexCalendarDay): void {
    if (flexDay.availableForSelect) {
      this.updateFlexCalendarDayClass(flexDay.date, !flexDay.selected);
      this.updateFlexCalendarDaysSelected(flexDay.date, flexDay.selected, !flexDay.selected);
      this.broadcastScheduledDateChange();
    }
  }

  setFlexCalendarDays(): void {
    if (!this.selectedCalendarDate) {
      return;
    }

    const flexCalendarDays = this.flexViewCalendarService.getFlexCalendarDays(this.selectedCalendarDate);
    this.flexCalendarDays = this.flexViewCalendarService.getPrevMonthFlexCalendarDays(flexCalendarDays);
  }

  getFlexCalendarDays(): FlexCalendarDay[] {
    return this.flexCalendarDays?.filter(item => item) || [];
  }

  protected setFlexCalendarDaysProperties(): void {
    this.flexCalendarDays?.forEach(flexCalendarDay => {
      this.setAvailableFlexCalendarDayProperties(flexCalendarDay);
      this.setScheduleFlexCalendarDayProperties(flexCalendarDay);
      this.setSelectedFlexCalendarDayProperties(flexCalendarDay);
    });
  }

  protected updateFlexCalendarDaysSelected(
    date: ExtendedDate,
    shouldAddNonExisting = true,
    shouldRemoveExisting = true
  ): void {
    const index = this.flexCalendarDaysSelected.findIndex(item => compareDateByDays(item, date) === 0);

    if (index === -1 && shouldAddNonExisting) {
      this.flexCalendarDaysSelected.push(date);
    } else if (index !== -1 && shouldRemoveExisting) {
      this.flexCalendarDaysSelected.splice(index, 1);
    }
  }

  protected initFlexCalendarDaysSelected(): void {
    if (this.flexCalendarDaysSelected.length === 0) {
      this.flexCalendarDaysSelected = this.scheduledDates ? this.scheduledDates : this.getFlexCalendarDays()
        .filter(flexCalendarDay => flexCalendarDay.selected === true || flexCalendarDay.availableForSelect === false)
        .map(flexCalendarDay => flexCalendarDay.date);
    }
  }

  protected setScheduleFlexCalendarDayProperties(flexCalendarDay: FlexCalendarDay): void {
    if (!flexCalendarDay) {
      return;
    }

    const targetScheduledDate = this.scheduledDates?.find(date => isDatesEqual(date, flexCalendarDay.date));

    if (!targetScheduledDate) {
      return;
    }

    const comparingDateResult = compareDateByDays(targetScheduledDate, this.today);

    if (comparingDateResult === -1) {
      flexCalendarDay.class = 'date-btn-past';
      flexCalendarDay.availableForSelect = false;
    } else {
      flexCalendarDay.class = 'available';
      flexCalendarDay.selected = true;
      flexCalendarDay.availableForSelect = true;
    }
  }

  protected setSelectedFlexCalendarDayProperties(flexCalendarDay: FlexCalendarDay): void {
    if (!flexCalendarDay) {
      return;
    }

    const calendarDate = new ExtendedDate(this.scheduleNavigateObject?.calendarQueryParam.selectedCalendarDate);

    if (!calendarDate) {
      return;
    }

    if (this.flexViewCalendarService.isDateAvailableBySelectedDate(flexCalendarDay.date, calendarDate)) {
      flexCalendarDay.class = 'date-btn-current selected';
      flexCalendarDay.availableForSelect = false;
      flexCalendarDay.selected = true;
    }
  }

  protected setAvailableFlexCalendarDayProperties(flexCalendarDay: FlexCalendarDay) {
    if (!this.flex) {
      return;
    }

    if (
      flexCalendarDay &&
      this.flexViewCalendarService.isDateAvailableByFlex(flexCalendarDay.date, this.flex) &&
      this.flexViewCalendarService.isDateAvailableByRecurrence(flexCalendarDay.date, this.flex)
    ) {

      if (!this.flexViewCalendarService.isDateAvailableFromToday(flexCalendarDay.date)) {
        return;
      }

      if (
      this.flexViewCalendarService.isCutOffTimeElapsed(
        this.flex.cutoffDays,
        this.flex.cutoffTime,
        this.flex.cutoffCountWeekends,
        flexCalendarDay.date
      ) === false) {
        flexCalendarDay.class = 'available';
        flexCalendarDay.availableForSelect = true;
      }
    }
  }

  protected setCalendarDaysSelectedByBulkDates(): void {
    this.bulkDates?.forEach(bulkDate => {
      if (this.isDateEqualToOriginalSelectedCalendarDate(bulkDate)) {
        return;
      }

      this.updateFlexCalendarDaysSelected(bulkDate, this.showAddBulkDates, !this.showAddBulkDates);
      this.updateFlexCalendarDayClass(bulkDate, this.showAddBulkDates);
    });
  }

  private broadcastScheduledDateChange(): void {
    this.eventManager.broadcast(new EventContent(EventType.ScheduledDateChange, this.flexCalendarDaysSelected));
  }
}
