<app-menu></app-menu>

<app-page-header *appHasAnyAuthority="[authority.Admin, authority.Staff, authority.Teacher]">
  <ng-content pageTitle select="[pageTitle]"></ng-content>
  <ng-content headerBtns select="[headerBtns]"></ng-content>
</app-page-header>

<div
  class="main-content-wrapper"
  appClassByAuthority
  [authority]="[authority.Student, authority.Substitute]"
  [className]="'main-content-wrapper-horizontal'"
>
  <app-announcement-broadcast></app-announcement-broadcast>
  <ng-content select="[mainContent]"> </ng-content>
</div>
