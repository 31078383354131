import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivityPriorityType } from 'src/app/core/services/http/activity-priority/enums/activity-priority-type';
import { Status } from 'src/app/core/services/http/general/enums/status';
import { FormValidators } from 'src/app/shared/form-error/form.validators';

export function getFormGroup(): UntypedFormGroup {
  // tslint:disable-next-line deprecation
  return new UntypedFormBuilder().group(
    {
      ...{
        uuid: [],
        title: [, [Validators.required, Validators.minLength(3), Validators.maxLength(255)]],
        description: [, [Validators.minLength(3), Validators.maxLength(10000)]],
        tags: [, [FormValidators.hashTag]],
        activityType: [, Validators.required],
        priority: [, Validators.required],
        defaultRoom: [, [Validators.required]],
        maxAttendees: [
          0,
          [Validators.required, FormValidators.onlyPositiveNumbers, Validators.min(0), Validators.max(10000)]
        ],
        sharedAttendance: [],
        defaultRoster: [],
        status: [Status.Active],
        additionalStudents: [[]],
        excludedStudents: [[]],
        rosterStudents: [[]],
        flex: null,
        applyToMasterActivity: [true],
        applyToScheduledActivities: [true],

        // Uses just to have entire object
        activityPriority: [],
        future: [],
        notifyOnChange: false,
        autoAssign: true
      },
      ...getAdditionalControlsForFiltering()
    },
    {
      validator: validateMaxAttendees
    }
  );
}

export function getAdditionalControlsForFiltering(): Record<string, AbstractControl> {
  return new UntypedFormBuilder().group({
    scheduled: [],
    rosterType: []
  }).controls;
}

export function validateMaxAttendees(form: UntypedFormGroup): ValidationErrors {
  const message = {
    validateMaxAttendees: {
      message: {
        text: '',
        interpolateParams: { maxAttendees: 0, allStudents: 0, rosterStudents: 0, additionalStudents: 0 }
      }
    }
  };

  let result: any = message;

  if (!form.controls.priority.value) {
    result = null;
    return result;
  }

  switch (form.controls.activityPriority.value?.type) {
    // The default value for max attendees should be equal to total number of students from the roster + additional students.
    // This is true for Admin Must Attend, Priority, and Regular Activities
    case ActivityPriorityType.AdminMustAttend: {
      const allStudents =
        form.controls.additionalStudents.value?.length +
        (form.controls.rosterStudents.value?.length - form.controls.excludedStudents.value?.length);

      const maxAttendees = Number(form.controls.maxAttendees.value);

      if (maxAttendees !== allStudents) {
        message.validateMaxAttendees.message.text = 'MAX_ATTENDEES_NOT_MATCH_TO_ROSTER';
        message.validateMaxAttendees.message.interpolateParams.allStudents = allStudents;
        message.validateMaxAttendees.message.interpolateParams.maxAttendees = maxAttendees;
      }
      break;
    }
    case ActivityPriorityType.PriorityRoster: {
      const allStudents =
        form.controls.additionalStudents.value?.length +
        (form.controls.rosterStudents.value?.length - form.controls.excludedStudents.value?.length);

      const maxAttendees = Number(form.controls.maxAttendees.value);

      if (maxAttendees !== allStudents) {
        message.validateMaxAttendees.message.text = 'MAX_ATTENDEES_NOT_MATCH_TO_ROSTER';
        message.validateMaxAttendees.message.interpolateParams.allStudents = allStudents;
        message.validateMaxAttendees.message.interpolateParams.maxAttendees = maxAttendees;
      }
      break;
    }
    case ActivityPriorityType.RegularRoster: {
      const allStudents =
        form.controls.additionalStudents.value?.length +
        (form.controls.rosterStudents.value?.length - form.controls.excludedStudents.value?.length);

      const maxAttendees = Number(form.controls.maxAttendees.value);

      if (maxAttendees !== allStudents) {
        message.validateMaxAttendees.message.text = 'MAX_ATTENDEES_NOT_MATCH_TO_ROSTER';
        message.validateMaxAttendees.message.interpolateParams.allStudents = allStudents;
        message.validateMaxAttendees.message.interpolateParams.maxAttendees = maxAttendees;
      }
      break;
    }

    // For Optional priority activities the user should be able to set the maximum attendees
    // to the number on the roster plus any additional students but not greater than the total number
    case ActivityPriorityType.OptionalRoster: {
      const maxAttendees = Number(form.controls.maxAttendees.value);
      const rosterStudents = form.controls.rosterStudents.value?.length - form.controls.excludedStudents.value?.length;
      const additionalStudents = form.controls.additionalStudents.value?.length;
      const allStudents = rosterStudents + additionalStudents;

      if (Number(form.controls.maxAttendees.value) > allStudents) {
        message.validateMaxAttendees.message.text = 'MAX_ATTENDEES_IS_GREATER_THAN_ROSTER';
        message.validateMaxAttendees.message.interpolateParams.maxAttendees = maxAttendees;
        message.validateMaxAttendees.message.interpolateParams.rosterStudents = rosterStudents;
        message.validateMaxAttendees.message.interpolateParams.additionalStudents = additionalStudents;
      }
      break;
    }
  }

  if (!message.validateMaxAttendees.message.text) {
    result = null;
  }

  return result;
}

export function resetRosterFormControls(form?: UntypedFormGroup): void {
  if (!form) {
    return;
  }

  form.controls.rosterStudents.setValue([]);
  form.controls.additionalStudents.setValue([]);
  form.controls.sharedAttendance.setValue([]);
  form.controls.defaultRoster.setValue(null);
}
