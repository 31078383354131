import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ClassInputType } from '../shared-extensions/types/class-input-type';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html'
})
export class SelectComponent {
  @Input() label: string | undefined;
  @Input() className: ClassInputType = 'app-input';
  @Input() controlName: string | undefined;
  @Input() form: UntypedFormGroup | undefined;
  @Input() items: Record<any, any>[] = [];
  @Input() placeholder: string | undefined;
  @Input() bindLabel = 'name';
  @Input() bindValue = 'id';
  @Input() isMultiple = false;
  @Input() clearable = true;
}
