import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { AppConfig } from 'src/app/configs/app.config';
import { ExtendedDate } from 'src/app/core/services/date-manager/classes/extended-date';
import { DateManagerService } from 'src/app/core/services/date-manager/date-manager.service';
import { compareDateByDays, getRandomString, isDatesEqual } from 'src/app/core/utils/utils';
import { CalendarViewTab } from './enums/calendar-view-tab';
import { SchoolYearRegistry } from '../../core/services/date-manager/school-year/school-year.registry';
import { SchoolYearService } from '../../core/services/date-manager/school-year/school-year.service';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatePickerComponent implements OnChanges {
  dateCached: ExtendedDate | undefined;
  dateValue: ExtendedDate | undefined;
  @Input() get date() {
    return this.dateValue || new ExtendedDate();
  }

  set date(value: ExtendedDate) {
    this.dateValue = value;
    this.emitChange();
  }

  get equalDates(): boolean {
    return compareDateByDays(this.dateValue, this.maxDate) === 0;
  }

  @Input() orderReverse: boolean | undefined;
  @Input() directionReverse: boolean | undefined;
  @Input() activeCalendarViewTab = CalendarViewTab.Day;
  @Input() formats: string[] = AppConfig.CalendarViewTabFormatMapping[CalendarViewTab.Day];
  @Input() maxDate: ExtendedDate | undefined;
  @Input() control: AbstractControl | undefined;

  @Output() dateChange = new EventEmitter<ExtendedDate>();

  // Fixes issue when we have multiple date-picker in the same component
  randomString = getRandomString();
  appConfig = AppConfig;
  shouldBeVisiblePicker: boolean | undefined;
  today: ExtendedDate | undefined;
  bsConfig: Partial<BsDatepickerConfig> = {
    adaptivePosition: true,
    minMode: 'day',
    maxDate: this.schoolYearRegistry.selectedSchoolYear.endDate,
    minDate: this.schoolYearRegistry.selectedSchoolYear.startDate
  };

  constructor(
      protected dateManagerService: DateManagerService,
      protected schoolYearRegistry: SchoolYearRegistry,
      protected schoolYearService: SchoolYearService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.activeCalendarViewTab) {
      return;
    }

    if (AppConfig.CalendarViewTabFormatMapping[this.activeCalendarViewTab]) {
      this.formats = AppConfig.CalendarViewTabFormatMapping[this.activeCalendarViewTab];
    }

    this.updateBsConfig(changes);
    this.setshouldBeVisiblePicker();
  }

  onClickPrev() {
    this.setDateValue(-1);
    this.emitChange();
  }

  onClickNext(): void {
    this.setDateValue(1);
    this.emitChange();
  }

  onDateChange(date: any) {
    this.dateValue = date;
    this.emitChange();
  }

  onClickToday(): void {
    this.setDateToToday();
    this.emitChange();
  }

  isToday(): boolean {
    if (!this.dateValue) {
      return false;
    }

    const today = this.dateManagerService.getDateBySchoolUtcOffset();

    return compareDateByDays(today, this.dateValue) === 0;
  }

  isWorkDay(): boolean {
    return this.dateManagerService.isWorkDay(this.dateManagerService.getDateBySchoolUtcOffset().getDay());
  }

  protected setshouldBeVisiblePicker(): void {
    this.shouldBeVisiblePicker = this.activeCalendarViewTab !== CalendarViewTab.TodayPlus;
  }

  protected updateBsConfig(changes?: SimpleChanges): void {
    if (this.activeCalendarViewTab === CalendarViewTab.Month) {
      this.bsConfig.minMode = 'month';
    }

    if (changes?.hasOwnProperty('maxDate')) {
      this.bsConfig.maxDate = changes?.maxDate?.currentValue;
    }

    if (changes?.hasOwnProperty('minDate')) {
      this.bsConfig.minDate = changes?.minDate?.currentValue;
    }

    if (!AppConfig.DateTimePickers.countWeekends) {
      this.bsConfig.daysDisabled = [6, 0];
    }
  }

  private setDateToToday(): void {
    this.dateValue = this.schoolYearService.getDefaultDateBySchoolYear();
    this.today = this.schoolYearService.getDefaultDateBySchoolYear();
  }

  private emitChange(): void {
    if (!this.dateValue) {
      return;
    }

    if (!this.dateCached || !isDatesEqual(this.dateCached, this.dateValue)) {
      this.dateChange.emit(this.dateValue);
      this.dateCached = this.dateValue;
      this.setControlValue();
    }
  }

  private setControlValue(): void {
    if (this.control) {
      this.control.setValue(this.dateValue);
    }
  }

  private setDateValue(shift: 1 | -1): void {
    if (!this.dateValue) {
      return;
    }

    let dateValue = new ExtendedDate(
      this.dateValue.getFullYear(),
      this.dateValue.getMonth(),
      this.dateValue.getDate()
    );

    if (this.activeCalendarViewTab === CalendarViewTab.Day) {
      dateValue.setDate(dateValue.getDate() + shift);
    } else if (this.activeCalendarViewTab === CalendarViewTab.Week) {
      dateValue.setDate(dateValue.getDate() + CalendarViewTab.Week * shift);
    } else if (this.activeCalendarViewTab === CalendarViewTab.Month) {
      dateValue = this.calculateLastWorkingDay(dateValue, shift);
    }

    const selectedSchoolYear = this.schoolYearRegistry.selectedSchoolYear;

    if (selectedSchoolYear && !this.schoolYearService.isDuringSchoolYear(selectedSchoolYear, dateValue)) {
      return;
    }

    this.dateValue = dateValue;

    if (!AppConfig.DateTimePickers.countWeekends) {
      this.dateValue = this.dateManagerService.getDateByCountWeekends(dateValue, shift);
    }
  }

  private calculateLastWorkingDay(date: ExtendedDate, shift: 1 | -1 = 1): ExtendedDate {
    const month = date.getMonth() + shift;

    const dateValue = new ExtendedDate(
      date.getFullYear(),
      month + 1,
      0
    );
    while (!this.dateManagerService.isWorkDay(dateValue.getDay())) {
      dateValue.setDate(dateValue.getDate() - 1);
    }

    return dateValue;
  }
}
