<div [formGroup]="form" class="container-fluid">
  <div class="row">
    <div class="search-input search-resp">
      <i class="icon ftmi-search"></i>
      <input
        formControlName="filter"
        type="text"
        class="form-control"
        placeholder="Search"
        aria-label="Search"
        aria-describedby="searchBy"
      />
    </div>

    <ng-container *ngIf="shouldBeVisibleRangeFilter">
      <div class="form-group mb-3 col-md-6 col-lg-3">
        <div class="app-input-inline datepicker-unit">
          <label for="name">{{ 'START_DATE' | translate }}</label>
          <input
            type="text"
            class="form-control"
            placeholder="{{ appConfig.Formats.DateShortFormat | uppercase }}"
            [maxDate]="form.controls.endDate.value"
            bsDatepicker
            formControlName="startDate"
            [bsConfig]="appConfig.BsConfig"
          />
        </div>
        <app-form-error [control]="form.controls.startDate"></app-form-error>
      </div>
      <div class="form-group mb-3 col-md-6 col-lg-3">
        <div class="app-input-inline datepicker-unit">
          <label for="name">{{ 'END_DATE' | translate }}</label>
          <input
            type="text"
            class="form-control"
            [minDate]="form.controls.startDate.value"
            placeholder="{{ appConfig.Formats.DateShortFormat | uppercase }}"
            bsDatepicker
            formControlName="endDate"
            [bsConfig]="appConfig.BsConfig"
          />
        </div>
        <app-form-error [control]="form.controls.endDate"></app-form-error>
      </div>
    </ng-container>

    <ng-content> </ng-content>

    <div class="form-group mb-3 col-md-6 col-lg-3" *ngIf="shouldBeVisibleStatus">
      <app-status-select [className]="'app-input-inline'" [form]="form"> </app-status-select>
    </div>
  </div>
</div>
