<div class="priorities-legend" #toggleLegend *ngIf="activityPriorities$">
  <button type="button" class="btn btn-text legend-label" (click)="collapseLegend(toggleLegend)" [appTooltip]="'PROIORITY_LEGEND' | translate">
    <i class="icon ftmi-arrow-right mr-2"></i>{{ 'PROIORITY_LEGEND' | translate }}
  </button>
  <div id="myDiv" class="legend-list-wrapper">
    <div class="legend-list">
      <span
        appPriorityClass
        [type]="activityType.type"
        title="{{ activityType.title }}"
        class="priority-badge ml-1"
        *ngFor="let activityType of activityPriorities$ | async"
        >{{ activityType.title }}</span
      >
    </div>
  </div>
</div>
