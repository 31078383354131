import { Directive, ElementRef, Input, OnChanges } from '@angular/core';
import { ExtendedDate } from '../../services/date-manager/classes/extended-date';
import { DateManagerService } from '../../services/date-manager/date-manager.service';

@Directive({
  selector: '[appStatusClass]'
})
export class StatusClassDirective implements OnChanges {
  @Input() startDate: string | Date | undefined;
  @Input() endDate: string | Date | undefined;

  constructor(private el: ElementRef, private dateManagerService: DateManagerService) {}

  ngOnChanges(): void {
    if (!this.startDate || !this.endDate) {
      return;
    }

    const today = this.dateManagerService.getDateBySchoolUtcOffset();
    const startDate = this.dateManagerService.getDateBySchoolUtcOffset(new ExtendedDate(this.startDate));
    const endDate = this.dateManagerService.getDateBySchoolUtcOffset(new ExtendedDate(this.endDate));

    if (
      this.dateManagerService.compareDateByDays(today, startDate) !== -1 &&
      this.dateManagerService.compareDateByDays(today, endDate) !== 1
    ) {
      this.setStatusActive();
    } else {
      this.setStatusInActive();
    }
  }

  private setStatusActive(): void {
    if (this.el.nativeElement.classList.contains('status-inactive')) {
      this.el.nativeElement.classList.remove('status-inactive');
    }

    this.el.nativeElement.classList.add('status-active');
  }

  private setStatusInActive(): void {
    if (this.el.nativeElement.classList.contains('status-active')) {
      this.el.nativeElement.classList.remove('status-active');
    }

    this.el.nativeElement.classList.add('status-inactive');
  }
}
