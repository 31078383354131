import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AppConfig } from 'src/app/configs/app.config';
import { Authority } from 'src/app/constants/authority';
import { DestroyNotifierComponent } from 'src/app/core/component/destroy/destroy-notifier.component';
import { Paging } from 'src/app/core/services/http/general/classes/paging';
import { Status } from 'src/app/core/services/http/general/enums/status';
import { UserAdapterService } from 'src/app/core/services/http/user/adapters/user-adapter.service';
import { User } from 'src/app/core/services/http/user/interfaces/user';
import { UserService } from 'src/app/core/services/http/user/user.service';
import { ObservableWrapperService } from 'src/app/core/services/observable-wrapper/observable-wrapper.service';
import { AutoCompleteItem } from '../../interfaces/autocomplete-item';

@Component({
  selector: 'app-auto-complete-list-group-student',
  templateUrl: './auto-complete-list-group-student.component.html'
})
export class AutoCompleteListGroupStudentComponent extends DestroyNotifierComponent {
  @Input() inputTitle = 'ADD_STUDENT';
  @Input() controlName = 'additionalStudents';
  @Input() defaultMessageContent = 'ADD_STUDENTS_TO_ACTIVITY';
  @Input() form: UntypedFormGroup | undefined;
  @Input() shouldBeAbleToEditListGroupItem = true;
  @Input() shouldShowSelectedItemsList = true;
  @Input() shouldShowDefaultMessage = true;
  @Input() removeSelectedItem: AutoCompleteItem | undefined;
  @Input() placeholder = 'SEARCH_BY_NAME_EMAIL';
  @Input() additionalRequestFilters: Record<string, any>[] | undefined;
  @Output() selectChange = new EventEmitter<AutoCompleteItem[]>();

  students: AutoCompleteItem[] = [];
  paging = new Paging({ pageSize: AppConfig.AutoComplete.pageSize });

  constructor(
    private userService: UserService,
    private userAdapterService: UserAdapterService,
    private observableWrapperService: ObservableWrapperService
  ) {
    super();
  }

  onSearchChangeStudent(filter: string): void {
    let requestFilters: Record<string, any> = { filter, role: Authority.Student, status: Status.Active };
    if (this.additionalRequestFilters) {
      this.additionalRequestFilters.forEach((filterItem: Record<string, any>) => {
        requestFilters = { ...requestFilters, ...filterItem };
      });
    }

    this.observableWrapperService.unsubscribeWrapper<User[]>(
      this.userService.getAll(this.paging, requestFilters),
      this.destroyNotify,
      students => {
        this.students = this.userAdapterService.userToAutoCompleteItem(students);
      }
    );
  }

  onSelectChangeStudent(items: AutoCompleteItem[]): void {
    this.selectChange.emit(items);
    this.setFormControlValue(items);
  }

  private setFormControlValue(items: AutoCompleteItem[]): void {
    if (!this.form || !this.controlName) {
      return;
    }

    this.form.get(this.controlName)?.setValue(items);
  }
}
