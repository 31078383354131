<app-select
  [form]="form"
  [className]="className"
  label="{{ 'DEFAULT_SUBSTITUTE_PLAN' | translate }}"
  [controlName]="'defaultSubstitutePlan'"
  [items]="[
    { id: substitutePlan.RunWithSubStitute, name: 'RUN_WITH_SUBSTITUTE' | translate },
    { id: substitutePlan.CancelWithSubstitute, name: 'CANCEL_WITH_SUBSTITUTE' | translate }
  ]"
>
</app-select>
