import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { EventManagerService } from 'src/app/core/services/event-manager/event-manager.service';
import { ConfirmationModalComponent } from '../confirmation-modal.component';
import { ConfirmationModal } from '../interfaces/confirmation.modal';

@Component({
  selector: 'app-confirm-modal-template',
  templateUrl: './confirm-modal-template.component.html',
  styleUrls: ['./confirm-modal-template.component.scss']
})
export class ConfirmModalTemplateComponent extends ConfirmationModalComponent {
  @Input() form: UntypedFormGroup | undefined;
  @Input() modal: ConfirmationModal | undefined;
  @Input() shouldShowCancelButton = true;
  @Input() shouldShowConfirmButton = true;
  @Input() shouldShowCustomButton = false;
  @Input() modalBodyPaddingY = true;
  @Input() headerIcon: boolean|string = false;

  constructor(protected modalRef: BsModalRef, protected eventManager: EventManagerService) {
    super(modalRef, eventManager);
  }
}
