<div class="flex-calendar vertical">
  <div class="week-header">
    <div class="week-day">{{ 'MON' | translate }}</div>
    <div class="week-day">{{ 'TUE' | translate }}</div>
    <div class="week-day">{{ 'WED' | translate }}</div>
    <div class="week-day">{{ 'THU' | translate }}</div>
    <div class="week-day">{{ 'FRI' | translate }}</div>
  </div>
  <div class="week-body">
    <div *ngFor="let flexDay of flexCalendarDays; trackBy: trackBy" class="flex-day">
      <div
        *ngIf="flexDay?.visible !== false"
        (click)="onSelectDay(flexDay)"
        [ngClass]="flexDay?.class || ''"
        class="round-btn date-btn"
      >
        {{ flexDay?.dateNumber }}
      </div>
    </div>
  </div>
</div>
