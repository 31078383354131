import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AutoCompleteItem } from 'src/app/shared/auto-complete/interfaces/autocomplete-item';
import { FileAdapterService } from '../../file/adapters/file-adapter.service';
import { UserAdapterService } from '../../user/adapters/user-adapter.service';
import { RosterUploadFileType } from '../enums/roster-upload-file-types';
import { Roster } from '../interfaces/roster';
import { RosterRequest } from '../interfaces/roster-request';
import { AuthFactoryService } from '../../../auth/auth-factory.service';
import { Authority } from '../../../../../constants/authority';
import { RosterType } from '../enums/roster-types';

@Injectable({
  providedIn: 'root'
})
export class RosterAdapterService {
  constructor(
    private userAdapterService: UserAdapterService,
    private fileAdapterService: FileAdapterService,
    private authFactoryService: AuthFactoryService,
  ) {}

  rosterToAutoCompleteItem(rosters: Roster[]): AutoCompleteItem[] {
    return rosters?.map(item => {
      return {
        value: item.uuid,
        label: item.name,
        extraLabels: {
          firstLabel: item.name,
          value: item.uuid,
          metaDataCountIcon: 'ftmi-group icon',
          metaDataCount: item.membershipCount
        },
        extraValue: item.membershipCollection
      } as AutoCompleteItem;
    });
  }

  rosterToFormGroupData(roster: Roster, form: UntypedFormGroup): void {
    form.patchValue({
      type: roster.type,
      name: roster.name,
      description: roster.description,
      membershipCollection: roster.membershipCollection?.map(student => student.uuid),
      adultCollection: roster.adultCollection?.map(adult => adult.uuid),
      status: roster.status,
      source: roster.source
    });
    const isAdmin = this.authFactoryService.service.hasAnyAuthority([Authority.Admin]);

    if (roster.membershipCollection?.length > 0) {
      const autoCompleteItems = this.userAdapterService.userToAutoCompleteItem(roster.membershipCollection);
      form.controls.items.setValue(autoCompleteItems);
    }

    if (roster.type === RosterType.Shared) {
      const autoCompleteItems = this.userAdapterService.userToAutoCompleteItem(roster.adultCollection);
      form.controls.adults.setValue(autoCompleteItems);
      form.controls.type.setValue(RosterType.Shared);
    } else {
      form.controls.adults.clearValidators();
      form.controls.adults.disable();
    }

    if (roster.file && roster.source === RosterUploadFileType.Automatic && isAdmin) {
      const autoCompleteItem = this.fileAdapterService.uploadFileToAutoCompleteItem([roster.file]);

      if (autoCompleteItem?.length === 1) {
        form.controls.file.setValue(autoCompleteItem[0]);
      }
    } else if (roster.source === RosterUploadFileType.GroupOfGroups && roster.sourceRosters) {
      form.controls.groupSelect.setValue(this.rosterToAutoCompleteItem(roster.sourceRosters));
      form.controls.source.setValue(RosterUploadFileType.GroupOfGroups);
    }
  }

  rosterToRosterRequest(roster?: Roster | Roster): RosterRequest | undefined {
    if (!roster) {
      return;
    }

    return {
      name: roster.name,
      description: roster.description ?? '',
      type: roster.type,
      membershipCollection: roster.membershipCollection?.map(student => student.uuid),
      adultCollection: roster.adultCollection?.map(adult => adult.uuid),
      sourceRosters: roster.sourceRosters?.map(sourceRoster => sourceRoster.uuid),
      status: roster.status,
      source: roster.source
    };
  }

  formGroupToRosterRequest(form: UntypedFormGroup): RosterRequest | undefined {
    if (!form) {
      return;
    }

    const rosterRequest: RosterRequest = {
      name: form.controls.name.value,
      description: form.controls.description.value,
      type: form.controls.type.value,
      status: form.controls.status.value,
      source: form.controls.source.value
    };

    if (form.controls.source.value === RosterUploadFileType.Manual) {
      rosterRequest.membershipCollection = form.controls.items.value?.map((item: AutoCompleteItem) => item.value);
    } else if (form.controls.source.value === RosterUploadFileType.Automatic && form.controls.file?.value) {
      const file: AutoCompleteItem = form.controls.file.value;
      rosterRequest.file = form.controls.file?.value && typeof form.controls.file.value === 'string' ? form.controls.file.value : file.value;
      rosterRequest.membershipCollection = form.controls.items.value?.map((item: AutoCompleteItem) => item.value);
    } else if (form.controls.source.value === RosterUploadFileType.GroupOfGroups) {
      rosterRequest.sourceRosters = form.controls.groupSelect.value?.map((item: AutoCompleteItem) => item.value);
    }

    if (form.controls.type.value === RosterType.Shared) {
      rosterRequest.adultCollection = form.controls.adults.value?.map((item: AutoCompleteItem) => item.value);
    }

    return rosterRequest;
  }
}
