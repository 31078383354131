<app-select
  *ngIf="graduationYearList"
  [form]="form"
  label="{{ 'GRADUATION_YEAR' | translate }}"
  [className]="className"
  placeholder="{{ 'CHOOSE_YEAR' | translate }}"
  controlName="graduationYear"
  [items]="graduationYearList"
  [isMultiple]="isMultiple"
  [clearable]="form?.controls?.graduationYear?.value?.length < 2"
>
</app-select>
