import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { SchoolService } from 'src/app/core/services/http/school/school.service';
import { School } from 'src/app/core/services/http/school/interfaces/school';
import { ClassInputType } from '../types/class-input-type';
import {EventManagerService} from "../../../core/services/event-manager/event-manager.service";
import {EventType} from "../../../constants/events-type";

@Component({
  selector: 'app-school-select',
  templateUrl: './school-select.component.html'
})
export class SchoolSelectComponent implements OnInit {
  @Input() label = 'SCHOOL';
  @Input() form: UntypedFormGroup | undefined;
  @Input() controlName = 'school';
  @Input() className: ClassInputType = 'app-input';
  @Input() isProfileSettings = false;
  schools: School[] | undefined;

  constructor(
    private schoolService: SchoolService,
    private eventManager: EventManagerService
  ) {}

  ngOnInit(): void {
    this.schoolService.getAll().subscribe(schools => {
      const selected = schools.find(i => i?.membership?.isDefault === 1);
      this.form?.get(this.controlName)?.setValue(selected?.membership.uuid);
      this.schools = schools;
    });
  }

  changeDefaultSchool() {
    if (this.isProfileSettings) {
      this.eventManager.broadcast(EventType.DefaultSchoolChange);
    }
  }
}
