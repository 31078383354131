import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AppConfig } from 'src/app/configs/app.config';
import { Authority } from 'src/app/constants/authority';
import { DestroyNotifierComponent } from 'src/app/core/component/destroy/destroy-notifier.component';
import { Paging } from 'src/app/core/services/http/general/classes/paging';
import { Status } from 'src/app/core/services/http/general/enums/status';
import { UserAdapterService } from 'src/app/core/services/http/user/adapters/user-adapter.service';
import { User } from 'src/app/core/services/http/user/interfaces/user';
import { UserService } from 'src/app/core/services/http/user/user.service';
import { ObservableWrapperService } from 'src/app/core/services/observable-wrapper/observable-wrapper.service';
import { ClassInputType } from 'src/app/shared/shared-extensions/types/class-input-type';
import { AutoCompleteItem } from '../../interfaces/autocomplete-item';

@Component({
  selector: 'app-auto-complete-user',
  templateUrl: './auto-complete-user.component.html'
})
export class AutoCompleteUserComponent extends DestroyNotifierComponent {
  @Input() className: ClassInputType = 'app-input';
  @Input() controlName = 'student';
  @Input() defaultMessageContent = 'ADD_STUDENT';
  @Input() authority = Authority.Student;
  @Input() multiple = false;
  @Input() form: UntypedFormGroup | undefined;

  @Output() selectChange = new EventEmitter<AutoCompleteItem>();
  @Output() selectItemsChange = new EventEmitter<AutoCompleteItem[]>();

  students: AutoCompleteItem[] = [];
  paging = new Paging({ pageSize: AppConfig.AutoComplete.pageSize });

  constructor(
    private userService: UserService,
    private userAdapterService: UserAdapterService,
    private observableWrapperService: ObservableWrapperService
  ) {
    super();
  }

  onSelectChangeUser(item: AutoCompleteItem): void {
    this.selectChange.emit(item);
    this.setFormControlValue(item);
  }

  onRemoveItemChange(items: AutoCompleteItem[]) {
    this.selectItemsChange.emit(items);
    this.setFormControlValue(items);
  }

  onSearchChangeStudent(filter: string): void {
    this.observableWrapperService.unsubscribeWrapper<User[]>(
      this.userService.getAll(this.paging, { filter, role: this.authority, status: Status.Active }),
      this.destroyNotify,
      teachers => {
        this.students = this.userAdapterService.userToAutoCompleteItem(teachers);
      }
    );
  }

  private setFormControlValue(item: AutoCompleteItem | AutoCompleteItem[]): void {
    if (!this.form || !this.controlName) {
      return;
    }

    this.form?.get(this.controlName)?.setValue(item);
  }
}
