import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { DestroyNotifierComponent } from 'src/app/core/component/destroy/destroy-notifier.component';
import { FlexService } from 'src/app/core/services/http/flex/flex.service';
import { Flex } from 'src/app/core/services/http/flex/interfaces/flex';
import { Paging } from 'src/app/core/services/http/general/classes/paging';
import { Status } from 'src/app/core/services/http/general/enums/status';
import { ClassInputType } from '../types/class-input-type';

@Component({
  selector: 'app-flex-period-select',
  templateUrl: './flex-period-select.component.html'
})
export class FlexPeriodSelectComponent extends DestroyNotifierComponent implements OnInit {
  @Input() label = 'FLEX_PERIODS';
  @Input() form: UntypedFormGroup | undefined;
  @Input() controlName = 'flexPeriods';
  @Input() className: ClassInputType = 'app-input';
  flexPeriods$: Observable<Flex[]> | undefined;

  constructor(private flexPeriodService: FlexService) {
    super();
  }

  ngOnInit(): void {
    this.flexPeriods$ = this.flexPeriodService.getAll(new Paging().getAllRecords(), { status: Status.Active });
  }
}
