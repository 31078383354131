import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { EventType } from 'src/app/constants/events-type';
import { EventManagerService } from 'src/app/core/services/event-manager/event-manager.service';
import { EventContent } from 'src/app/core/services/event-manager/models/event-content';
import { Authority } from '../../constants/authority';

@Component({
  selector: 'app-export-button',
  templateUrl: './export-button.component.html'
})
export class ExportButtonComponent implements OnInit, OnDestroy {
  @Input() rowsCount: undefined | number;

  @Output() clicked: EventEmitter<void> = new EventEmitter<void>();

  exportStarted: boolean | undefined;
  authority = [Authority.Admin, Authority.Teacher, Authority.Staff, Authority.Substitute];

  private subscription: Subscription | undefined;

  constructor(private eventManager: EventManagerService) {}

  ngOnInit(): void {
    this.subscription = this.eventManager.subscribe(EventType.ExportStart, (eventContent: EventContent<boolean>) => {
      this.exportStarted = eventContent.content;
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.eventManager.destroy(this.subscription);
    }
  }
}
