<ng-select
  [clearable]="true"
  [items]="items"
  [(ngModel)]="selectedItem"
  [compareWith]="compareWith"
  [notFoundText]="'NO_ITEMS_FOUND' | translate"
  [bindLabel]="'label'"
  [bindValue]="'value'"
  [loading]="isLoading"
  [placeholder]="'SEARCH' | translate"
  [appendTo]="'body'"
  (change)="onChange($event)"
  (open)="onOpen()"
  (search)="onSearch($event)"
  (clear)="onClear()"
>
  <ng-template ng-option-tmp let-item="item" let-index="index">
    {{ item.extraLabels.firstLabel }}
    {{ item.extraLabels.secondLabel }}<br />
    <small>{{ item.extraLabels.thirdLabel ?? item.extraLabels.fourthLabel }}</small>
  </ng-template>
</ng-select>
