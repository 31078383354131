import { Directive, ElementRef, Input, OnChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Directive({
  selector: '[appDisableClearButton]'
})
export class DisableClearButtonDirective implements OnChanges {
  @Input() formReference: UntypedFormGroup | undefined;
  @Input() formChange: { change: true } | undefined;

  constructor(private el: ElementRef) {}

  ngOnChanges(): void {
    this.checkShouldBeDisabledClearButton();
  }

  checkShouldBeDisabledClearButton(): void {
    if (this.shouldBeDisabledClearButton()) {
      this.el.nativeElement.setAttribute('disabled', true);
    } else {
      this.el.nativeElement.removeAttribute('disabled');
    }
  }

  private shouldBeDisabledClearButton(): boolean {
    if (!this.formReference?.dirty) {
      return true;
    }

    for (const controlName in this.formReference?.controls) {
      if (Object.prototype.hasOwnProperty.call(this.formReference?.controls, controlName)) {
        const value = this.formReference?.controls[controlName].value;
        if (value !== null && value !== undefined) {
          return false;
        }
      }
    }

    return true;
  }
}
