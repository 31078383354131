import { AutoCompleteItem } from 'src/app/shared/auto-complete/interfaces/autocomplete-item';
import { Injectable } from '@angular/core';
import { UploadFile } from '../../general/interfaces/upload-file';
import { UntypedFormGroup } from '@angular/forms';
import { FileRequest } from '../interfaces/file-request';
import { File } from '../interfaces/file';

@Injectable({
  providedIn: 'root'
})
export class FileAdapterService {
  formGroupToFileRequest(form: UntypedFormGroup): FileRequest | undefined {
    if (!form) {
      return;
    }

    return {
      title: form.controls.title.value
    };
  }

  fileToFormGroupData(file: File, form: UntypedFormGroup): void {
    if (!form) {
      return;
    }

    form.patchValue({
      title: file.title
    });
  }

  uploadFileToAutoCompleteItem(items: UploadFile[]): AutoCompleteItem[] {
    return items?.map(item => {
      return {
        value: item.uuid,
        label: `${item.title} ${item.file}`,
        extraLabels: {
          firstLabel: item.file
        }
      } as AutoCompleteItem;
    });
  }
}
