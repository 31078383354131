import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { Authority } from 'src/app/constants/authority';
import { AuthFactoryService } from '../../services/auth/auth-factory.service';

@Directive({
  selector: '[appClassByAuthority]'
})
export class ClassByAuthorityDirective implements OnInit {
  @Input() authority: Authority[] = [];
  @Input() className = '';

  constructor(
    private authFactoryService: AuthFactoryService,
    private renderer: Renderer2,
    private hostElement: ElementRef
  ) {}

  ngOnInit(): void {
    const hasAnyAuthority = this.authFactoryService.service.hasAnyAuthority(this.authority);

    if (hasAnyAuthority) {
      this.renderer.addClass(this.hostElement.nativeElement, this.className);
    }
  }
}
