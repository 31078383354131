<div class="btn-group" *ngIf="schoolYearRegistry.selectedSchoolYear" dropdown>
    <span id="school-year-dropdown" dropdownToggle class="btn btn-school-year dropdown-toggle"
       aria-controls="school-year-dropdown">
        <span class="selected-school-year">School year: {{ schoolYearRegistry.selectedSchoolYear.schoolYear }} - {{schoolYearRegistry.selectedSchoolYear.schoolYear + 1}}</span>
    </span>
    <ul id="dropdown-school-year" *dropdownMenu class="dropdown-menu" role="menu"
        aria-labelledby="school-year-dropdown">
        <li *ngFor="let schoolYear of schoolYearRegistry.availableYears" role="menuitem">
            <button (click)="onChooseSchoolYear(schoolYear)" class="dropdown-item">{{ schoolYear.schoolYear }}
                - {{schoolYear.schoolYear + 1}}</button>
        </li>
    </ul>
</div>
