import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthFactoryService } from '../../auth/auth-factory.service';
import { RestGenericFileBulkOperations } from '../rest-generic-file-bulk-operations';
import { RestGenericUtilService } from '../rest-generic-util.service';
import { RestGeneric } from '../rest.generic';
import { Room } from './interfaces/room';
import { RoomRequest } from './interfaces/room-request';
import { RoomFromFile } from './interfaces/room-from-file';

@Injectable({
  providedIn: 'root'
})
export class RoomService extends RestGeneric<any, Room, any> implements RestGenericFileBulkOperations<Room> {
  constructor(
    protected http: HttpClient,
    protected authFactoryService: AuthFactoryService,
    protected restGenericUtilService: RestGenericUtilService
  ) {
    super(http, authFactoryService, 'room', restGenericUtilService);
  }

  getById(uuid: string): Observable<Room> {
    return this.http.get<Room>(`${this.baseApiUrl}/building/${this.endpointName}/${uuid}`);
  }

  postBulkDelete(uuids: string[]): Observable<Room> {
    return this.http.post<Room>(`${this.baseApiUrl}/${this.schoolUUid}/building/room/batch`, uuids);
  }

  delete(uuid: string): Observable<Room> {
    return this.http.delete<Room>(`${this.baseApiUrl}/building/${this.endpointName}/${uuid}`);
  }

  postRoom(updateType: RoomRequest, buildingUUid: string): Observable<Room> {
    return this.http.post<Room>(`${this.baseApiUrl}/building/${buildingUUid}/room`, updateType);
  }

  put(updateType: RoomRequest, uuid: string): Observable<Room> {
    return this.http.put<Room>(`${this.baseApiUrl}/building/${this.endpointName}/${uuid}`, updateType);
  }

  // @ts-ignore
  getListByFileHash(fileContentAsSHA256: string): Observable<RoomFromFile> {
    return this.http.get<RoomFromFile>(`${this.baseApiUrl}/${this.schoolUUid}/room-list/csv/${fileContentAsSHA256}`);
  }

  // @ts-ignore
  createListFromFileBase64(fileContentAsBase64: string): Observable<RoomFromFile> {
    return this.http.post<RoomFromFile>(`${this.baseApiUrl}/${this.schoolUUid}/room-list/csv`, {
      contents: fileContentAsBase64
    });
  }
}
