import { Directive, ElementRef, OnInit } from '@angular/core';

@Directive({
  selector: '[appAutocompleteOff]'
})
export class AutocompleteOffDirective implements OnInit {
  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.el.nativeElement.setAttribute('autocomplete', Math.random().toString(20).substr(2, 5));
    });
  }
}
