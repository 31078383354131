<div *ngIf="shouldBeAbleToEditListGroupItem; else notAbleToEditTemplate" class="app-input">
  <label *ngIf="inputTitle">
    {{ inputTitle }}
  </label>
  <ng-select
    [clearable]="true"
    [items]="items"
    [(ngModel)]="selectedItem"
    [compareWith]="compareWith"
    [notFoundText]="'NO_ITEMS_FOUND' | translate"
    [bindLabel]="'label'"
    [bindValue]="'value'"
    [loading]="isLoading"
    [placeholder]="placeholder | translate"
    [appendTo]="'body'"
    (change)="onChange($event)"
    (open)="onOpen()"
    (search)="onSearch($event)"
    [disabled]="!shouldBeAbleToEditListGroupItem"
  >
    <ng-template ng-option-tmp let-item="item" let-index="index">
      {{ item.extraLabels.firstLabel }}
      {{ item.extraLabels.secondLabel }}<br />
      <small>{{ item.extraLabels.thirdLabel ?? item.extraLabels.fourthLabel }}</small>
    </ng-template>
  </ng-select>
</div>

<ng-template #notAbleToEditTemplate>
  <div class="alert py-1">
    {{ notAbleToEditListGroupItemLabel | translate }}
  </div>
</ng-template>

<ul *ngIf="shouldShowSelectedItemsList" class="list-group list-group-remove"  ngClass={{customListClass}}>
  <li *ngFor="let item of selectedItems" class="list-group-item" (click)="onItemClick(item)">
    <b class="mr-3">{{ item.extraLabels.firstLabel }} {{ item.extraLabels.secondLabel }}</b>
    {{ item.extraLabels.thirdLabel ?? item.extraLabels.fourthLabel }}
    <span class="d-flex ml-auto">
      <span *ngIf="item.extraLabels.metaDataCount" class="d-flex align-items-center">
        <i class="{{ item.extraLabels.metaDataCountIcon }}"></i> {{ item.extraLabels.metaDataCount }}
      </span>
      <button
        *ngIf="shouldBeAbleToEditListGroupItem"
        class="round-btn round-btn-xs list-group-action ml-1"
        (click)="onRemove(item)">
        <i class="ftmi-cross"></i>
      </button>
    </span>
  </li>
</ul>
<div
  *ngIf="shouldShowDefaultMessage && this.selectedItems.length === 0 && shouldBeAbleToEditListGroupItem"
  class="empty-list-message"
>
  {{ defaultMessageContent | translate }}
</div>
