<app-auto-complete-list-group
  (selectChange)="onSelectChangeRoster($event)"
  (searchChange)="onSearchChangeRoster($event)"
  (itemClicked)="onItemClick($event)"
  [itemsData]="rosters"
  [selectedItemsData]="form?.get(this.controlName)?.value"
  [shouldShowDefaultMessage]="shouldShowDefaultMessage"
  [defaultMessageContent]="defaultMessageContent"
  [shouldShowSelectedItemsList]="shouldShowSelectedItemsList"
  [shouldBeAbleToEditListGroupItem]="shouldBeAbleToEditListGroupItem"
  [removeSelectedItem]="removeSelectedItem"
  [inputTitle]="inputTitle | translate"
  [placeholder]="placeholder | translate"
  [customListClass]="customListClass"
  [minTermLength]="0"
  [searchOnClick]="true"
></app-auto-complete-list-group>
<app-form-error [control]="form?.get(this.controlName)"></app-form-error>
