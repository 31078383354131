import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ClassInputType } from '../types/class-input-type';

@Component({
  selector: 'app-restriction-period-select',
  templateUrl: './restriction-period-select.component.html'
})
export class RestrictionPeriodSelectComponent {
  @Input() form: UntypedFormGroup | undefined;
  @Input() className: ClassInputType = 'app-input';
}
