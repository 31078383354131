<div class="app-input-inline">
  <label>{{ 'ASSIGN_TEACHER' | translate }}</label>
  <ng-container *ngIf="form">
    <app-auto-complete
      (selectedItemChange)="onSelectChangeTeacher($event)"
      (searchChange)="onSearchChangeTeacher($event)"
      [itemsData]="teachers"
      [selectedItem]="form.controls.assignTeacher.value"
    ></app-auto-complete>
  </ng-container>
</div>
