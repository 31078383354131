<app-preview-header>
  <ng-content previewTitle select="[previewTitle]"></ng-content>
  <ng-content backBtn select="[backBtn]"></ng-content>
</app-preview-header>

<div class="preview-container">
  <ng-content select="[previewContent]"> </ng-content>

  <app-preview-footer>
    <ng-content select="[footerContent]"> </ng-content>
  </app-preview-footer>
</div>
