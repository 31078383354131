import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { isFeatureEnabled } from '../../utils/utils';

@Directive({
    selector: '[appFeatureFlagHidden]'
})
export class FeatureFlagHiddenDirective implements OnInit {
    @Input() appFeatureFlagHidden = '';

    constructor(
        private hostElement: ElementRef
    ) {}

    ngOnInit(): void {
        if (!isFeatureEnabled(this.appFeatureFlagHidden)) {
            this.hostElement.nativeElement.hidden = true;
        }
    }
}
