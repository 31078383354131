import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AutoCompleteComponent } from '../auto-complete.component';
import { AutoCompleteItem } from '../interfaces/autocomplete-item';

@Component({
  selector: 'app-auto-complete-list-by-selection',
  templateUrl: './auto-complete-list-by-selection.component.html'
})
export class AutoCompleteListBySelectionComponent extends AutoCompleteComponent {
  selectedItems: AutoCompleteItem[] = [];
  @Input() set selectedItemsData(selectedItems: AutoCompleteItem[]) {
    if (!selectedItems) {
      return;
    }

    this.selectedItems = [...selectedItems];
  }

  @Output() clearChange = new EventEmitter<AutoCompleteItem[]>();

  constructor() {
    super();
  }

  onClear(): void {
    this.clearChange.emit();
  }
}
