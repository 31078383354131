const domainApiUrl = 'https://flexdev-api-k8s.flex.securly.com';
const domainUrl = 'https://dev-content.flex.securly.com';
const domainSSOUrl = 'https://flexdev-sso-k8s.flex.securly.com';
const domainNotificationsUrl = 'https://flexdev-notifications-api-k8s.flex.securly.com';

export const environment = {
  mobile: false,
  production: false,
  KeepLoggedIn: true,
  domainUrl,
  aptrinsicApiKey: 'AP-TDUP4LCTEZJK-2-2',
  AuthCallBackUrl: `${domainUrl}/authcallback`,
  EduSpireApi: {
    domainApiUrl,
    domainSSOUrl,
    baseUrl: `${domainApiUrl}/ftm`,
    baseUrlWithSegment: `${domainApiUrl}/ftm/district/school`,
    domainNotificationsUrl
  },
  ProvidersClientIds: {
    Eduspire: 'd9588f7b7dcd28d697a8ec640cda69e8',
    Google: '46451555040-fngji4t261spijrlaabgdccb7tfvsugh.apps.googleusercontent.com',
    Microsoft: '57707614-f2be-49f0-9605-322cd72e825b',
    ClassLink: 'c1569002517933a46b61330363f3099a240dbb7ddaa734',
    Clever: '8bc4d4db80f9553f80d2',
    GG4L: 'PTRSKJSIUZ',
    Edlink: '2aea35b4-7977-45cc-9cb8-d4573225416b'
  },
  DataDogConfig: {
    applicationId: '3413147f-5ecb-43a7-a24b-1dea74574b6d',
    clientToken: 'pub0e38c0237c7b4b0107478f8491259ead',
    site: 'datadoghq.com',
    service: 'ftm-v2',
    env: 'production',
    // Specify a version number to identify the deployed version of your application in Datadog
    version: '1.0.0',
    sampleRate: 1,
    replaySampleRate: 0,
    trackInteractions: true
  },
  Audio: {
    barCodeBeepSound: `${domainUrl}/assets/sounds/barcode-scanner-beep.wav`
  },
  DownloadRoutes: {
    roomCsvTemplate: `${domainUrl}/assets/csv-examples/room_template.csv`,
    rosterCsvTemplate: `${domainUrl}/assets/csv-examples/roster_template.csv`,
    userCsvTemplate: `${domainUrl}/assets/csv-examples/user_template.csv`
  },
  Firebase: {
    apiKey: 'AIzaSyC3OzUFqdYb3ORBL8Q6V1Gg1ZMEO5kpaPA',
    authDomain: 'flextimemanager-app.firebaseapp.com',
    projectId: 'flextimemanager-app',
    storageBucket: 'flextimemanager-app.appspot.com',
    messagingSenderId: '440694094312',
    appId: '1:440694094312:web:d95867adcbb65b78fdcc5d',
    vapidKey: 'BPM35e8cQUbZQqZjZtG1eQ5D1XdzBoijBBUEfyuu_zlmd8Aa9F3NVneFfBX6RcifukfrJ58J9qeoqWtP7hvMYJ4'
  }
};
