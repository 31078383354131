import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AppConfig } from 'src/app/configs/app.config';
import { DestroyNotifierComponent } from 'src/app/core/component/destroy/destroy-notifier.component';
import { Paging } from 'src/app/core/services/http/general/classes/paging';
import { Status } from 'src/app/core/services/http/general/enums/status';
import { UserAdapterService } from 'src/app/core/services/http/user/adapters/user-adapter.service';
import { User } from 'src/app/core/services/http/user/interfaces/user';
import { UserService } from 'src/app/core/services/http/user/user.service';
import { ObservableWrapperService } from 'src/app/core/services/observable-wrapper/observable-wrapper.service';
import { AutoCompleteItem } from '../../interfaces/autocomplete-item';

@Component({
  selector: 'app-auto-complete-list-group-teacher',
  templateUrl: './auto-complete-list-group-teacher.component.html'
})
export class AutoCompleteListGroupTeacherComponent extends DestroyNotifierComponent {
  @Input() inputTitle = 'ADD_TEACHER';
  @Input() controlName = 'sharedAttendance';
  @Input() defaultMessageContent = 'ADD_TEACHER_MESSAGE';
  @Input() form: UntypedFormGroup | undefined;
  @Input() shouldBeAbleToEditListGroupItem = true;
  @Input() shouldShowSelectedItemsList = true;
  @Input() shouldShowDefaultMessage = true;
  @Input() removeSelectedItem: AutoCompleteItem | undefined;

  @Output() selectChange = new EventEmitter<AutoCompleteItem[]>();

  employees: AutoCompleteItem[] = [];
  paging = new Paging({ pageSize: AppConfig.AutoComplete.pageSize });

  constructor(
    private userService: UserService,
    private userAdapterService: UserAdapterService,
    private observableWrapperService: ObservableWrapperService
  ) {
    super();
  }

  onSelectChangeTeacher(items: AutoCompleteItem[]): void {
    this.selectChange.emit(items);
    this.setFormControlValue(items);
  }

  onSearchChangeTeacher(filter: string): void {
    this.observableWrapperService.unsubscribeWrapper<User[]>(
      this.userService.getEmployeesByRole(this.paging, { filter, status: Status.Active }),
      this.destroyNotify,
      employees => {
        this.employees = this.userAdapterService.userToAutoCompleteItem(employees);
      }
    );
  }

  private setFormControlValue(items: AutoCompleteItem[]): void {
    if (!this.form || !this.controlName) {
      return;
    }

    this.form?.get(this.controlName)?.setValue(items);
  }
}
