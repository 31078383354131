import { formatDate } from '@angular/common';
import { Component, EventEmitter, Inject, Input, LOCALE_ID, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { AppConfig } from 'src/app/configs/app.config';
import { DestroyNotifierComponent } from 'src/app/core/component/destroy/destroy-notifier.component';
import { ClassInputType } from 'src/app/shared/shared-extensions/types/class-input-type';

@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls:['./time-picker.component.scss']
})
export class TimePickerComponent extends DestroyNotifierComponent implements OnInit {
  @Output() hideChange = new EventEmitter<Date>();

  @Input() form: UntypedFormGroup | undefined;
  @Input() controlName: string | undefined;
  @Input() controlNameSelector: string | undefined;
  @Input() label: string | undefined;
  @Input() max: any;
  @Input() min: any;
  @Input() clearInputAfterSelection: boolean | undefined;
  @Input() shouldEncreaseRelatedControl: boolean | undefined;
  @Input() relatedControl: AbstractControl | undefined;
  @Input() relatedControlSelector: AbstractControl | undefined;
  @Input() className: ClassInputType = 'app-input';
  @Input() dateTimeStepMin: number = AppConfig.DateTimePickers.dateTimeStepMin;
  @Input() dateTimeRelatedStepMin: number = AppConfig.DateTimePickers.dateTimeRelatedStepMin;

  appConfig = AppConfig;

  constructor(@Inject(LOCALE_ID) private locale: string) {
    super();
  }

  ngOnInit(): void {
    this.onChangeRelatedControl();
    this.onChangeControlSelector();
  }

  onHideTime(): void {
    if (!this.form || !this.controlNameSelector) {
      return;
    }

    const time = this.form.get(this.controlNameSelector)?.value;

    if (!time) {
      return;
    }

    this.emitHideChange(time);

    this.setControlName(time);
  }

  private onChangeControlSelector(): void {
    if (!this.controlNameSelector) {
      return;
    }

    this.form
      ?.get(this.controlNameSelector)
      ?.valueChanges.pipe(takeUntil(this.destroyNotify))
      .subscribe((value: Date) => {
        this.onChangeControlSelectorHandler(value);
      });
  }

  private onChangeRelatedControl(): void {
    if (
      this.shouldEncreaseRelatedControl === undefined ||
      !this.relatedControl ||
      !this.relatedControlSelector ||
      !this.controlNameSelector
    ) {
      return;
    }

    this.form
      ?.get(this.controlNameSelector)
      ?.valueChanges.pipe(takeUntil(this.destroyNotify))
      .subscribe((value: Date) => {
        this.onChangeRelatedControlHandler(value);
      });
  }

  private onChangeControlSelectorHandler(value: Date): void {
    const formattedTime = formatDate(value, AppConfig.Formats.TimeFormatMidDay, this.locale);

    if (this.controlName) {
      this.form?.get(this.controlName)?.setValue(formattedTime, { emitEvent: false });
    }
  }

  private onChangeRelatedControlHandler(value: Date): void {
    const changeStepWithSign = this.shouldEncreaseRelatedControl
      ? this.dateTimeRelatedStepMin
      : this.dateTimeRelatedStepMin * -1;

    const relatedControlDate = new Date();
    relatedControlDate.setHours(value.getHours(), value.getMinutes() + changeStepWithSign, 0, 0);

    this.relatedControl?.setValue(formatDate(relatedControlDate, AppConfig.Formats.TimeFormatMidDay, this.locale), {
      emitEvent: false
    });
    this.relatedControlSelector?.setValue(relatedControlDate, { emitEvent: false });
  }

  private emitHideChange(time: Date): void {
    this.hideChange.emit(time);
  }

  private setControlName(time: Date): void {
    if (!this.controlName) {
      return;
    }

    if (this.clearInputAfterSelection) {
      this.form?.get(this.controlName)?.setValue(null, { emitEvent: false });
      return;
    }

    const formattedTime = formatDate(time, AppConfig.Formats.TimeFormatMidDay, this.locale);

    this.form?.get(this.controlName)?.setValue(formattedTime, { emitEvent: false });
  }
}
