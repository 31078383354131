import { Injectable } from '@angular/core';
import { ExtendedDate } from '../classes/extended-date';
import { SchoolYear } from './school-year.interface';
import { SchoolYearRegistry } from './school-year.registry';
import { DateManagerService } from '../date-manager.service';

@Injectable({
    providedIn: 'root'
})
export class SchoolYearService {
    public constructor(
        private schoolYearRegistry: SchoolYearRegistry,
        private dateManagerService: DateManagerService
    ) {
    }

    public isCurrentSchoolYearSelected(): boolean {
        const selectedSchoolYear = this.schoolYearRegistry.selectedSchoolYear;
        const currentSchoolYear = this.schoolYearRegistry.getCurrentSchoolYear();

        if (currentSchoolYear && selectedSchoolYear) {
            return selectedSchoolYear.schoolYear === currentSchoolYear.schoolYear;
        }

        return false;
    }

    public isDuringSelectedSchoolYear(date: ExtendedDate): boolean {
        const selectedSchoolYear = this.schoolYearRegistry.selectedSchoolYear;

        if (selectedSchoolYear) {
            return this.isDuringSchoolYear(selectedSchoolYear, date);
        }

        return false;
    }

    public isDuringSchoolYear(schoolYear: SchoolYear, date: ExtendedDate): boolean {
        return (schoolYear.startDate).getTime() <= date.getTime() && (schoolYear.endDate).getTime() >= date.getTime();
    }

    public getDefaultDateBySchoolYear(): ExtendedDate {
        const selectedSchoolYear = this.schoolYearRegistry.selectedSchoolYear;

        if (this.isCurrentSchoolYearSelected() || !selectedSchoolYear) {
            return this.dateManagerService.getDateByCountWeekendsAndSchoolTimeZone();
        }

        const defaultDate: ExtendedDate = this.dateManagerService.getDateByCountWeekendsAndSchoolTimeZone(
            new ExtendedDate(selectedSchoolYear.schoolYear, 7, 1)
        );

        while (defaultDate.getDay() !== 1) {
            defaultDate.setDate(defaultDate.getDate() + 1);
        }

        return defaultDate;
    }
}
