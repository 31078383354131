<ng-container *ngIf="form">
  <div [formGroup]="form" class="{{ className }}">
    <label for="building">{{ label || ('PREFERRED_TITLE' | translate) }}</label>

    <ng-select
      *ngIf="titles$ | async as titles"
      [placeholder]="'TITLE' | translate"
      [items]="titles"
      [bindLabel]="'name'"
      [bindValue]="'name'"
      formControlName="{{ controlName }}"
    >
    </ng-select>
  </div>
</ng-container>
