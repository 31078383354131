import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[appDelayShowing]'
})
export class DelayShowingDirective implements OnInit {
  @Input() delayTime = 10000;

  constructor(private template: ElementRef) {}

  ngOnInit() {
    this.template.nativeElement.style.display = 'none';

    setTimeout(() => {
      this.template.nativeElement.style.display = 'block';
    }, this.delayTime);
  }
}
