<div [formGroup]="form">
  <div class="app-filter-bar" ngClass={{customClass}} [class.filter-collapsed]="isCollapsed === false">
    <ng-content select="[slotStart]"> </ng-content>

    <div class="search-input search-resp" *ngIf="shouldBeVisibleBuildInInputFilter">
      <i class="icon ftmi-search"></i>
      <input
        formControlName="filter"
        type="text"
        class="form-control"
        placeholder="Search"
        aria-label="Search"
        aria-describedby="searchBy"
      />
    </div>
    <button
      *ngIf="shouldBeVisibleToggleExtraFilterButton"
      type="button"
      class="round-btn toggle-filter"
      [ngClass]="{ 'filter-on': isCollapsed === false }"
      (click)="onCollapse()"
      [attr.aria-expanded]="!isCollapsed"
      aria-controls="collapseFilter"
    >
      <i class="ftmi-filter" [appTooltip]="'FILTER' | translate"></i>
    </button>

    <ng-content select="[slotEnd]"> </ng-content>
  </div>

  <div id="collapseFilter" *ngIf="shouldBeVisibleEmbeddedFilters" [collapse]="isCollapsed" class="app-filter-collapse">
    <div class="container-fluid">
      <div class="row">
        <ng-container *ngIf="shouldBeVisibleRangeFilter">
          <div class="form-group mb-2 col-md-6 col-lg-3">
            <div class="app-input-sm datepicker-unit">
              <label for="name">{{ 'START_DATE' | translate }}</label>
              <input
                type="text"
                class="form-control"
                placeholder="{{ appConfig.Formats.DateShortFormat | uppercase }}"
                [maxDate]="form.controls.endDate.value"
                bsDatepicker
                formControlName="startDate"
                [bsConfig]="bsConfig"
              />
            </div>
            <app-form-error [control]="form.controls.startDate"></app-form-error>
          </div>
          <div class="form-group mb-2 col-md-6 col-lg-3">
            <div class="app-input-sm datepicker-unit">
              <label for="name">{{ 'END_DATE' | translate }}</label>
              <input
                type="text"
                class="form-control"
                [minDate]="form.controls.startDate.value"
                placeholder="{{ appConfig.Formats.DateShortFormat | uppercase }}"
                bsDatepicker
                formControlName="endDate"
                [bsConfig]="bsConfig"
              />
            </div>
            <app-form-error [control]="form.controls.endDate"></app-form-error>
          </div>
        </ng-container>

        <ng-content> </ng-content>

        <div class="form-group mb-2 col-md-6 col-lg-3" *ngIf="shouldBeVisibleStatus">
          <app-status-select [className]="'app-input-sm'" [form]="form" [value]="filter?.status"> </app-status-select>
        </div>
      </div>
      <div class="row">
        <div class="col text-right">
          <button
            appDisableClearButton
            [formReference]="form"
            [formChange]="formChange"
            class="btn clear mb-2"
            (click)="onClearFilters()"
          >
            {{ 'CLEAR' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
