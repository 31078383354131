import { Pipe, PipeTransform } from '@angular/core';
import { ExtendedDate } from '../../services/date-manager/classes/extended-date';
import { DateManagerService } from '../../services/date-manager/date-manager.service';

@Pipe({
  name: 'timeByShiftUtc'
})
export class TimeByShiftUtcPipe implements PipeTransform {
  constructor(private dateManagerService: DateManagerService) {}

  transform(value: number): ExtendedDate {
    return this.dateManagerService.getTimeByUTCShift(value);
  }
}
