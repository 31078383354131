<app-select
  *ngIf="form"
  [form]="form"
  label="{{ 'ROLE' | translate }}"
  [className]="className"
  controlName="role"
  [items]="[
    { id: authority.Admin, name: 'ADMIN' | translate },
    { id: authority.Staff, name: 'STAFF' | translate },
    { id: authority.Student, name: 'STUDENT' | translate },
    { id: authority.Teacher, name: 'TEACHER' | translate }
  ]"
>
</app-select>
