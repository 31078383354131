import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'joinArray'
})
export class JoinArrayPipe implements PipeTransform {
  transform(value: unknown[], separator: ', ' | '. ' | '- '): string | undefined {
    if (!Array.isArray(value)) {
      return;
    }

    return value?.join(separator);
  }
}
