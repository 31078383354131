import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { AppConfig } from 'src/app/configs/app.config';
import { DestroyNotifierComponent } from 'src/app/core/component/destroy/destroy-notifier.component';
import { Status } from 'src/app/core/services/http/general/enums/status';
import { FormGroupService } from '../form-error/form-group.service';
import { getFormGroup } from './form-group/form.group';
import { SchoolYearRegistry } from '../../core/services/date-manager/school-year/school-year.registry';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterComponent extends DestroyNotifierComponent implements OnInit {
  @Input() shouldBeVisibleBuildInInputFilter = true;
  @Input() shouldBeVisibleEmbeddedFilters = true;
  @Input() shouldBeVisibleRangeFilter = true;
  @Input() shouldBeVisibleStatus = true;
  @Input() shouldBeVisibleToggleExtraFilterButton = true;
  @Input() customClass = '';
  @Input() filter: Record<string, any> | undefined;

  // Sets external form controls to the form
  @Input() set controls(controls: Record<string, any> | undefined) {
    if (!controls) {
      return;
    }

    for (const controlName in controls) {
      if (Object.prototype.hasOwnProperty.call(controls, controlName)) {
        this.form.addControl(controlName, controls[controlName]);
      }
    }
  }

  @Output() filterChange = new EventEmitter<Record<string, any>>();
  @Output() filterCollapse = new EventEmitter<boolean>();

  form = new UntypedFormBuilder().group({ filter: [], status: [] });
  appConfig = AppConfig;
  isCollapsed = true;
  debounce: any;
  status = Status;
  lastSentFilterValue = '';
  formChange: { change: true } | undefined;
  bsConfig = Object.assign(AppConfig.BsConfig, {
    maxDate: this.schoolYearRegistry.selectedSchoolYear.endDate,
    minDate: this.schoolYearRegistry.selectedSchoolYear.startDate
  });

  constructor(private formGroupService: FormGroupService, private schoolYearRegistry: SchoolYearRegistry) {
    super();
  }

  ngOnInit(): void {
    if (this.shouldBeVisibleRangeFilter) {
      this.addDefaultFormControls();
    }

    this.onInputChange();
    this.onFormChange();
  }

  onClearFilters(): void {
    this.formGroupService.clearFormControlsValuesByFormGroup(this.form, false, false);
    this.emitFilterChange();
  }

  onCollapse(): void {
    this.isCollapsed = !this.isCollapsed;
    this.filterCollapse.emit(this.isCollapsed);
  }

  private addDefaultFormControls(): void {
    const form = getFormGroup();

    for (const controlName in form.controls) {
      if (Object.prototype.hasOwnProperty.call(form.controls, controlName)) {
        this.form.addControl(controlName, form.controls[controlName]);
      }
    }
  }

  private onInputChange(): void {
    this.form.controls.filter.valueChanges.pipe(takeUntil(this.destroyNotify)).subscribe((value: string) => {
      this.onInputChangeHandler(value);
    });
  }

  private onInputChangeHandler(value: string): void {
    // Stops further execution if min length of string is not reached
    // when we type new sumbols (increase length of input value)
    // otherwise this won't executed (decrease length of input value)
    if (value.length < AppConfig.Inputs.minTermLength && this.lastSentFilterValue.length < value.length) {
      return;
    }

    clearTimeout(this.debounce);
    // Emits select event after debounceTimeMs
    // Protects API from requesting a new data, every time when the user types a new symbol
    this.debounce = setTimeout(() => {
      // Prevents emitting of the event if the last send value is the same as current one
      // Example: If you type something and quickly deleted until debounceTimeMs.
      // This action wont be emitted because assigning of new value happend inside debounce.
      // This prevents API from additional requests that already is loaded
      if (this.lastSentFilterValue === value) {
        return;
      }

      this.lastSentFilterValue = value;

      this.emitFilterChange();
    }, AppConfig.AutoComplete.debounceTimeMs);
  }

  /**
   * Handles all form controls changes without input
   */
  private onFormChange(): void {
    // Excludes input from the subscriber, because we have special debounce processing for input control
    const merged = this.formGroupService.mergeValueChangesByFormControlWithoutExcluded(['filter'], this.form);

    if (!merged) {
      return;
    }

    merged.pipe(takeUntil(this.destroyNotify)).subscribe(() => {
      this.emitFilterChange();
    });
  }

  private emitFilterChange(): void {
    const result = this.convertFormControlToRecord();
    this.filterChange.emit(result);
    this.formChange = { change: true };
  }

  private convertFormControlToRecord(): Record<string, any> | undefined {
    const result: Record<string, any> | undefined = {};

    if (!this.form?.controls) {
      return;
    }

    for (const key in this.form.controls) {
      if (Object.prototype.hasOwnProperty.call(this.form.controls, key)) {
        let value = this.form.controls[key].value;
        value = this.getAutoCompleteItemValue(value);

        if (!result[key]) {
          result[key] = value;
        }
      }
    }

    return result;
  }

  private getAutoCompleteItemValue(value: any): any {
    if (value && value.toString() === '[object Object]' && value.hasOwnProperty('value')) {
      return value.value;
    }

    return value;
  }
}
