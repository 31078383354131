<ng-container *ngIf="modal && modal.form && modal.form.controls.manualFile">
  <app-confirm-modal-template [form]="modal.form" [modal]="modal" [modalBodyPaddingY]="false">
    <div modalBody>
      <div [formGroup]="modal.form">
        <app-file-uploader (fileChange)="onFileChange($event)"></app-file-uploader>

        <div class="d-flex justify-content-between align-items-center mb-4" *ngIf="modal.fileExampleURL">
          <a class="btn btn-text" target="_blank" [href]="modal.fileExampleURL">
            <i class="ftmi-download"></i> {{ 'DONWLOAD_EXAMPLE_FILE' | translate }}
          </a>
        </div>

        <input class="d-none" formControlName="manualFile" />
        <app-form-error [control]="modal.form.controls.manualFile"></app-form-error>
      </div>
    </div>

    <button
      confBtn
      type="button"
      class="btn btn-primary btn-padded btn-radius btn-block"
      [disabled]="!modal.form.controls.manualFile.value || modal.form.controls.manualFile.errors || modal.form.disabled"
      (click)="onFileUpload()"
    >
      {{ modal.buttonText || ('CONFIRM' | translate) }}
    </button>
  </app-confirm-modal-template>
</ng-container>
