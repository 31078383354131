import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { Paging } from 'src/app/core/services/http/general/classes/paging';
import { UserTitle } from 'src/app/core/services/http/user-title/interface/user-title';
import { UserTitleService } from 'src/app/core/services/http/user-title/user-title.service';
import { ClassInputType } from '../types/class-input-type';

@Component({
  selector: 'app-title-select',
  templateUrl: './title-select.component.html'
})
export class TitleSelectComponent implements OnInit {
  @Input() label: string | undefined;
  @Input() form: UntypedFormGroup | undefined;
  @Input() controlName = 'title';
  @Input() className: ClassInputType = 'app-input';
  titles$: Observable<UserTitle[]> | undefined;

  constructor(private userService: UserTitleService) {}

  ngOnInit(): void {
    this.titles$ = this.userService.getAll(new Paging().getAllRecords());
  }
}
