import { formatDate } from '@angular/common';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfig } from 'src/app/configs/app.config';
import { AuthFactoryService } from '../../auth/auth-factory.service';
import { ExtendedDate } from '../../date-manager/classes/extended-date';
import { Cancellation } from '../cancellation/interfaces/cancellation';
import { Paging } from '../general/classes/paging';
import { RestGenericUtilService } from '../rest-generic-util.service';
import { RestGeneric } from '../rest.generic';
import { Flex } from './interfaces/flex';
import { FlexRequest } from './interfaces/flex-request';

@Injectable({ providedIn: 'root' })
export class FlexService extends RestGeneric<FlexRequest, Flex, FlexRequest> {
  private isEditMode = false;

  constructor(
    protected http: HttpClient,
    protected authFactoryService: AuthFactoryService,
    protected restGenericUtilService: RestGenericUtilService,
    @Inject(LOCALE_ID) private locale: string
  ) {
    super(http, authFactoryService, 'flex-period', restGenericUtilService);
  }

  setIsEditMode(mode: boolean): void {
    this.isEditMode = mode;
  }

  getIsEditMode(): boolean {
    return this.isEditMode;
  }

  getAll(paging = new Paging(), filter?: Record<string, any>): Observable<Flex[]> {
    const params = this.restGenericUtilService.toParams({ ...paging.getApiPaginationData(), ...filter });

    return this.http
      .get<Flex[]>(`${this.baseApiUrl}/${this.schoolUUid}/${this.endpointName}`, {
        params
      })
      .pipe(
        map(response => {
          response.map(item => {
            this.setFlexTimeAndDateFormatted(item);
          });

          return response;
        })
      );
  }

  getById(uuid: string): Observable<Flex> {
    return this.http.get<Flex>(`${this.baseApiUrl}/${this.endpointName}/${uuid}`).pipe(
      map(flex => {
        this.setFlexTimeAndDateFormatted(flex);

        return flex;
      })
    );
  }

  getCancellationsByFlex(
    flexUuid: string,
    paging = new Paging(),
    filter?: Record<string, any>
  ): Observable<HttpResponse<Cancellation[]>> {
    const params = this.restGenericUtilService.toParams({ ...paging.getApiPaginationData(), ...filter });

    return this.http.get<Cancellation[]>(
      `${this.baseApiUrl}/${this.endpointName}/${flexUuid}/flex-period-cancellation`,
      {
        observe: 'response',
        params
      }
    );
  }

  setFlexTimeAndDateFormatted(flex: Flex): void {
    const flexTime = this.getFlexTime(flex.startTime, flex.endTime);
    const flexDate = this.getFlexDate(flex.startDate, flex.endDate);

    const startDate = new ExtendedDate(flex.startDate);
    const endDate = new ExtendedDate(flex.endDate);

    flex.flexTimeStr = flexTime;
    flex.flexDateStr = flexDate;

    if (startDate) {
      flex.startDateDate = startDate;

      if (endDate) {
        flex.endDateDate = endDate;
        const diffTime = Math.abs(flex.startDateDate.getTime() - flex.endDateDate.getTime());
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        flex.flexDateDays = diffDays;
      }
    }
  }

  getFlexTime(startTimeShift?: number, endTimeShift?: number): string {
    if (!startTimeShift || !endTimeShift) {
      return '';
    }

    let result = '';

    if (startTimeShift > 0) {
      const startTime = new ExtendedDate();
      startTime.setHours(0, 0, 0, 0);
      startTime.setSeconds(startTimeShift);

      result = formatDate(startTime, AppConfig.Formats.TimeFormatMidDay, this.locale);
    }

    if (startTimeShift > 0 && endTimeShift > 0) {
      const endTime = new ExtendedDate();
      endTime.setHours(0, 0, 0, 0);
      endTime.setSeconds(endTimeShift);

      result += ` - ${formatDate(endTime, AppConfig.Formats.TimeFormatMidDay, this.locale)}`;
    }

    return result;
  }

  private getFlexDate(startDate?: string, endDate?: string): string {
    if (!startDate || !endDate) {
      return '';
    }

    return `${formatDate(startDate, AppConfig.Formats.DateFormat, AppConfig.Language.defaultLocale)} - ${formatDate(
      endDate,
      AppConfig.Formats.DateFormat,
      this.locale
    )}`;
  }
}
