<ng-container *ngIf="modal">
  <div class="modal-header align-items-center">
      <i *ngIf="headerIcon" class="{{headerIcon}} icon modal-header-icon"></i>
    <div class="modal-title-centered">
      <h4 class="modal-title">{{ modal.title }}</h4>
    </div>
    <button type="button" class="round-btn ml-auto" aria-label="Close" (click)="onClose()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <ng-content select="[preModalBody]"></ng-content>
  <div class="modal-body" [ngClass]="modalBodyPaddingY ? '' : 'py-0'">
    <ng-content select="[modalBody]"> </ng-content>
  </div>
  <div class="modal-footer">
    <div *ngIf="shouldShowCancelButton" class="btn-col">
      <button type="button" class="btn btn-outline-primary btn-padded btn-radius btn-block" (click)="onClose()">
        {{ modal.secondaryButtonText || 'CANCEL' | translate }}
      </button>
    </div>
    <div *ngIf="shouldShowCustomButton" class="btn-col">
      <ng-content select="[customBtn]"> </ng-content>
    </div>
    <div *ngIf="shouldShowConfirmButton" class="btn-col">
      <ng-content select="[confBtn]"> </ng-content>
    </div>
  </div>
</ng-container>
