import { Pipe, PipeTransform } from '@angular/core';
import { DateManagerService } from '../../services/date-manager/date-manager.service';

@Pipe({
  name: 'weekNumbersToDays'
})
export class WeekNumbersToDaysPipe implements PipeTransform {
  constructor(private dateManagerService: DateManagerService) {}

  transform(weekNumbers: number[]): string {
    return this.dateManagerService.convertWeekNumbersToDays(weekNumbers);
  }
}
