import { Directive, HostBinding, Input } from '@angular/core';

import { AbstractControl } from '@angular/forms';

@Directive({
  selector: '[appParentValidator]'
})
export class ParentValidatorDirective {
  @Input() control: AbstractControl | undefined;

  @HostBinding('class.form-error') get invalid() {
    return this.control?.invalid && this.control.touched;
  }

  @HostBinding('class.ng-valid') get valid() {
    return this.control?.valid && this.control.touched;
  }
}
