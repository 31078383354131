import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthFactoryService } from '../../auth/auth-factory.service';
import { Paging } from '../general/classes/paging';
import { RestGenericUtilService } from '../rest-generic-util.service';
import { RestGeneric } from '../rest.generic';
import { Announcement } from './interfaces/announcement';

@Injectable({
  providedIn: 'root'
})
export class AnnouncementService extends RestGeneric<any, Announcement, any> {
  constructor(
    protected http: HttpClient,
    protected authFactoryService: AuthFactoryService,
    protected restGenericUtilService: RestGenericUtilService
  ) {
    super(http, authFactoryService, 'announcement', restGenericUtilService);
  }

  getRemoteAnnouncements(
    paging = new Paging(),
    filter?: Record<string, any>,
    headers?: Record<string, string>
  ): Observable<Announcement[]> {
    const params = this.restGenericUtilService.toParams({ ...paging.getApiPaginationData(), ...filter });

    return this.http.get<Announcement[]>(`${environment.EduSpireApi.baseUrl}/remote-announcement`, {
      params,
      headers
    });
  }
}
