import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { EventType } from 'src/app/constants/events-type';
import { ExtendedDate } from 'src/app/core/services/date-manager/classes/extended-date';
import { DateManagerService } from 'src/app/core/services/date-manager/date-manager.service';
import { EventManagerService } from 'src/app/core/services/event-manager/event-manager.service';
import { EventContent } from 'src/app/core/services/event-manager/models/event-content';
import { DateToMove } from 'src/app/core/services/http/scheduled-activity/interfaces/date-to-move';
import { compareDateByDays, formatDateToApiFormat, isDatesEqual } from 'src/app/core/utils/utils';
import { FlexCalendarVerticalComponent } from '../flex-calendar-vertical/flex-calendar-vertical.component';
import { FlexCalendarDay } from '../interfaces/flex-calendar-day';
import { FlexViewCalendarService } from '../services/flex-view-calendar-service';

@Component({
  selector: 'app-flex-calendar-vertical-move',
  templateUrl: './flex-calendar-vertical-move.component.html',
  styleUrls: ['./flex-calendar-vertical-move.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlexCalendarVerticalMoveComponent extends FlexCalendarVerticalComponent implements OnChanges {
  @Input() priorityClass: string | undefined;
  
  updatedRoomCaps: any = [];

  constructor(
    protected flexViewCalendarService: FlexViewCalendarService,
    protected eventManager: EventManagerService,
    protected dateManagerService: DateManagerService
  ) {
    super(flexViewCalendarService, eventManager, dateManagerService);
  }

  ngOnChanges(): void {
    this.onRoomCapUpdate();
    this.setFlexCalendarDays();
    this.setFlexCalendarDaysProperties();
    this.setFlexCalendarSelectedPropertiesBySelected();
  }

  onSelectDay(flexDay: FlexCalendarDay): void {
    if (flexDay.availableForSelect && this.originalSelectedCalendarDate) {
      this.updateFlexCalendarDaysSelected(flexDay.date);
      this.updateFlexCalendarDayClass(flexDay.date);

      this.broadcastDatesToMoveUpdate({
        fromDate: formatDateToApiFormat(this.originalSelectedCalendarDate),
        toDate: formatDateToApiFormat(flexDay.date),
        roomCapData: this.updatedRoomCaps
      });
    }
  }

  protected onRoomCapUpdate(): void {
    this.eventManager.subscribe(EventType.RoomCapUpdate, (eventContent: EventContent<ExtendedDate[]>) => {
      this.updatedRoomCaps = eventContent.content;

      if (this.flexCalendarDaysSelected && this.originalSelectedCalendarDate) {
         this.broadcastDatesToMoveUpdate({
           fromDate: formatDateToApiFormat(this.originalSelectedCalendarDate),
           toDate: formatDateToApiFormat(this.flexCalendarDaysSelected[0]),
           roomCapData: this.updatedRoomCaps
         });
       }
    });
  }

  protected updateFlexCalendarDayClass(date: Date): void {
    const targetNewSelected = this.getFlexCalendarDays().find(item => compareDateByDays(item.date, date) === 0);
    const targetSelected = this.getFlexCalendarDays().find(
      item => item.selected && compareDateByDays(item.date, this.originalSelectedCalendarDate) !== 0
    );

    if (targetNewSelected) {
      targetNewSelected.class = 'selected';
      targetNewSelected.selected = true;
    }

    if (targetSelected) {
      targetSelected.class = 'available';
      targetSelected.selected = false;
    }
  }

  protected setFlexCalendarDaysProperties(): void {
    this.getFlexCalendarDays().forEach(flexCalendarDay => {
      this.setAvailableFlexCalendarDayProperties(flexCalendarDay);
      this.setScheduleFlexCalendarDayProperties(flexCalendarDay);
      this.setSelectedFlexCalendarDayProperties(flexCalendarDay);
    });
  }

  protected updateFlexCalendarDaysSelected(date: ExtendedDate): void {
    this.flexCalendarDaysSelected = [date];
  }

  protected setScheduleFlexCalendarDayProperties(flexCalendarDay: FlexCalendarDay): void {
    const targetScheduledDate = this.scheduledDates?.find(date => isDatesEqual(date, flexCalendarDay.date));

    if (!targetScheduledDate) {
      return;
    }

    flexCalendarDay.class = 'date-btn-past';
    flexCalendarDay.availableForSelect = false;
  }

  protected setSelectedFlexCalendarDayProperties(flexCalendarDay: FlexCalendarDay): void {
    if (!this.originalSelectedCalendarDate) {
      return;
    }

    if (compareDateByDays(this.originalSelectedCalendarDate, flexCalendarDay.date) === 0) {
      flexCalendarDay.class = 'date-btn-current selected';
      flexCalendarDay.availableForSelect = false;
      flexCalendarDay.selected = true;
    }
  }

  private broadcastDatesToMoveUpdate(dateToMove: DateToMove): void {
    dateToMove.roomCapData = this.updatedRoomCaps;
    this.eventManager.broadcast(new EventContent(EventType.DateMove, dateToMove));
  }
}
