import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Authority } from 'src/app/constants/authority';
import { DestroyNotifierComponent } from 'src/app/core/component/destroy/destroy-notifier.component';
import { Status } from 'src/app/core/services/http/general/enums/status';
import { UserAdapterService } from 'src/app/core/services/http/user/adapters/user-adapter.service';
import { User } from 'src/app/core/services/http/user/interfaces/user';
import { UserService } from 'src/app/core/services/http/user/user.service';
import { ObservableWrapperService } from 'src/app/core/services/observable-wrapper/observable-wrapper.service';
import { AutoCompleteItem } from '../../interfaces/autocomplete-item';

@Component({
  selector: 'app-auto-complete-assign-teacher',
  templateUrl: './auto-complete-assign-teacher.component.html'
})
export class AutoCompleteAssignTeacherComponent extends DestroyNotifierComponent {
  @Input() form: UntypedFormGroup | undefined;

  teachers: AutoCompleteItem[] = [];

  constructor(
    private userService: UserService,
    private userAdapterService: UserAdapterService,
    private observableWrapperService: ObservableWrapperService
  ) {
    super();
  }

  onSelectChangeTeacher(item: AutoCompleteItem): void {
    this.form?.controls.assignTeacher.setValue(item);
  }

  onSearchChangeTeacher(filter: string): void {
    this.observableWrapperService.unsubscribeWrapper<User[]>(
      this.userService.getAll(undefined, { filter, role: Authority.Teacher, status: Status.Active }),
      this.destroyNotify,
      teachers => {
        this.teachers = this.userAdapterService.userToAutoCompleteItem(teachers);
      }
    );
  }
}
