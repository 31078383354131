import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'split'
})
export class SplitPipe implements PipeTransform {
  transform(text: string, by: string, index = 1) {
    if (index < 0) {
      index = 1;
    }

    const arr = text.split(by);
    return arr[index];
  }
}
