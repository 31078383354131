<div *ngFor="let item of announcements" class="announcement">
  <div class="announcement-header d-flex justify-content-between align-items-center">
    <h3 class="announcement-title m-0"><i class="icon ftmi-announcement"></i>{{ item.title }}</h3>
    <button
      type="button"
      class="btn btn-text text-yellow"
      (click)="onClick(item)"
      [attr.aria-expanded]="!getCollapsedState(item)"
      [attr.aria-controls]="item.uuid"
    >
      {{ 'TOGGLE_ANNOUNCEMENT' | translate }}
    </button>
  </div>
  <div id="{{ item.uuid }}" [collapse]="getCollapsedState(item) || false" [isAnimated]="true">
    <div [innerHTML]="item.html | sanitizeHtml" class="announcement-body"></div>
  </div>
</div>
