<div class="flex-calendar">
  <div *ngFor="let flexDay of this.getFlexCalendarDays(); trackBy: trackBy" class="flex-day">
    <div [ngClass]="flexDay.classTitle || ''" class="week-day">{{ flexDay.name }}</div>
    <button
      [disabled]="flexDay.selected"
      (click)="onSelectDay(flexDay)"
      [ngClass]="flexDay.class || ''"
      class="round-btn date-btn"
    >
      {{ flexDay.dateNumber }}
    </button>
  </div>
</div>
